import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ContactInfo from "Components/common/contactInfo";
import { getCity } from "../../../../utils/methods/apiMethods";
import { address } from "../../../../utils/constant/prescriber/atom";

export default function Address() {
  const addressState = useRecoilValue(address);

  async function handleChange(evt) {
    const value = evt.target.value;
    if (evt.target.name === "zip") {
      let zip = value.replace(/_/g, "").toString();

      if (zip.length >= 3) {
        const response = await getCity(zip);
        console.log(response, address);

        if (response.status) {
          addressState({
            ...address,
            zip: response.data.postalCode,
            cityId: response.data.id,
            city: response.data.name,
            state: response.data.stateName,
          });
        } else {
          addressState({
            ...address,
            // zip:value,
            cityId: 0,
            city: "",
            state: "",
          });
        }
      } else {
        // addressState({
        //   ...address,
        //   // zip:value,
        // });
      }

      if (zip === "") {
        addressState({
          ...address,
          zip: "",
          cityId: "",
          city: "",
          state: "",
        });
      }
    } else {
      addressState({
        ...address,
        [evt.target.name]: value,
      });
    }
  }
  return <ContactInfo type="address" state={address} handleChange={handleChange} />;
}
