import { Button, DatePicker, Input, message, Popconfirm, Select, Space, Table, Tooltip, AutoComplete, Divider } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import SearchVendor from "../../../../components/common/searchVendor";
// import { prescriberVendorDetailState } from "../../../../utils/constant/prescriber/atom";
// import PrescriberVendorDetail from "./prescriberVendorDetail";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function LinkVendor() {
  const [options, setOptions] = useState([]);
  // const [isVendorDetail, setVendorDetail] = useRecoilState(prescriberVendorDetailState);
  const [state, setState] = useState({
    name: "",
    dob: new Date(),
    tel: "",
    relation: "",
    insID: "",
    address: 1,
  });

  const linkVendorData = [];
  for (let i = 0; i < 6; i++) {
    linkVendorData.push({
      key: i,
      vendorCode: `Amoxicillin/POT Calvulana`,
      generic: `Amoxicillin Oral Tablet 87`,
      strength: `250-62.5mG/5mL${i}`,
      pkgSize: `100.${i}`,
      stockQty: `150.${i}`,
      ndc: `23123 2341 2${i}`,
      upc: `231232341${i}`,
      manufacturer: `Aurobindo`,
      vendor: `Mayo Clinic`,
      cost: `157.${i}`,
    });
  }
  const columnsLinkVendor = [
    {
      title: "Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      width: 100,
    },
    {
      title: `Vendor Name`,
      dataIndex: "vendor",
      key: "vendor",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (vendor) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={vendor}>
          {vendor}
        </Tooltip>
      ),
    },
    // {
    //   title: "UPC",
    //   dataIndex: "upc",
    //   key: "upc",
    //   width: 120,
    // },
    // {
    //   title: "NDC",
    //   dataIndex: "ndc",
    //   key: "ndc",
    //   width: 120,
    // },
    {
      title: "Item #",
      dataIndex: "fax",
      key: "fax",
      align: "center",
      width: 100,
    },
    // {
    //   title: `Pkg. Size`,
    //   dataIndex: "pkgSize",
    //   key: "pkgSize",
    //   width: 80,
    // },
    // {
    //   title: `Cost`,
    //   dataIndex: "cost",
    //   key: "cost",
    //   width: 100,
    //   className: "col-amount",
    // },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 80,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
          <Tooltip title="Default" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-default"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const { Option } = Select;

  const handleSearch = (value) => {
    setOptions(value ? searchPatient(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const searchPatient = (name, cell) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const prescriberVendorName = `${name}${idx}`;
        const prescriberVendorCell = `123 456 786${idx}`;
        return {
          value: prescriberVendorName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {prescriberVendorName}
              </span>
              <span className="search-generic">
                <i>DOB:</i>
                {prescriberVendorCell}
              </span>
            </div>
          ),
        };
      });
  };

  return (
    <>
      <section className="bg-secondary">
        <div className="section-title mt-2">
          {/* <span className="title-text">Vendors</span> */}
          <div className="input-group input-search">
            <SearchVendor />
          </div>
          <Tooltip title="Link">
            <Button className="btn btn-icon btn-link btn-sm float-right">
              <i className="mdi mdi-link"></i>
            </Button>
          </Tooltip>
        </div>
        <Table
          className="table-length length-three mt-0"
          size="small"
          columns={columnsLinkVendor}
          dataSource={linkVendorData}
          pagination={false}
          scroll={{ y: 65 }}
          rowKey={(record) => record.uid}
        />
      </section>
    </>
  );
}
