import React, { useState } from "react";
import { AutoComplete, Select, Input, Divider } from "antd";
import { Link } from "react-router-dom";
import { vendorDetailState } from "../../../utils/constant/vendor/atom";
import { useRecoilState } from "recoil";
import VendorDetail from "../../../pages/Private/Vendor/vendorDetail";

export default function SearchVendor() {
  const [isVendorDetail, setVendorDetail] = useRecoilState(vendorDetailState);
  const vendorDetailModal = () => {
    setVendorDetail(true);
  };

  const [options, setOptions] = useState([]);
  const { Option } = Select;

  const handleSearch = (value) => {
    setOptions(value ? searchVendor(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };
  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const searchVendor = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const vendorName = `${name}${idx}`;
        const vendorStg = `500mg${idx}`;
        return {
          value: vendorName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {vendorName}
              </span>
              <span className="search-generic">
                <i>DOB:</i>
                {vendorStg}
              </span>
            </div>
          ),
        };
      });
  };
  return (
    <>
      <div className="d-none">
        <VendorDetail />
      </div>
      <div className="input-vendor input-autocomplete">
        <AutoComplete
          className="formControl"
          dropdownClassName="search-result vendor"
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          notFoundContent="No Record Found"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: ".5rem 0" }} />
              <div className="btn-wrap">
                <Link className="btn" onClick={() => vendorDetailModal(true)}>
                  <i className="mdi mdi-plus-circle" /> <span>Vendor</span>
                </Link>
              </div>
            </>
          )}>
          <Input.Search placeholder="Name" />
        </AutoComplete>
        <label className="formLabel">Vendor(s)</label>
      </div>
    </>
  );
}
