import React, { useState } from "react";
import { Checkbox } from "antd";
import WorksmanCom from "./worksmanCom";

export default function PatientWorksmanCom() {
  return (
    <>
      <form>
        <div className="row align-items-center">
          <div className="col-12">
            <section className="pat-worksman-com m-0 p-0 border-0">
              <div className="row">
                <div className="col-lg-6">
                  <section style={{ background: "rgb(228 237 255)" }}>
                    <div className="section-title">
                      <Checkbox className="title-text" checked>
                        WC 1
                      </Checkbox>
                    </div>
                    <WorksmanCom />
                  </section>
                </div>
                <div className="col-lg-6">
                  <section style={{ background: "rgb(236 255 242)" }}>
                    <div className="section-title">
                      <Checkbox className="title-text">WC 2</Checkbox>
                    </div>
                    <WorksmanCom />
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </form>
    </>
  );
}
