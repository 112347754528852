import { Button, message, Popconfirm, Select, Modal, Input } from "antd";
import React from "react";
import { auxillaryLabelsState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";
export default function AuxillaryLabels() {
  const [isAuxillaryLabels, setAuxillaryLabels] = useRecoilState(auxillaryLabelsState);

  const handleOk = async (e) => {
    setAuxillaryLabels(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setAuxillaryLabels(false);
    message.error("Data not saved!");
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
  };

  const { Option } = Select;

  return (
    <>
      {/* <Button className="btn float-right" icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />} onClick={() => setAuxillaryLabels(true)}>
        Eligibility Check
      </Button> */}
      <Modal
        width={700}
        title="Auxillary Labels"
        visible={isAuxillaryLabels}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-link" onClick={handleOk}>
                Link
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section>
          <div className="row">
            <div className="col-12">
              <section className="bg-primary">
                <div className="input-group width-100">
                  <div className="input-group-prepend">
                    <i className="mdi mdi-numeric-1-box"></i>
                  </div>
                  <Select className="formControl" showSearch placeholder="Code" onChange={handleChange} autoFocus></Select>
                </div>
                <div className="input-group width-100">
                  <div className="input-group-prepend">
                    <i className="mdi mdi-numeric-2-box"></i>
                  </div>
                  <Select className="formControl" showSearch placeholder="Code" onChange={handleChange}></Select>
                </div>
                <div className="input-group width-100">
                  <div className="input-group-prepend">
                    <i className="mdi mdi-numeric-3-box"></i>
                  </div>
                  <Select className="formControl" showSearch placeholder="Code" onChange={handleChange}></Select>
                </div>
                <div className="input-group width-100">
                  <div className="input-group-prepend">
                    <i className="mdi mdi-numeric-4-box"></i>
                  </div>
                  <Select className="formControl" showSearch placeholder="Code" onChange={handleChange}></Select>
                </div>
                <hr></hr>
                <div className="input-group input-xxl">
                  <Input className="formControl" placeholder="Master Drug Group Name" />
                  <label className="formLabel">Master Drug Group Name</label>
                </div>
                <div className="input-group input-xxl float-right">
                  <Input className="formControl" placeholder="Drug Therapeutic Class" disabled />
                  <label className="formLabel">Drug Therapeutic Class</label>
                </div>
                <div className="input-group input-xxl">
                  <Input className="formControl" placeholder="Master Drug Code" disabled />
                  <label className="formLabel">Master Drug Code</label>
                </div>
                <div className="input-group input-xxl float-right">
                  <Input className="formControl" placeholder="Drug Chemical Class" disabled />
                  <label className="formLabel">Drug Chemical Class</label>
                </div>
              </section>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
