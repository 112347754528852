import React from "react";
import { useRecoilState } from "recoil";
import ImageGallery from "react-image-gallery";
import { Button, Modal } from "antd";
import { docGalleryState } from "../../../../utils/constant/processing/atom";
import PatientDummyDoc from "Assets/img/docs/patient-medical-record.png";
import "../../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

const documents = [
  {
    original: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
  },
  {
    original: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
  },
  {
    original: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Prescription_Sigmund_Freud.jpg",
  },
];

export default function DocGallery() {
  const [isDocGallery, setDocGallery] = useRecoilState(docGalleryState);
  const docListModal = () => {
    setDocGallery(true);
  };

  const handleOk = () => {
    setDocGallery(false);
  };

  const handleCancel = () => {
    setDocGallery(false);
  };

  const galleryProps = {
    items: [documents],
    // thumbnailPosition: "left",
  };

  return (
    <Modal
      width={900}
      style={{ top: 20 }}
      className="modal-document-list"
      title="Document List"
      visible={isDocGallery}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      // keyboard={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <ImageGallery items={documents} showPlayButton={false} showNav={false} thumbnailPosition="left" />
    </Modal>
  );
}
