const { atom } = require("recoil");

export const drugGroupDetailState = atom({
  key: "drugGroupDetailState",
  default: false,
});
export const drugDetailState = atom({
  key: "drugDetailState",
  default: false,
});
export const otcDetailState = atom({
  key: "otcDetailState",
  default: false,
});
export const productTypeVisibleState = atom({
  key: "productTypeVisibleState",
  default: false,
});
export const productTypeState = atom({
  key: "productTypeState",
  default: "",
});
