/* eslint-disable no-unused-vars */
import React from "react";
import { Modal, Button, Switch, Tooltip, Checkbox, DatePicker, Popconfirm } from "antd";
import TextArea from "antd/lib/input/TextArea";
import NotePreview from "../notes/previewNote";

export default function SetStatusDeceased({ visible, setDeceasedState }) {
  const [modalText, setModalText] = React.useState("Content of the modal");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const deceasedReasonModal = () => {
    setDeceasedState(true);
  };

  const handleOk = () => {
    setModalText("Reason is added !!!");
    setConfirmLoading(true);
    setTimeout(() => {
      setDeceasedState(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setDeceasedState(false);
  };
  return (
    <>
      <div className="d-flex">
        <div className="input-group input-date with-addon">
          <div className="input-group-prepend">
            <Checkbox for="deceasedDate" onChange={(e) => setDeceasedState(e)}></Checkbox>
          </div>
          <DatePicker className="formControl" id="deceasedDate" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" disabled />
          <label className="formLabel">Deceased Date</label>
        </div>
        <div className="note-preview">
          <NotePreview>Deceased notes if the prescriber is deceased, telling the reason</NotePreview>
        </div>
      </div>
      <Modal
        className="setStatus-modal"
        title="Deceased Reason"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        visible={visible}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Button className="btn btn-close">
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <div className="row align-items-center">
          <div className="col-12">
            <TextArea className="input-note" placeholder="Add Reason" rows={4} />
          </div>
        </div>
      </Modal>
    </>
  );
}
