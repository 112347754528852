import React, { useState, useEffect } from "react";
import { Tree, Input, Tooltip, Table, Checkbox, Skeleton } from "antd";
import { fetchPermission } from "../../../../utils/methods/apiMethods";
import SectionLoader from "Components/common/loader/section";
import { useRecoilState } from "recoil";
import { rolesStates, selectedRoles, permissionState } from "../../../../utils/constant/roles/atom";


function randomKey() {
  return Math.random().toString(36).substring(7);
}



export default function Permissions(props) {
  // const [checkStrictly, setCheckStrictly] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [permissins, setPermission] = useRecoilState(permissionState);
  const [selectedKeys, setSelectedKey] = useRecoilState(selectedRoles);
  const [rolesState, setRole] = useRecoilState(rolesStates);

  useEffect(() => {
    async function preInfoCall() {
      await fetchPermissions();
    }

    preInfoCall();
  }, []);

  const fetchPermissions = async () => {
    setLoading(true);
    if (permissins.length === 0) {
      let response = await fetchPermission();


      let pers = [];
      response.data.data.object.forEach((item) => {
        let perm = [];

        item.pageFeatures.forEach((i) => {
          let res = {
            id: i.secPermissionId,
            key: i.secPermissionId,
            title: i.permissionName,
            className: "level level-two",

          }
          perm.push(res);
        })
        let obj = {
          key: item.pagePermission.secPermissionId,
          id: item.pagePermission.secPermissionId,
          title: item.pagePermission.permissionName,
          className: "level level-one",
          children: perm

        }
        pers.push(obj)
      })
      setPermission(pers);
    }

    setLoading(false);
  };
  const onSelect = (selectedKeys, info) => {
    setSelectedKey(selectedKeys);

  };

  const onCheck = (checkedKeys, info) => {
    setSelectedKey(checkedKeys);


    let roleArray = [];

    checkedKeys.forEach((item) => {
      let exists = permissins.filter((record) => record.key === item);

      if (exists.length > 0) {
        let exist = rolesState.filter((record) => record.id === item);
        if (exist.length === 0) {

          let filterExist = props.orginalPermission.filter((record) => record.id === item);
          console.log(filterExist);
          if (filterExist.length === 0) {
            let obj = {
              description: exists[0].title,
              id: exists[0].id,
              name: exists[0].title,
              sec_Roles_Permission_Id: 0,
            };
            roleArray.push(obj);
          }
          else {
            roleArray.push(filterExist[0]);
          }


        } else {
          roleArray.push(exist[0]);
        }

      } else {
        permissins.forEach((per) => {
          let exists = per.children.filter((r) => r.key === item);
          if (exists.length > 0) {
            let exist = rolesState.filter((record) => record.id === item);
            if (exist.length === 0) {
              let filterExist = props.orginalPermission.filter((record) => record.id === item);
              if (filterExist.length === 0) {
                let obj = {
                  description: exists[0].title,
                  id: exists[0].id,

                  name: exists[0].title,
                  sec_Roles_Permission_Id: 0,
                };
                roleArray.push(obj);
              }
              else {
                roleArray.push(filterExist[0]);
              }

            }
            else {
              roleArray.push(exist[0]);
            }

          }
        });
      }
    });

    setRole(roleArray);
  };


  return (
    <>
      {loading === false ? <Tree className="premision-roles-tree" checkedKeys={selectedKeys} onSelect={onSelect} onCheck={onCheck} checkable treeData={permissins} /> : <Skeleton active />}
    </>
  );
}
