import React, { Suspense, useState } from "react";
import { Modal, Button, Select, InputNumber } from "antd";
import { amountFormatter, amountParser } from "../../../../utils/methods/universal";
import { billingDetailState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";

export default function BillingDetail() {
  const [current, setCurrent] = React.useState(0);
  const [isBillingDetail, setBillingDetail] = useRecoilState(billingDetailState);

  const billingDetailModal = () => {
    setBillingDetail(true);
  };
  const handleOk = async (e) => {
    setBillingDetail(false);
  };

  const handleCancel = async (e) => {
    setBillingDetail(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        style={{ top: 20 }}
        width={1000}
        className="modal-claim-detail"
        title="Claim Details"
        visible={isBillingDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn" onClick={handleCancel}>
                Print Label
              </Button>
              <Button className="btn" onClick={handleCancel}>
                Print Label & Monograph
              </Button>
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="mt-0 bg-ins pri">
            <div className="row">
              <div className="col-12 mx-auto">
                <section className="custom-table mx-0 border-0 mb-0">
                  <div className="section-title mb-2">
                    <span className="title-text">Primary Insurance</span>
                    <div className="detail-group d-inline">
                      <div className="detail-block float-left">
                        <div className="detail-info">
                          <label>BIN #</label>
                          <span>NA</span>
                        </div>
                        <div className="detail-info">
                          <label>PBM</label>
                          <span>NA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th width="100">Ing.</th>
                        <th width="100">
                          <span>Dispensing</span>Fee
                        </th>
                        <th width="100">
                          <span>Other</span> Fee
                        </th>
                        <th width="100">
                          <span>Sale</span> Tax
                        </th>
                        <th width="100">
                          <span>Other</span> Amount
                        </th>
                        <th width="100">Total</th>
                        <th width="100">
                          <span>Insurance</span> Approved
                        </th>
                        <th width="110">
                          <span>Insurance</span> Paid
                        </th>
                        <th width="100">Copay</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white"> Billing</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white width-88"> Paid</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="info-bar info-bar-sm info-bar-bottom mt-0">
                  <div className="info-block amount">
                    <label>App. Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Paid Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Copay</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Cost</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Profit</label>
                    <span>0.00</span>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section className="mt-0 bg-ins sec">
            <div className="row">
              <div className="col-12 mx-auto">
                <section className="custom-table mx-0 border-0 mb-0">
                  <div className="section-title mb-2">
                    <span className="title-text">Secondary Insurance</span>
                    <div className="detail-group d-inline">
                      <div className="detail-block float-left">
                        <div className="detail-info">
                          <label>BIN #</label>
                          <span>NA</span>
                        </div>
                        <div className="detail-info">
                          <label>PBM</label>
                          <span>NA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th width="100">Ing.</th>
                        <th width="100">
                          <span>Dispensing</span>Fee
                        </th>
                        <th width="100">
                          <span>Other</span> Fee
                        </th>
                        <th width="100">
                          <span>Sale</span> Tax
                        </th>
                        <th width="100">
                          <span>Other</span> Amount
                        </th>
                        <th width="100">Total</th>
                        <th width="100">
                          <span>Insurance</span> Approved
                        </th>
                        <th width="110">
                          <span>Insurance</span> Paid
                        </th>
                        <th width="100">Copay</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white"> Billing</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white width-88"> Paid</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="info-bar info-bar-sm info-bar-bottom mt-0">
                  <div className="info-block amount">
                    <label>App. Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Paid Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Copay</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Cost</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Profit</label>
                    <span>0.00</span>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section className="mt-0 bg-ins tri">
            <div className="row">
              <div className="col-12 mx-auto">
                <section className="custom-table mx-0 border-0 mb-0">
                  <div className="section-title mb-2">
                    <span className="title-text">Tertiary Insurance</span>
                    <div className="detail-group d-inline">
                      <div className="detail-block float-left">
                        <div className="detail-info">
                          <label>BIN #</label>
                          <span>NA</span>
                        </div>
                        <div className="detail-info">
                          <label>PBM</label>
                          <span>NA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th width="100">Ing.</th>
                        <th width="100">
                          <span>Dispensing</span>Fee
                        </th>
                        <th width="100">
                          <span>Other</span> Fee
                        </th>
                        <th width="100">
                          <span>Sale</span> Tax
                        </th>
                        <th width="100">
                          <span>Other</span> Amount
                        </th>
                        <th width="100">Total</th>
                        <th width="100">
                          <span>Insurance</span> Approved
                        </th>
                        <th width="110">
                          <span>Insurance</span> Paid
                        </th>
                        <th width="100">Copay</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white"> Billing</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white width-88"> Paid</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="info-bar info-bar-sm info-bar-bottom mt-0">
                  <div className="info-block amount">
                    <label>App. Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Paid Amt.</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Copay</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Cost</label>
                    <span>0.00</span>
                  </div>
                  <div className="info-block amount">
                    <label>Profit</label>
                    <span>0.00</span>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
