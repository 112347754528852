import React from "react";
import { Modal, Select, Tooltip, Image } from "antd";
import { GlassMagnifier } from "react-image-magnifiers";
import PatientDummyDoc from "Assets/img/docs/patient-medical-record.png";
import { Link } from "react-router-dom";
import DocumentPreview from "./docView";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { Option } = Select;

export default function DocumentList() {
  const docFileList = [
    {
      uid: "-1",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      url: "https://calrads.org/wp-content/uploads/2018/01/Patient-Registration.png",
      thumburl: "https://calrads.org/wp-content/uploads/2018/01/Patient-Registration.png",
      status: "done",
      check: true,
    },
    {
      uid: "-2",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      url:
        "https://www.researchgate.net/profile/Susan_Scott29/publication/26258549/figure/fig5/AS:590430500564993@1517781009139/Documentation-form-used-by-rapid-response-team.png",
      thumburl:
        "https://www.researchgate.net/profile/Susan_Scott29/publication/26258549/figure/fig5/AS:590430500564993@1517781009139/Documentation-form-used-by-rapid-response-team.png",
      status: "done",
    },
    {
      uid: "-3",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      url: "https://calrads.org/wp-content/uploads/2018/01/Patient-Registration.png",
      thumburl: "https://calrads.org/wp-content/uploads/2018/01/Patient-Registration.png",
      status: "done",
    },
    {
      uid: "-4",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      url: "https://i.pinimg.com/736x/5d/62/9c/5d629c5d5ca9a3a12f00094621ad28c2.jpg",
      thumburl: "https://i.pinimg.com/736x/5d/62/9c/5d629c5d5ca9a3a12f00094621ad28c2.jpg",
      status: "done",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
    {
      uid: "-5",
      name: "PatDoc.png",
      type: "Patient Docs",
      uploadBy: "Abdul Rafay",
      uploadOn: "MM/DD/YYYY",
      status: "error",
    },
  ];

  return (
    <>
      <section className="bg-white doc-upload-list">
        {docFileList.map((item) => {
          return (
            <Checkbox className="card">
              <span className="doc-thumb">
                <img src={PatientDummyDoc} />
              </span>
              <div className="doc-detail">
                <span className="doc-name" title="1231kmnlkjl12n123908ukjsdfhiu3.png">
                  1231kmnlkjl12n123908ukjsdfhiu3.png
                </span>
                <small className="doc-type">Patient Document</small>
                <small className="doc-uploadBy">Abdul Rafay</small>
                <small className="doc-uploadOn">12-03-2020</small>
              </div>
              <span className="doc-actions">
                <Tooltip title="Delete" placement="leftTop">
                  <Link className="icon-delete">
                    <i className="mdi mdi-delete"></i>
                  </Link>
                </Tooltip>
              </span>
            </Checkbox>
          );
        })}
      </section>
    </>
  );
}
