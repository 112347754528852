import React, { useState } from "react";
import { AutoComplete, Select, Input, Divider } from "antd";
import { Link } from "react-router-dom";
import { patientDetailState } from "../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";
import PatientDetail from "../../../pages/Private/Patient/patientDetail";

const SearchPatient = (addState, onSelectPatient) => {
  const [isPatientDetail, setPatientDetail] = useRecoilState(patientDetailState);

  const patientDetailModal = () => {
    setPatientDetail(true);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
    document.getElementById("prescriber").focus();
  };

  const [options, setOptions] = useState([]);
  const { Option } = Select;
  const handleSearch = (value) => {
    setOptions(value ? searchPatient(value) : []);
  };

  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const searchPatient = (name, dob, onSelect) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((items, idx) => {
        const patientName = `${name}${idx}`;
        const patientDOB = `10-10-198${idx}`;
        const patientCell = `123 123 1234${idx}`;
        return {
          value: patientName,

          label: (
            <div className="search-dropdown-item">
              <span className="search-td search-name">
                <i>Name:</i> {patientName}
              </span>
              <span className="search-td search-dob">
                <i>DOB:</i>
                {patientDOB}
              </span>
              <span className="search-td search-cell">
                <i>Cell:</i>
                {patientCell}
              </span>
            </div>
          ),
        };
      });
  };
  return (
    <>
      <div className="d-none">
        <PatientDetail />
      </div>
      <div className="input-patient input-autocomplete">
        <AutoComplete
          className="formControl"
          dropdownClassName="search-result patient"
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          notFoundContent="No Record Found"
          autoFocus
          dropdownRender={(menu) => (
            <>
              {menu}
              {addState && (
                <>
                  <Divider style={{ margin: ".5rem 0" }} />
                  <div className="btn-wrap">
                    <Link className="btn" onClick={() => patientDetailModal(true)}>
                      <i className="mdi mdi-plus-circle" /> <span>Paient</span>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}>
          <Input.Search size="medium" placeholder="" />
        </AutoComplete>
        <label className="formLabel">Patient</label>
      </div>
    </>
  );
};
export default SearchPatient;
