import React, { useState } from "react";
import { AutoComplete, Select, Input, Divider } from "antd";
import { Link } from "react-router-dom";
import { drugDetailState } from "../../../utils/constant/drug/atom";
import { useRecoilState } from "recoil";
import DrugSearch from "../../../pages/Private/Product/drugSearch";

export default function SearchProduct() {
  const [options, setOptions] = useState([]);
  const { Option } = Select;
  const [isDrugDetailModal, setDrugDetailModal] = useRecoilState(drugDetailState);

  const drugDetailModal = () => {
    setDrugDetailModal(true);
  };
  const handleSearch = (value) => {
    setOptions(value ? searchProduct(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };
  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const searchProduct = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const productName = `${name}${idx}`;
        const productStg = `500mg${idx}`;
        return {
          value: productName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {productName}
              </span>
              <span className="search-generic">
                <i>DOB:</i>
                {productStg}
              </span>
            </div>
          ),
        };
      });
  };
  return (
    <>
      <div className="d-none">
        <DrugSearch />
      </div>
      <div className="input-product input-autocomplete">
        <AutoComplete
          className="formControl"
          dropdownClassName="search-result product"
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          notFoundContent="No Record Found"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: ".5rem 0" }} />
              <div className="btn-wrap">
                <Link className="btn" onClick={() => drugDetailModal(true)}>
                  <i className="mdi mdi-plus-circle" /> <span>Drug</span>
                </Link>
              </div>
            </>
          )}>
          <Input.Search placeholder="" />
        </AutoComplete>
        <label className="formLabel">Drug</label>
      </div>
    </>
  );
}
