import React from "react";
import { Button, Select, Tooltip } from "antd";
import DocumentList from "../../../../components/common/dms/components/docList";
import DocumentPreview from "../../../../components/common/dms/components/docView";

export default function PatientDocuments() {
  const { Option } = Select;
  return (
    <>
      <div className="input-group input-lg" style={{ position: "absolute", top: "-2.9rem" }}>
        <Select size="large" placeholder="Select Document Type" defaultValue="1" autoFocus>
          <Option value="1">Patient Docs</Option>
          <Option value="2">Insurance Card</Option>
          <Option value="3">Lab Reports</Option>
          <Option value="4">Delivery Slips</Option>
        </Select>
        <label className="formLabel">Select Document Type</label>
      </div>
      <section className="bg-primary border-0">
        {/* <section className="bg-secondary mb-0">
          <Button className="btn btn-upload float-right">
            <i className="mdi mdi-upload"></i>
            <span>Upload</span>
            <input type="file" />
          </Button>
          <div className="d-flex justify-content-end">
            <Button className="btn btn-scan">Scan</Button>
            <Button className="btn btn-fax">Send Fax</Button>
          </div>
        </section> */}
        <div className="document-view">
          <DocumentList />
          <section className="document-preview-panel ml-5 my-0 p-0">
            <div className="action-btns p-0">
              <Tooltip title="Scan" placement="right">
                <Button className="btn btn-icon btn-scan">
                  <span></span>
                </Button>
              </Tooltip>
              <Tooltip title="Upload" placement="right">
                <span className="btn btn-icon btn-upload float-right">
                  <i></i>
                  <input type="file" />
                </span>
              </Tooltip>
              <Tooltip title="Send Fax" placement="right">
                <Button className="btn btn-icon btn-fax">
                  <span></span>
                </Button>
              </Tooltip>
            </div>
            <DocumentPreview />
          </section>
        </div>
      </section>
    </>
  );
}
