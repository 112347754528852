/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, Input, Button, notification } from "antd";
import Permissions from "./permissions";
import { useRecoilState } from "recoil";
import { rolesStates, selectedRoles, rolesVisibleState } from "../../../../utils/constant/roles/atom";
import { storeRole, editRole, updateRole } from "../../../../utils/methods/apiMethods";
// import AddRoles from './addRoles';

function AddRolesPermission(props) {
  const [state, setState] = useState({
    visible: false,
    loading: false,
    addPermissions: false,
    id: "",
    mockData: [],
    targetKeys: [],
  });
  const [visible, setVisible] = useRecoilState(rolesVisibleState);
  const [description, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [rolesState, setRole] = useRecoilState(rolesStates);
  const [selected, setSelectedRoles] = useRecoilState(selectedRoles);
  const [orginalPermission, setPer] = useState([]);


  useEffect(() => {
    async function fetchMyAPI() {
      let res = await editRole(props.id);
      let selectedID = [];
      let selectedPermission = [];
      if (res.data.status === "SUCCESS") {

        setState({
          ...state,
          ...res.data.object.roleDto,
          addPermissions: true,
          id: res.data.object.roleDto.secRoleId
        })
        setName(res.data.object.roleDto.roleName);
        res.data.object.rolePermissionsDtos.forEach((item) => {
          selectedID.push(item.secPermissionId);

          let obj = {
            ...item,
            id: item.secPermissionId
          }

          selectedPermission.push(obj)

        })
        setSelectedRoles(selectedID);
        setRole(selectedPermission);
        setPer(selectedPermission);

      }
    }



    if (visible === true && props.id !== "") {
      setVisible(true);
      props.setID("");
      fetchMyAPI()

    }









  }, [props, props.id, setRole, setSelectedRoles, setVisible, state, visible]);

  const addPermissions = () => {
    setState({
      ...state,
      addPermissions: true,
    });
  };
  const addRolesPermissionModal = () => {

    setRole([]);
    setSelectedRoles([]);
    setVisible(true);
    props.setID("");
    setName("");
    setState({
      visible: false,
      loading: false,
      addPermissions: false,
      id: "",
      mockData: [],
      targetKeys: [],
    })
    setPer([]);
  };

  const handleOk = async (e) => {

    if (description === "") {
      notification.error({
        message: "Error",
        description: "Name Required!",
      });
      return;
    }
    if (rolesState.length === 0) {
      notification.error({
        message: "Error",
        description: "Permission Required!",
      });
      return;
    }

    if (state.id === "") {
      let rolePermissionsDtos = [];
      console.log(rolesState);
      rolesState.forEach((item) => {
        let obj = {
          "activated": true,
          "createdBy": 0,
          "groupId": 0,
          "modifiedBy": 0,
          "parentLayoutId": 0,
          "plid": 0,
          "roleId": 0,
          "secPermissionId": item.id,
          "sec_Roles_Permission_Id": 0,
          "storeId": 0
        }

        rolePermissionsDtos.push(obj);
      })

      let roleDto = {
        "createdBy": 0,
        "groupId": 0,
        "modifiedBy": 0,
        "roleName": description,
        "secRoleId": 0,
        "storeId": 0
      }


      let obj = {
        roleDto: roleDto,
        rolePermissionsDtos: rolePermissionsDtos,

      };
      setLoading(true);
      const response = await storeRole(obj);
      if (response.status) {
        setLoading(false);
        notification.success({
          message: "Success",
          description: "Role created successfully",
        });
      } else {
        setLoading(false);
      }
    }
    else {
      let roleDto = {
        "createdBy": state.createdBy,
        "groupId": state.groupId,
        "modifiedBy": state.modifiedBy,
        "roleName": description,
        "secRoleId": state.secRoleId,
        "storeId": state.storeId
      }
      let rolePermissionsDtos = [];
      let deletedRolePermissionIds = [];
      let results = orginalPermission.filter(({ id: id1 }) => !rolesState.some(({ id: id2 }) => id2 === id1));

      results.forEach((item) => {
        deletedRolePermissionIds.push(item.sec_Roles_Permission_Id);
      })

      rolesState.forEach((item) => {




        let obj = {
          "activated": true,
          "createdBy": !item.createdBy ? 0 : item.createdBy,
          "groupId": !item.groupId ? 0 : item.groupId,
          "modifiedBy": !item.modifiedBy ? 0 : item.modifiedBy,
          "parentLayoutId": !item.parentLayoutId ? 0 : item.parentLayoutId,
          "plid": !item.plid ? 0 : item.plid,
          "roleId": !item.roleId ? 0 : item.roleId,
          "secPermissionId": item.id,
          "sec_Roles_Permission_Id": !item.sec_Roles_Permission_Id ? 0 : item.sec_Roles_Permission_Id,
          "storeId": !item.storeId ? 0 : item.storeId
        }

        rolePermissionsDtos.push(obj);
      });
      let obj = {
        roleDto: roleDto,
        rolePermissionsDtos: rolePermissionsDtos,
        deletedRolePermissionIds: deletedRolePermissionIds,

      };

      setLoading(true);
      const response = await updateRole(obj);
      if (response.status) {
        setLoading(false);
        notification.success({
          message: "Success",
          description: "Role Update successfully",
        });
      } else {
        setLoading(false);
      }
    }

    setRole([]);
    setSelectedRoles([]);
    setVisible(false);
    props.setEditVisible(false);
    props.setID("");
    setName("");
    setState({
      visible: false,
      loading: false,
      addPermissions: false,
      id: "",
      mockData: [],
      targetKeys: [],
    })
    setPer([]);

  };

  const handleCancel = (e) => {
    setVisible(false);
    props.setEditVisible(false);
    props.setID("");
    setName("");
    setState({
      visible: false,
      loading: false,
      addPermissions: false,
      id: "",
      mockData: [],
      targetKeys: [],
    })
  };


  console.log(visible);
  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={addRolesPermissionModal}>
        Role(s) & Permission(s)
      </Button>
      <Modal
        style={{ top: 20 }}
        width={500}
        className="modal-role-permission"
        title="Role(s) & Premission(s)"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button loading={loading} className={!loading ? state.id === "" ? "btn btn-save" : "btn btn-update" : "btn"} onClick={() => handleOk()}>
                <span></span>
              </Button>
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>

        <section className="bg-primary">
          <div className="input-group input-xxl">
            <Input
              className="formControl"
              name="description"
              value={description}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              size="large"
              autoFocus
              required
            />
            <label className="formLabel">Name</label>
          </div>
          <button className="btn btn-icon btn-lg btn-add ml-3" icon={<i className="mdi mdi-plus-circle" />} onClick={() => addPermissions()}>
            <span></span>
          </button>
        </section>
        {state.addPermissions ? (
          <>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <Permissions orginalPermission={orginalPermission} />
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
}

export default AddRolesPermission;
