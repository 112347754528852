import React, { useState } from "react";
import { Checkbox, DatePicker, Input, Radio, Select, Space, Switch, Tooltip } from "antd";
import ContactInfo from "Components/common/contactInfo";
import SetStatus from "Components/common/setStatus";
import { MaskedInput } from "antd-mask-input";
import DeliveryAddress from "./addressDelivery";
import FamilyMembers from "./familyMembers";

const { Option } = Select;

export default function PatientInfo() {
  const [state, setState] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    isWorkTel: true,
    isAddress: true,
    isPatient: true,
    cityId: 0,
    businessPhone: "",
    cellPhone: "",
    email: "",
    fax: "",
    deliveryHandler: 0,
    willCall: 0,
  });
  async function handleChange(evt) {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
      willCall: evt.target.value,
      deliveryHandler: evt.target.value,
    });
  }

  // constructor(props) {
  //   super(props);
  //   this.state = { status: 0 }; // 0: no show, 1: show yes, 2: show no.
  // }

  return (
    <>
      <form>
        <section className="bg-primary pat-info">
          <div className="row flex-nowrap w-100 pr-4">
            <div className="col-lg-7 py-2">
              <div className="row">
                <div className="col-12">
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="Patient #" disabled />
                    <label className="formLabel">Patient #</label>
                  </div>
                  <SetStatus />
                </div>
                <section className="px-0 py-2 bg-secondary">
                  <div className="d-flex flex-wrap">
                    <div className="col-12">
                      {/* <div className="input-group width-10">
                      <Select className="formControl" defaultValue="mr">
                      <Option value="mr">Mr.</Option>
                      <Option value="ms">Ms.</Option>
                      </Select>
                      <label className="formLabel">Title</label>
                    </div> */}
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="Last Name" autoFocus />
                        <label className="formLabel">Last Name</label>
                      </div>
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="First Name" />
                        <label className="formLabel">First Name</label>
                      </div>
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="MI" />
                        <label className="formLabel">MI</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-date with-addon">
                        <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" name="dateOfBirth" />
                        <Tooltip title="Date of Birth">
                          <label className="formLabel">DOB</label>
                        </Tooltip>
                      </div>
                      <div className="input-group w-auto">
                        <hr axis="y" className="input-divider mx-4"></hr>
                      </div>
                      <div className="input-group input-date with-addon ml-0 mr-4">
                        <div className="input-group-prepend">
                          <Checkbox for="insDob" tabIndex="-1"></Checkbox>
                        </div>
                        <DatePicker id="insDob" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" className="formControl" disabled />
                        <Tooltip title="Insurance Date of Birth">
                          <label className="formLabel">INS. DOB</label>
                        </Tooltip>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-md">
                          <Select className="formControl" defaultValue="human" tabIndex="-1">
                            <Option value="human">Human</Option>
                            <Option value="animal">Animal</Option>
                          </Select>
                          <label className="formLabel">Kind</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-group-radio w-auto">
                        <Radio.Group defaultValue={1}>
                          <span className="radio-label">Gender</span>
                          <Radio value={1}>M</Radio>
                          <Radio value={2}>F</Radio>
                          <Radio value={3}>U</Radio>
                        </Radio.Group>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                            defaultChecked
                          />
                          <label className="formLabel">Autofill</label>
                        </div>
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-red"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                          />
                          <label className="formLabel">Stars</label>
                        </div>
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                          />
                          <label className="formLabel">EZ Cap</label>
                        </div>
                        {/* <div className="input-group w-auto">
                    <Checkbox>House Charge</Checkbox>
                  </div> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-md">
                        <MaskedInput className="formControl" name="ssn" placeholder="SSN" mask="111 11 1111" />
                        <label className="formLabel">SSN</label>
                      </div>
                      <div className="input-group input-md">
                        <Input className="formControl" placeholder="Medicare #" />
                        {/* <MaskedInput className="formControl" placeholder="Medicare #" mask="1111-111-1111" name="medicareNo" /> */}
                        <label className="formLabel">Medicare #</label>
                      </div>
                      <div className="input-group input-md">
                        <Input className="formControl" placeholder="Driving License #" />
                        <label className="formLabel">Driving License #</label>
                      </div>
                      <div className="input-group input-state input-xs">
                        <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                        <label className="formLabel">St.</label>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="col-12 mt-3">
                  <div className="input-group input-lg">
                    <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                      <Option value="0">0-Not Specified</Option>
                      <Option value="1">1-Home</Option>
                      <Option value="2">2-Inter Care</Option>
                      <Option value="3">3-Nursing Home</Option>
                      <Option value="4">4-LTC/Extended care</Option>
                      <Option value="5">5-Rest Home</Option>
                      <Option value="6">6-Boarding Home</Option>
                      <Option value="7">7-Skilled Care Facility</Option>
                      <Option value="8">8-Sub-Acute Care Facility</Option>
                      <Option value="9">9-Acute Care Facility</Option>
                      <Option value="10">10-Hospice</Option>
                      <Option value="11">11-Homeless Selther</Option>
                    </Select>
                    <label className="formLabel">Patient Residence</label>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Race</label>
                    </div>
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Ethnicity</label>
                    </div>
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Occupation</label>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3 px-0">
                  <section className="contact-info outer-title pat-address border-bottom-0 border-top-0 mt-3 mb-0">
                    <div className="section-title">
                      <span className="title-text">Home Address</span>
                    </div>
                    <ContactInfo state={state} type={"company"} handleChange={handleChange} />
                  </section>
                  <section className="border-0 mt-0 pr-0 d-flex align-items-center  ">
                    <div className="input-group input-group-radio radio-block px-3">
                      <Radio.Group list size="large" onChange={handleChange} defaultValue={0}>
                        <span className="radio-label d-block  mb-3 pl-0" style={{ fontSize: "1.25rem", color: "green" }}>
                          Will Call
                        </span>
                        <Radio value={0}>Pickup</Radio>
                        <Radio value={1}>Delivery</Radio>
                      </Radio.Group>
                    </div>
                    {state.willCall === 1 ? <DeliveryAddress /> : ""}
                  </section>
                </div>
              </div>
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-5 pt-5 px-0">
              <section className="pat-emergency-info outer-title mb-0 border-bottom-0 my-5">
                <div className="section-title">
                  <span className="title-text">Emergency Contact</span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Last Name" />
                      <label className="formLabel">Last Name</label>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="First Name" />
                      <label className="formLabel">First Name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Cell"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Cell" position="top">
                            <i className="mdi mdi-cellphone-iphone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Home Tel"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Home Tel" position="top">
                            <i className="mdi mdi-phone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="outer-title pb-0 border-bottom-0 mb-5">
                <div className="section-title">
                  <span className="title-text">Caregiver Contact</span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Last Name" />
                      <label className="formLabel">Last Name</label>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="First Name" />
                      <label className="formLabel">First Name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Cell"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Cell" position="top">
                            <i className="mdi mdi-cellphone-iphone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Home Tel"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Home Tel" position="top">
                            <i className="mdi mdi-phone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                  </div>
                </div>
              </section>
              <FamilyMembers />
              <section className="outer-title bg-secondary mb-0 pb-0 border-bottom-0" style={{ position: "absolute", bottom: "0", right: "-1.5rem" }}>
                <div className="section-title">
                  <span className="title-text">MedSYNC Info.</span>
                </div>
                <div className="input-group input-date with-addon mb-2">
                  <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" tabIndex="-1" />
                  <label className="formLabel">MedSYNC Date</label>
                </div>
                <div className="d-inline-block w-100">
                  <div className="input-group input-date with-addon">
                    <div className="input-group-prepend">
                      <Checkbox for="medSyncAttestation" tabIndex="-1"></Checkbox>
                    </div>
                    <DatePicker
                      className="formControl"
                      id="medSyncAttestation"
                      placeholder="MM/DD/YYYY"
                      format="MM/DD/YYYY"
                      style={{ width: "200px" }}
                      tabIndex="-1"
                    />
                    <label className="formLabel">MedSYNC Attest</label>
                  </div>
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="Attested By" style={{ width: "140px" }} disabled tabIndex="-1" />
                    <label className="formLabel">Attested By</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <hr className="mb-0"></hr> */}
        </section>
      </form>
    </>
  );
}
