/* eslint-disable no-unused-vars */
import { Checkbox, Tooltip } from "antd";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ContactInfo from "Components/common/contactInfo";
import { getCity } from "../../../../utils/methods/apiMethods";
import { companyAddress, mailingContact } from "../../../../utils/constant/company/atom";

export default function DeliveryAddress() {
  const [isMailing, setMailing] = useState(false);

  const [address, addressState] = useRecoilState(mailingContact);
  const companyState = useRecoilValue(companyAddress);

  async function handleChange(evt) {
    const value = evt.target.value;
    if (evt.target.name === "zip") {
      let zip = value.replace(/_/g, "").toString();

      if (zip.length >= 3) {
        const response = await getCity(zip);
        console.log(response, isMailing);

        if (response.status) {
          addressState({
            ...address,
            zip: response.data.postalCode,
            cityId: response.data.id,
            city: response.data.name,
            state: response.data.stateName,
          });
        } else {
          addressState({
            ...address,
            // zip:value,
            cityId: 0,
            city: "",
            state: "",
          });
        }
      } else {
        // addressState({
        //   ...address,
        //   // zip:value,
        // });
      }

      if (zip === "") {
        addressState({
          ...address,
          zip: "",
          cityId: "",
          city: "",
          state: "",
        });
      }
    } else {
      addressState({
        ...address,
        [evt.target.name]: value,
      });
    }
  }

  function handleCheckBox(e) {
    if (e.target.checked) {
      setMailing(true);
      addressState({
        addressLine1: companyState.addressLine1,
        addressLine2: companyState.addressLine2,
        city: companyState.city,
        state: companyState.state,
        zip: companyState.zip,
        isWorkPhone: false,
        isAddress: false,
        cityId: companyState.cityId,
        businessPhone: companyState.businessPhone,
        cellPhone: companyState.cellPhone,
        email: companyState.email,
        fax: companyState.fax,
      });
    } else {
      setMailing(false);
      addressState({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        isWorkPhone: false,
        isAddress: false,
        cityId: 0,
        businessPhone: "",
        cellPhone: "",
        email: "",
        fax: "",
      });
    }
  }

  return (
    <section className="contact-info delivery-address outer-title mb-0 border-bottom-0 border-top-0">
      <div className="section-title">
        <span className="title-text" onChange={handleCheckBox}>
          Delivery Address
        </span>
      </div>
      <ContactInfo type="mailing" state={address} handleChange={handleChange} />
    </section>
  );
}
