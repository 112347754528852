/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal, Button, Switch, Tooltip, Popconfirm } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRecoilState } from "recoil";
import { blockedState } from "../../../utils/constant/insurance/atom";
import NotePreview from "../notes/previewNote";

export default function SetStatusBlocked() {
  // const [isBlockedState, setBlockedState] = useState(blockedState);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Content of the modal");
  const [isBlocked, setBlocked] = useState(false);
  const [isBlockedState, setBlockedState] = useRecoilState(blockedState);

  const handleOk = () => {
    setModalText("Reason is added !!!");
    setConfirmLoading(true);
    setTimeout(() => {
      setBlockedState(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setBlockedState(false);
  };
  return (
    <>
      <div className="input-group input-switch input-sm">
        <Switch
          size="small"
          className="formControl bg-green"
          name="toggleBlocked"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close"></i>}
          onChange={(e) => setBlockedState(e)}
          checked={isBlocked}
        />
        <label className="formLabel">Blocked</label>
      </div>
      {/* {isBlocked ? ( */}
      <div className="section-note w-100">
        <span className="note-preview w-100">
          <NotePreview ellipsis={{ rows: 1 }}>Blocked note preview in case of insurance is marked as blocked.</NotePreview>
        </span>
      </div>
      {/* ) : null} */}
      <Modal
        className="setStatus-modal"
        title="Blocked Reason"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        visible={isBlockedState}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <div className="row align-items-center">
          <div className="col-12">
            <TextArea className="input-note" placeholder="Add Reason" rows={4} />
          </div>
        </div>
      </Modal>
    </>
  );
}
