import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Tooltip, DatePicker, Input, Switch, Select } from "antd";
import { useRecoilState } from "recoil";
import { rphVerifyState } from "../../../../utils/constant/order/atom";
import TextArea from "antd/lib/input/TextArea";
import DocumentPreview from "../../../../components/common/dms/components/docView";
import ThumbnailImg from "../../../../assets/img/thumbnail-images.jpg";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { transmissionLogState } from "../../../../utils/constant/processing/atom";
import TransmissionLog from "../../Processing/components/transmissionLog";
import Password from "antd/lib/input/Password";
import { LockOutlined } from "@ant-design/icons";

export default function RphVerify() {
  const { Option } = Select;
  const [isrphVerify, setrphVerify] = useRecoilState(rphVerifyState);
  const [isTransmissionLog, setTransmissionLog] = useRecoilState(transmissionLogState);

  const tLogModal = () => {
    setTransmissionLog(true);
  };

  const rphVerifyModal = () => {
    setrphVerify(true);
  };

  const handleOk = () => {
    setrphVerify(false);
  };

  const handleCancel = () => {
    setrphVerify(false);
  };

  return (
    <>
      <Suspense className="d-none">
        <TransmissionLog />
      </Suspense>
      <Button type="primary" onClick={rphVerifyModal}>
        Rph Verification
      </Button>
      <Modal
        width={1400}
        style={{ top: 20 }}
        className="modal-rph-verify"
        title="Rph Verification"
        visible={isrphVerify}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-left">
              <Button className="btn btn-edit">Edit Rx</Button>
              <Button className="btn btn-med-history">Med History</Button>
              <Button className="btn btn-profile">Profile</Button>
              <Button className="btn btn-t-log" onClick={() => tLogModal(true)}>
                T. Log
              </Button>
            </div>
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <div className="row">
            <div className="col-lg-4">
              <section className="bg-primary border-0 mb-0">
                <div className="detail-block d-flex mt-3">
                  <div className="detail-info w-100">
                    <label className="label-info patient">Patient</label>
                    <span style={{ fontSize: "1.15rem" }}>Patient, test</span>
                  </div>
                  <div className="detail-info">
                    <label className="label-info">DOB</label>
                    <span className="text-center" style={{ fontSize: "1.15rem" }}>
                      10-25-1974
                    </span>
                  </div>
                </div>
              </section>
              <section className="bg-secondary border-0 my-1">
                <div className="detail-block d-inline-block w-100 my-3">
                  <div className="detail-info">
                    <label className="label-info">Fill date</label>
                    <span style={{ fontSize: "1.15rem" }}>12/15/2015</span>
                  </div>
                  <div className="detail-info float-right">
                    <label className="label-info">Rx #</label>
                    <span style={{ fontSize: "1.15rem" }}>123456897-00</span>
                  </div>
                </div>
                <div className="detail-block d-inline-block w-100 my-1">
                  <div className="detail-info w-100">
                    <label className="label-info">Drug</label>
                    <span>Amoxicillin</span>
                  </div>
                </div>
                <div className="detail-block w-100 my-1">
                  <div className="d-flex w-100">
                    <div className="detail-info w-100">
                      <label className="formLabel">Stg.</label>
                      <span>875 M</span>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">Form</label>
                      <span>Tab</span>
                    </div>
                  </div>
                </div>
                <div className="detail-block d-inline-block w-100 my-1">
                  <div className="detail-info w-100">
                    <label className="formLabel">SIG</label>
                    <span style={{ height: 50 }}>3 Times Daily</span>
                  </div>
                </div>
                <div className="detail-block d-flex align-items-end justify-content-between w-100 my-1 text-center">
                  <div className="d-flex align-items-end">
                    <div className="detail-info">
                      <label className="formLabel">OQ</label>
                      <span>20</span>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">AR</label>
                      <span>20</span>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">DQ</label>
                      <span style={{ fontSize: "1.25rem", background: "#a5c9e2" }}>20</span>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">DS</label>
                      <span style={{ fontSize: "1.25rem", background: "#a5c9e2" }}>6</span>
                    </div>
                  </div>
                  <div className="detail-info">
                    <label className="formLabel">RQ</label>
                    <span>6</span>
                  </div>
                </div>
              </section>
              <section className="border-0 m-0 p-0">
                <section className="outer-title bg-primary border-bottom-0 py-0">
                  <div className="section-title">
                    <span className="title-text">Prescriber</span>
                  </div>
                  <div className="detail-block d-inline-block w-100">
                    <div className="detail-info width-48">
                      {/* <label className="label-info">Prescriber</label> */}
                      <span style={{ fontSize: "1.15rem" }}>Prescriber, test</span>
                    </div>
                    <div className="detail-info width-48">
                      <span className="text-center" style={{ fontSize: "1.15rem" }}>
                        test drive 123
                      </span>
                    </div>
                  </div>
                </section>
                {/* <section>
                  <div className="section-title">
                    <span className="title-text">Patient</span>
                  </div>
                  <div className="detail-block d-inline-block w-100">
                    <div className="detail-info width-48">
                      <span style={{ fontSize: "1.15rem" }}>20</span>
                    </div>
                    <div className="detail-info width-48">
                      <span className="text-center" style={{ fontSize: "1.15rem" }}>
                        65824-0015-01
                      </span>
                    </div>
                  </div>
                </section> */}
                {/* <section className="bg-primary border-bottom-0 pb-0">
                  <div className="section-title">
                    <span className="title-text">Drug</span>
                  </div>
                  <div className="detail-block d-inline-block w-100">
                    <div className="detail-info float-right">
                      <label className="formLabel">Fill date</label>
                      <span style={{ background: "#D668F2", color: "#fff", fontSize: "1.15rem" }}>MM/DD/YYYY</span>
                    </div>
                  </div>
                  <div className="detail-block d-inline-block w-100 my-1">
                    <div className="detail-info">
                      <label className="formLabel">Drug</label>
                      <span style={{ fontSize: "1.15rem" }}>Amoxicillin</span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="detail-info">
                        <label className="formLabel">Stg.</label>
                        <span style={{ fontSize: "1.15rem" }}>875 M</span>
                      </div>
                    </div>
                  </div>
                  <div className="detail-block d-inline-block w-100 my-1">
                    <div className="detail-info w-100">
                      <label className="formLabel">SIG</label>
                      <span style={{ fontSize: "1.15rem" }}>3 Times Daily</span>
                    </div>
                  </div>
                </section>
                <section className="pb-1" style={{ background: "#D668F2" }}>
                  <div className="detail-block d-flex justify-content-center">
                    <div className="detail-info">
                      <label className="formLabel">DQ</label>
                      <span style={{ fontSize: "1.15rem" }}>20</span>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">NDC</label>
                      <span className="text-center" style={{ fontSize: "1.15rem" }}>
                        65824-0015-01
                      </span>
                    </div>
                  </div>
                </section> */}
                {/* <div className="section-title">
                  <span className="title-text">Clinicals</span>
                </div> */}
                <section className="outer-title my-0  w-100" style={{ background: "#c8c7ea" }}>
                  <div className="section-title">
                    <span className="title-text">Clinicals</span>
                  </div>
                  <div className="input-group input-md d-block">
                    <Select placeholder="Select" className="formControl" defaultValue="notSelected">
                      <Option value="notSelected">Not Selected</Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="formLabel">Insulin Dependent</label>
                  </div>
                  <div className="input-group input-md d-block">
                    <Select placeholder="Select" className="formControl" defaultValue="notSelected" autoFocus>
                      <Option value="notSelected">Not Selected</Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="formLabel">Diabetes</label>
                  </div>
                  <div className="input-group input-switch w-auto">
                    <Switch
                      size="small"
                      className="formControl bg-green"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                      defaultChecked
                      disabled
                    />
                    <label className="formLabel">Stars</label>
                  </div>
                  <div className="input-group input-switch w-auto">
                    <Switch
                      size="small"
                      className="formControl bg-green"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                      defaultChecked
                      disabled
                    />
                    <label className="formLabel">Hr Med</label>
                  </div>
                  <Button className="btn btn-primary float-right">BBW</Button>
                </section>
                <section className="pb-1 my-0" style={{ background: "#c8c7ea" }}>
                  <div className="section-title">
                    <span className="title-text">Allergies</span>
                    <div className="detail-block float-right width-65">
                      <div className="detail-info w-100">
                        <span className="width-65">
                          <ul className="allergies">
                            <li>Penicillin Antibacterial</li>
                            <li>Alpha Reducta</li>
                            <li>Penicillin Antibacterial</li>
                            <li>Alpha Reducta</li>
                            <li>Penicillin Antibacterial</li>
                            <li>Alpha Reducta</li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="mt-0 w-100 py-0" style={{ background: "#c8c7ea" }}>
                  <label className="label-link label-info">
                    <span className="label-text float-keft">Disease State(s) (ICD)</span>
                    <Link className="btn btn-info float-right">
                      <i className="mdi mdi-information mr-0"></i>
                    </Link>
                  </label>
                </section>
                {/* <section className="mt-0 w-100" style={{ background: "#c8c7ea" }}>
                  <div className="section-title">
                    <span className="title-text">Disease State(s) (ICD )</span>
                  </div>
                </section> */}
                <section className="info-bar info-bar-sm info-bar-bottom mt-4 w-100">
                  <div className="section-title">
                    <span className="title-text">Cash</span>

                    <div className="d-flex justify-content-end">
                      <div className="info-block amount">
                        <label>Amt. App.</label>
                        <span>26.00</span>
                      </div>
                      <div className="info-block amount">
                        <label>Amt. Paid</label>
                        <span>26.00</span>
                      </div>
                      <div className="info-block amount">
                        <label>Copay</label>
                        <span>26.00</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="info-block amount">
                        <label>Total</label>
                        <span>26.00</span>
                      </div>
                      <div className="info-block amount">
                        <label>Cost</label>
                        <span>26.00</span>
                      </div>
                      <div className="info-block amount profit">
                        <label>Profit</label>
                        <span>24.18</span>
                      </div>
                      {/* <div className="info-block percent">
                      <label className="label-icon">
                        <i className="label-icon mdi mdi-percent"></i>
                      </label>
                      <span>89.25</span>
                      </div> */}
                    </div>
                  </div>
                </section>
              </section>
            </div>
            <div className="col-lg-5 px-0">
              <DocumentPreview />
            </div>
            <div className="col-lg-3">
              <section className="product-thumbnail border-0 mt-0 p-0">
                <div className="img-wrapper w-100">
                  <img src={ThumbnailImg} />
                </div>
                <div className="input-group">
                  <TextArea
                    className="formControl input-notes p-1"
                    id="productDesc"
                    name="productDesc"
                    rows={4}
                    placeholder="No Description Found"
                    disabled
                  />
                  <label className="formLabel">Description</label>
                </div>
              </section>
              <section className="border-0" style={{ position: "absolute", left: 0, bottom: 0 }}>
                <section className="border-bottom-0 mb-0">
                  <div className="section-title">
                    <span className="title-text">Drug Verification</span>
                    <h5 className="float-right text-danger mb-0">Scanned</h5>
                  </div>
                  <div className="detail-block">
                    <div className="detail-info w-100">
                      <span style={{ fontSize: "1.15rem" }}>abdul.rafay</span>
                    </div>
                    <div className="detail-info">
                      <span style={{ fontSize: "1.15rem" }}>07-15-1985</span>
                    </div>
                  </div>
                </section>
                <section className="bg-secondary mt-0">
                  <div className="section-title">
                    <span className="title-text">Rph Verification</span>
                  </div>
                  <div className="detail-block">
                    <div className="detail-info w-100">
                      <span style={{ fontSize: "1.15rem" }}>abdul.rafay</span>
                    </div>
                    {/* </div>
                    <div className="d-flex justify-content-center mt-3 mb-2"> */}
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      autoFocus
                    />
                  </div>
                </section>
              </section>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
