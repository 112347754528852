import React from "react";
import { Link } from "react-router-dom";
import RysaRxLogo from "../../../assets/img/logo-rysa-rx.png";
import RysaPOSLogo from "../../../assets/img/logo-rysa-pos.png";
import { Space, Tooltip } from "antd";
import ThemeSwitcher from "react-theme-switcher";

const PharmacyFooter = () => (
  <>
    {/* <div className="brand-logos">
      <img src={RysaRxLogo} width="80px" className="mr-2" title="Rysa Rx" alt="Rysa Rx"></img>
      <img src={RysaPOSLogo} width="100px" title="Rysa POS" alt="Rysa POS"></img>
    </div> */}
    <nav>
      <ul className="list-unstyled mb-0">
        <li>
          <Space>
            {/* <ul className="presc-tab">
                <li className="active">
                  <i className="mdi mdi-numeric-1-box"></i>
                  <Link href="#">
                    <Tooltip title="Doe, John">Doe, John</Tooltip>
                  </Link>
                </li>
                <li>
                  <i className="mdi mdi-numeric-2-box"></i>
                  <Link href="#">
                    <Tooltip title="Doe, John">Doe, John</Tooltip>
                  </Link>
                </li>
                <li>
                  <i className="mdi mdi-numeric-3-box"></i>
                  <Link href="#">
                    <Tooltip title="Doe, John">Doe, John</Tooltip>
                  </Link>
                </li>
              </ul> 
              <hr axis="y" className="button-divider"></hr>*/}
            <ThemeSwitcher
              cssSelector="body"
              switcherColor="#2775cc"
              darkColor="#282c34"
              lightColor="#ffffff"
              lightTextColor="#272b33"
              darkTextColor="#ffffff"
            />
            {window.location.href.indexOf("private") > -1 ? (
              <>
                <hr axis="y" className="button-divider"></hr>
                <Link to="/" className="btn-icon">
                  <Tooltip title="Send SMS" placement="topLeft">
                    <i className="mdi mdi-cellphone-text"></i>
                  </Tooltip>
                </Link>
                {/* <Link to="/" className="btn-icon">
                <Tooltip title="Send e-Mail" placement="topLeft">
                  <i className="mdi mdi-email"></i>
                </Tooltip>
              </Link> */}
                <hr axis="y" className="button-divider"></hr>
                {/* <Tooltip title="Browse" placement="topLeft"></Tooltip> */}
                {/* <Link to="/" className="btn btn-sm btn-browse-rx"> */}
                {/* <i className="mdi mdi-file-find"> */}
                {/* <span>Browse Presc.</span> */}
                {/* </i> */}
                {/* </Link> */}
                <Link to="/" className="btn btn-sm">
                  Browse Presc.
                </Link>
                <Link to="/" className="btn btn-sm">
                  Rx PC
                </Link>
                <Link to="/" className="btn btn-sm">
                  <Tooltip title="Physical Inventory">PI</Tooltip>
                </Link>
                <Link to="/" className="btn btn-sm">
                  <Tooltip title="Expired Inventory">EI</Tooltip>
                </Link>
                <Link to="/" className="btn btn-sm">
                  <Tooltip title="Profile">Profile</Tooltip>
                </Link>
                <Link to="/" className="btn btn-sm">
                  <Tooltip title="Control Meds">Control Meds</Tooltip>
                </Link>
                <Link to="/" className="btn btn-sm">
                  <Tooltip title="Control Meds">RTS</Tooltip>
                </Link>
              </>
            ) : (
              ""
            )}
          </Space>
        </li>
      </ul>
    </nav>
  </>
);

export default PharmacyFooter;
