import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { message, Button, Modal, Tooltip, Space, Table } from "antd";
import { useRecoilState } from "recoil";
import { prescriberClinicSearchState } from "../../../../utils/constant/prescriber/atom";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

function PrescriberClinicSearchModal() {
  const [isPrescriberClinicSearch, setPrescriberClinicSearch] = useRecoilState(prescriberClinicSearchState);
  const [index, setIndex] = useState("");
  const clinicsData = [];

  for (let i = 0; i < 100; i++) {
    clinicsData.push({
      key: i,
      clinicName: `RysaTech ${i}`,
      ssId: `831204602${i}`,
      tel: `831 204 532`,
      fax: `831 204 532`,
      dea: `123456789${i}`,
      npi: `123456789${i}`,
      licenseNo: `23123234${i}`,
      address: `Mayo Clinic, Kingcity, CA 9393${i}`,
    });
  }

  useEffect(() => {
    async function preInfoCall() {
      if (clinicsData.length > 0) {
        if (index !== "") {
          $("tr[data-row-key=" + clinicsData[index].id + "]").removeClass("ant-table-row-selected");
        }
        setTimeout(function () {
          setIndex(0);

          $("tr[data-row-key=" + clinicsData[0].id + "]").addClass("ant-table-row-selected");
        }, 100);
      } else {
        setIndex("");
      }
    }

    preInfoCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicsData]);

  const columnsClinics = [
    {
      title: `SS ID`,
      dataIndex: "ssId",
      key: "ssId",
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (ssId) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={ssId}>
          {ssId}
        </Tooltip>
      ),
    },
    {
      title: "Clinic",
      dataIndex: "clinicName",
      key: "clinicName",
      width: 350,
    },
    {
      title: `Address`,
      dataIndex: "address",
      key: "address",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 120,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 80,
    },
    {
      title: "Tel",
      dataIndex: "tel",
      key: "tel",
      width: 120,
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      width: 120,
    },

    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: (row) => {
        return (
          <Space size="middle" className="actions">
            <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-edit"></Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const changeIndex = (record, edx) => {
    $("tr[data-row-key=" + clinicsData[index].id + "]").removeClass("ant-table-row-selected");
    $("tr[data-row-key=" + clinicsData[edx].id + "]").addClass("ant-table-row-selected");
    setIndex(edx);
  };

  document.onkeydown = function (evt) {
    console.log(evt);
    evt = evt || window.event;

    if (index !== "") {
      if (evt.keyCode === 40) {
        if (index < clinicsData.length - 1) {
          $("tr[data-row-key=" + clinicsData[index].id + "]").removeClass("ant-table-row-selected");
          $("tr[data-row-key=" + clinicsData[index + 1].id + "]").addClass("ant-table-row-selected");
          setIndex(index + 1);
        }
      }
      if (evt.keyCode === 38) {
        if (index > 0) {
          $("tr[data-row-key=" + clinicsData[index].id + "]").removeClass("ant-table-row-selected");
          $("tr[data-row-key=" + clinicsData[index - 1].id + "]").addClass("ant-table-row-selected");
          setIndex(index - 1);
        }
      }
      if (evt.ctrlKey && evt.keyCode === 69) {
        if (index !== "") {
          alert("Edit Api not defined");
        }
      }
      if (evt.ctrlKey && evt.keyCode === 68) {
        if (index !== "") {
          alert("Delete Api not defined");
        }
      }
      if (evt.ctrlKey && evt.keyCode === 65) {
        if (index !== "") {
          alert("Active/InActive Api not defined");
        }
      }
    }
  };

  const handleOk = async (e) => {
    setPrescriberClinicSearch(false);
  };

  const handleCancel = async (e) => {
    setPrescriberClinicSearch(false);
  };

  return (
    <>
      <Modal
        width={1200}
        className="modal-clinics"
        title="Clinic(s) Info"
        visible={isPrescriberClinicSearch}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <Table
          // className="table-length length-five"
          columns={columnsClinics}
          dataSource={clinicsData}
          pagination={false}
          scroll={{ y: 140 }}
          rowKey={(record) => record.uid}
          className={(record) => record.id}
          onRow={(record, index) => ({
            onClick: () => changeIndex(record, index),
          })}
        />
        {/* <ClinicSearchTable /> */}
      </Modal>
    </>
  );
}
export default PrescriberClinicSearchModal;
