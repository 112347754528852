import { Checkbox, Button, Input, message, Popconfirm, Select, Table, Tooltip, Modal, AutoComplete, Divider, InputNumber } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { percentFormatter, percentParser } from "../../../../utils/methods/universal";
import { groupNdcState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";

export default function GroupNdc() {
  const [options, setOptions] = useState([]);
  const [isGroupNdc, setGroupNdc] = useRecoilState(groupNdcState);

  const groupNdcData = [];

  const columnsGroupNdc = [
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      width: 100,
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      width: 100,
    },
    {
      title: `Name`,
      dataIndex: "name",
      key: "name",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: `Strength`,
      dataIndex: "strength",
      key: "strength",
      width: 100,
    },
    {
      title: `Form`,
      dataIndex: "form",
      key: "form",
      width: 100,
    },

    {
      title: `Pkg. Size`,
      dataIndex: "personCode",
      key: "personCode",
      width: 100,
    },

    {
      title: `Qty.`,
      dataIndex: "qty",
      key: "qty",
      width: 100,
    },
  ];

  const handleOk = async (e) => {
    setGroupNdc(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setGroupNdc(false);
    message.error("Data not saved!");
  };

  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const { Option } = Select;

  const handleSearch = (value) => {
    setOptions(value ? searchGroupNdc(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const searchGroupNdc = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const groupNdcCode = `${name}${idx}`;
        return {
          value: groupNdcCode,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {groupNdcCode}
              </span>
            </div>
          ),
        };
      });
  };

  return (
    <>
      {/* <Button className="btn float-right" icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />} onClick={() => setGroupNdc(true)}>
        Eligibility Check
      </Button> */}
      <Modal
        width={1280}
        title="Group NDCs"
        visible={isGroupNdc}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section>
          <div className="row">
            <div className="col-lg-9">
              <section className="bg-secondary">
                <div className="input-group input-xl">
                  <AutoComplete
                    autoFocus
                    dropdownClassName="search-result"
                    options={options}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    notFoundContent="No Record Found"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: ".5rem 0" }} />
                        <div className="btn-wrap">
                          <Link className="btn">
                            <i className="mdi mdi-plus-circle" /> <span>Add Drug Group</span>
                          </Link>
                        </div>
                      </>
                    )}>
                    <Input.Search className="formControl" placeholder="Search" name="dgSearch" />
                  </AutoComplete>
                  <label className="formLabel">Group NDC Code</label>
                </div>
                <div className="input-group input-xl">
                  <Input className="formControl" placeholder="Group NDC Name" name="dgName" />
                  <label className="formLabel">Group NDC Name</label>
                </div>
                <div className="input-group input-stg">
                  <Input className="formControl" placeholder="Strength" />
                  <label className="formLabel">Strength</label>
                </div>
                <Tooltip title="Link">
                  <Button className="btn btn-icon btn-link btn-sm ml-3 ">
                    <i className="mdi mdi-link"></i>
                  </Button>
                </Tooltip>
                <Table
                  className="table-length length-five"
                  size="small"
                  columns={columnsGroupNdc}
                  dataSource={groupNdcData}
                  pagination={false}
                  scroll={{ y: 150 }}
                  rowKey={(record) => record.uid}
                />
              </section>
            </div>
            <div className="col-lg-3">
              <section>
                <div className="input-group input-md">
                  <InputNumber precision={2} className="formControl" placeholder="0.00" />
                  <label className="formLabel">Min. Stock Qty.</label>
                </div>
                <div className="input-group input-md">
                  <InputNumber
                    className="formControl"
                    placeholder="Group Surcharge"
                    min={0}
                    max={100}
                    formatter={(value) => percentFormatter(value)}
                    parser={(value) => percentParser(value)}
                  />
                  <label className="formLabel">Surcharge</label>
                </div>
                <div className="input-group input-md">
                  <InputNumber precision={2} className="formControl" placeholder="0.00" />
                  <label className="formLabel">Min. Stock Qty.</label>
                </div>
                <div className="d-inline-block w-100 my-3">
                  <div className="input-group input-group-check float-right">
                    <Checkbox style={{ color: "green", fontSize: "1.25rem" }}>Auto Fill</Checkbox>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
