import React from 'react';
import HeaderNav from './headerNav';

const PharmacyHeader = () => (
  <>
    <HeaderNav />
  </>
);

export default PharmacyHeader;
