import React, { useState } from "react";
import { Modal, Button, Tooltip, Select } from "antd";
import { useRecoilState } from "recoil";
import { eRxViewDetailState } from "../../../../../utils/constant/notification/atom";

export default function ERxViewDetail() {
  const [current, setCurrent] = React.useState(0);
  const [isERxViewDetail, setERxViewDetail] = useRecoilState(eRxViewDetailState);

  const ERxViewDetailModal = () => {
    setERxViewDetail(true);
  };

  const handleOk = async (e) => {
    setERxViewDetail(false);
  };

  const handleCancel = async (e) => {
    setERxViewDetail(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        style={{ top: 20 }}
        width={1000}
        className="modal-view-detail"
        title="eRx View Detail"
        visible={isERxViewDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar action-btns">
            <div className="float-right">
              <Button className="btn btn-print">Print Rx</Button>
              <Button className="btn btn-print">Print Rx + Ins. Info.</Button>
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="m-0 px-0">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <section className="info width-98">
                  <div className="section-title">
                    <span className="title-text">Patient Info.</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 justify-content-between">
                      <div className="info-block">
                        <label>Patient ID</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block">
                        <label>DOB</label>
                        <span>12-01-1978</span>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Patient Name</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-98" style={{ height: "50px" }}>
                        <label>Observation</label>
                        <span>
                          Body Height: 59 in <br></br>Body Weight: 120 lb
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block">
                        <label>Gender</label>
                        <span>M</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>Patient Address</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-30">
                        <label>Suite</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>City</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-10">
                        <label>State</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-20">
                        <label>Zip</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div className="info-block">
                        <label>P/Cell</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Beeper</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Fax</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Home</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Work</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Other</label>
                        <span>(707) 521-4577</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="info width-98">
                  <div className="section-title">
                    <span className="title-text">Insurance Information</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-flex">
                      <div className="info-block width-33">
                        <label>Bin #</label>
                        <span>A1231qw3</span>
                      </div>
                      <div className="info-block width-33">
                        <label>Group</label>
                        <span>A1231qw3</span>
                      </div>
                      <div className="info-block width-33">
                        <label>PCN</label>
                        <span>A1231qw3</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Plan/Insurance</label>
                        <span>A1231qw3</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>PBM #</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-30">
                        <label>Relationship</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 my-3 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>Member ID</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-50">
                        <label>CH Last Name</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-50">
                        <label>CH First Name</label>
                        <span>NA</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="info width-98 mt-4">
                  <div className="section-title">
                    <span className="title-text">Message</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-flex">
                      <div className="info-block width-98" style={{ height: "50px" }}>
                        <span>
                          Body Height: 59 in <br></br>Body Weight: 120 lb
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-6 mx-auto">
                <section className="info width-98">
                  <div className="section-title">
                    <span className="title-text">Allergies</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <span>NA</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="info">
                  <div className="section-title">
                    <span className="title-text">Drug Information</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Drug</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 justify-content-between">
                      <div className="info-block">
                        <label>Strength</label>
                        <span>NA</span>
                      </div>
                      <div>
                        <div className="info-block">
                          <label>UOM</label>
                          <span>Tablet</span>
                        </div>
                        <div className="info-block">
                          <label>OQ</label>
                          <span>4</span>
                        </div>
                        <div className="info-block">
                          <label>DS</label>
                          <span>90</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>SIG</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Route</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Note</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 justify-content-between">
                      <div className="info-block">
                        <label>NDC</label>
                        <span>000000652178</span>
                      </div>
                      <div className="info-block">
                        <label>Form</label>
                        <span>Tablet</span>
                      </div>
                      <div className="info-block">
                        <label>Sub</label>
                        <span>4</span>
                      </div>
                      <div className="info-block">
                        <label>Refills</label>
                        <span>90</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="info-block">
                        <label>Eff. Date</label>
                        <span>06-07-2019</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="info width-98">
                  <div className="section-title">
                    <span className="title-text">Compound Information</span>
                  </div>
                  <div className="info-block width-98" style={{ height: "50px" }}>
                    <span>
                      Body Height: 59 in <br></br>Body Weight: 120 lb
                    </span>
                  </div>
                </section>
                <section className="info width-98">
                  <div className="section-title">
                    <span className="title-text">Prescriber Info.</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block w-75">
                        <label>Prescriber Name</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block w-25">
                        <label>Suffix</label>
                        <span>12-01-1978</span>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="info-block width-98">
                        <label>Clinic Name</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-end">
                      <div className="info-block">
                        <label>Tel</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block">
                        <label>Fax</label>
                        <span>(707) 521-4577</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>Clinic Address</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-30">
                        <label>Suite</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block width-70">
                        <label>City</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-10">
                        <label>State</label>
                        <span>NA</span>
                      </div>
                      <div className="info-block width-20">
                        <label>Zip</label>
                        <span>NA</span>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3 d-flex justify-content-between">
                      <div className="info-block w-25">
                        <label>NPI</label>
                        <span>28888888889</span>
                      </div>
                      <div className="info-block w-25">
                        <label>DEA</label>
                        <span>BP1142114</span>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="info-block w-30">
                        <label>Message ID</label>
                        <span>(707) 521-4577</span>
                      </div>
                      <div className="info-block w-70">
                        <label>License</label>
                        <span>CV54124125132</span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
