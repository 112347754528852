import React, { useState } from "react";
import { DatePicker, Input, Radio } from "antd";
import PatientAllergiesClassName from "./patientAllergiesClassName";
import PatientAllergiesExceptions from "./patientAllergiesExceptions";

export default function PatientAllergies() {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-6">
          <Radio.Group className="ml-3 my-3" defaultValue={1} autoFouc>
            <Radio value={1}>Not Selected</Radio>
            <Radio value={2}>NKDA</Radio>
            <Radio value={3}>Allergies</Radio>
          </Radio.Group>
          {/* <Radio.Group> */}
          {/* <Radio className="m-0 p-0" name="patientAllergiesCheck" value="1" >
                  NKDA
                </Radio>
                <Radio className="px-2 py-1" name="patientAllergiesCheck" value="2">
                  Allergies
                </Radio> */}

          {/* </Radio.Group> */}
        </div>
        <div className="col-lg-6">
          <div className="d-flex justify-content-end">
            <div className="input-group input-date with-addon m-0">
              <DatePicker className="formControl" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" disabled />
              <label className="formLabel">Checked on</label>
            </div>
            <div className="input-group input-md m-0">
              <Input className="formControl" placeholder="User" disabled />
              <label className="formLabel">User</label>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <PatientAllergiesClassName />
          <PatientAllergiesExceptions />
        </div>
      </div>
    </>
  );
}
