import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { privateMenus, adminMenus } from "../../../utils/menu/route";
// import LogoRysaRx from "Assets/img/logo-rysa-rx-white.png";
import RolesDialog from "../../../pages/Admin/employeeRoles";

const type = localStorage.getItem("type");

function SidebarNav() {
  const [collapsed, setCollapsed] = useState(true);
  const [isRolesDialog, setRolesDialog] = useState(false);

  const sidebarToggle = (name) => {
    if (name === "employee-roll-search") {
      setCollapsed(!collapsed);
      setRolesDialog(true);
    } else {
      setCollapsed(!collapsed);
      setRolesDialog(false);
    }
  };
  const closeSidebarToggle = () => {
    setRolesDialog(false);
  };

  let routes = [];
  if (type === "PRIVATE") {
    routes = privateMenus.filter((item) => item.insidebar === true);
  } else if (type === "ADMIN") {
    routes = adminMenus.filter((item) => item.insidebar === true);
  }

  return (
    <>
      <RolesDialog isRolesDialog={isRolesDialog} closeSidebarToggle={closeSidebarToggle} />
      <div className="sidebar-wrapper">
        <div className={collapsed ? "" : "overlay"} onClick={() => sidebarToggle("")}></div>
        <ProSidebar trigger={null} collapsed={collapsed}>
          <button onClick={() => sidebarToggle("")} className="sidebar-toggler">
            {collapsed ? <i className="mdi mdi-menu"></i> : <i className="mdi mdi-close"></i>}
          </button>
          {/* <div className="brand-logo float-left w-100">
              <img src={LogoRysaRx} alt="Logo" />
            </div> */}
          <Menu iconShape="square" popperArrow="true">
            {routes.map((menu, index) => {
              if (menu.child.length === 0) {
                if (menu.isPopup === true) {
                  return (
                    <MenuItem key={index} icon={menu.icon}>
                      <NavLink onClick={() => sidebarToggle(menu.name)} to={"#"}>
                        {menu.title}
                      </NavLink>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} icon={menu.icon}>
                      <NavLink onClick={() => sidebarToggle(menu.name)} to={menu.path}>
                        {menu.title}
                      </NavLink>
                    </MenuItem>
                  );
                }
              } else {
                return (
                  <SubMenu title={menu.title} icon={menu.icon}>
                    {menu.child.map((item, i) => {
                      return (
                        <MenuItem key={i + 100} icon={item.icon}>
                          <NavLink onClick={() => sidebarToggle(menu.name)} to={item.path}>
                            {item.title}
                          </NavLink>
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </Menu>
        </ProSidebar>
      </div>
    </>
  );
}

export default SidebarNav;
