import React from "react";

const VendorSearch = React.lazy(() => import("Pages/Private/Vendor/vendorSearch"));
const DrugGroupSearch = React.lazy(() => import("Pages/Private/Drug/drugGroupSearch"));
const PrescriberClinicSearch = React.lazy(() => import("Pages/Private/Prescriber/prescriberClinicSearch"));
const InsGroupSearch = React.lazy(() => import("Pages/Private/Insurance/InsuranceGroup/insGroupSearch"));
const InsPcnSearch = React.lazy(() => import("Pages/Private/Insurance/InsurancePcn/insPcnSearch"));
const InsBinSearch = React.lazy(() => import("Pages/Private/Insurance/InsuranceBin/insBinSearch"));
// IMPORT ADMIN PAGES
const CompanySearch = React.lazy(() => import("Pages/Admin/company/companySearch"));
const StoreSearch = React.lazy(() => import("Pages/Admin/store/storeSearch"));
const EmployeeSearch = React.lazy(() => import("Pages/Admin/employee/employeeSearch"));
const EmployeeRollSearch = React.lazy(() => import("Pages/Admin/employeeRoles"));
const CompanyDetail = React.lazy(() => import("Pages/Admin/company/companyDetail"));
const StoreDetail = React.lazy(() => import("Pages/Admin/store/storeDetail"));
const EmployeeDetail = React.lazy(() => import("Pages/Admin/employee/employeeDetail"));

const Order = React.lazy(() => import("Pages/Private/Order"));
const ProcessingDetail = React.lazy(() => import("Pages/Private/Processing/detail"));
const ProcessingSearch = React.lazy(() => import("Pages/Private/Processing"));
const PatientSearch = React.lazy(() => import("Pages/Private/Patient/patientSearch"));
const PrescriberSearch = React.lazy(() => import("Pages/Private/Prescriber/prescriberSearch"));
const DrugSearch = React.lazy(() => import("Pages/Private/Product/drugSearch"));
const OctSearch = React.lazy(() => import("Pages/Private/Product/octSearch"));
const Binman = React.lazy(() => import("Pages/Private/Binman"));
const Wimo = React.lazy(() => import("Pages/Private/Wimo"));

// import Order from "Pages/Private/Order";
export const adminMenus = [
  // PRIVATE PAGES ROUTES

  // ADMIN PAGES ROUTES
  {
    path: "/admin/companySearch",
    title: "Company",
    insidebar: true,
    icon: <i className="mdi mdi-office-building" />,
    component: CompanySearch,
    name: "company-search",
    isPopup: false,
    child: [],
  },
  {
    path: "/admin/storeSearch",
    title: "Store",
    insidebar: true,
    icon: <i className="mdi mdi-store" />,
    component: StoreSearch,
    isPopup: false,
    name: "store-search",
    child: [],
  },
  {
    path: "/admin/rollSearch",
    // onClick: setRolesDialog(true),
    title: "Roles",
    insidebar: true,
    icon: <i className="mdi mdi-checkbox-multiple-marked-outline" />,
    component: "#",
    name: "employee-roll-search",
    isPopup: true,
    child: [],
  },
  {
    path: "/admin/employeeSearch",
    title: "Employee",
    insidebar: true,
    icon: <i className="mdi mdi-account-multiple-plus" />,
    component: EmployeeSearch,
    name: "employee-search",
    isPopup: false,
    child: [],
  },
  {
    path: "/admin/companyDetail",
    insidebar: false,
    icon: <i className="mdi mdi-office-building" />,
    component: CompanyDetail,
    name: "company-detail",
    isPopup: false,
    child: [],
  },

  {
    path: "/admin/storeDetail",
    insidebar: false,
    icon: <i className="mdi mdi-store" />,
    component: StoreDetail,
    name: "store-detail",
    isPopup: false,
    child: [],
  },
  {
    path: "/admin/employeeDetail",
    insidebar: false,
    icon: <i className="mdi mdi-account-multiple-plus" />,
    component: EmployeeDetail,
    name: "employee-detail",
    isPopup: false,
    child: [],
  },
];

export const privateMenus = [
  // PRIVATE PAGES ROUTES
  {
    title: "Clinic",
    icon: <i className="mdi mdi-needle" />,
    insidebar: true,
    component: PrescriberClinicSearch,
    path: "/private/prescriberClinicSearch",
    name: "prescriber-clinic-search",
    isPopup: false,
    child: [],
  },
  {
    title: "Order",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: Order,
    path: "/private/order",
    name: "private-order",
    isPopup: false,
    child: [],
  },
  {
    title: "Processing Detail",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: ProcessingDetail,
    path: "/private/processing-detail",
    name: "private-processing-detail",
    isPopup: false,
    child: [],
  },
  {
    title: "Processing Search",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: ProcessingSearch,
    path: "/private/processing",
    name: "private-processing-search",
    isPopup: false,
    child: [],
  },
  {
    title: "Patient",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: PatientSearch,
    path: "/private/patient",
    name: "private-patient",
    isPopup: false,
    child: [],
  },
  {
    title: "Prescriber",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: PrescriberSearch,
    path: "/private/prescriber",
    name: "private-prescriber",
    isPopup: false,
    child: [],
  },
  {
    title: "Drug",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: DrugSearch,
    path: "/private/product/drugSearch",
    name: "private-drugSearch",
    isPopup: false,
    child: [],
  },
  {
    title: "OctSearch",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: OctSearch,
    path: "/private/product/octSearch",
    name: "private-octSearch",
    isPopup: false,
    child: [],
  },
  {
    title: "Binman",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: Binman,
    path: "/private/binman",
    name: "private-binman",
    isPopup: false,
    child: [],
  },
  {
    title: "Wimo",
    icon: <i className="mdi mdi-needle" />,
    insidebar: false,
    component: Wimo,
    path: "/private/wimo",
    name: "private-wimo",
    isPopup: false,
    child: [],
  },

  {
    title: "Vendor",
    icon: <i className="mdi mdi-vimeo" />,
    insidebar: true,
    component: VendorSearch,
    path: "/private/vendorSearch",
    name: "prescriber-clinic-search",
    isPopup: false,
    child: [],
  },
  {
    title: "Insurance",
    path: "/private/Insurance",
    name: "ins-group-search",
    icon: <i className="mdi mdi-marker-check" />,
    insidebar: true,
    isPopup: false,
    child: [
      {
        title: "Insurance Group",
        path: "/private/Insurance/insGroupSearch",

        insidebar: true,
        component: InsGroupSearch,
        name: "ins-group-search",
        isPopup: false,
        child: [],
      },
      {
        path: "/private/Insurance/insPcnSearch",
        title: "Insurance PCN",
        insidebar: true,
        component: InsPcnSearch,
        name: "ins-pcn-search",
        isPopup: false,
        child: [],
      },
      {
        path: "/private/Insurance/insBinSearch",
        title: "Insurance BIN",
        insidebar: true,
        component: InsBinSearch,
        name: "ins-bin-search",
        isPopup: false,
        child: [],
      },
      {
        path: "/private/Insurance/rejection/code",
        title: "Insurance Rejection Codes",
        insidebar: true,
        component: InsBinSearch,
        name: "ins-rejection-code",
        isPopup: false,
        child: [],
      },
    ],
  },
];
