import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag, Button, Modal, DatePicker, Input, Checkbox } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
import { billLaterState } from "../../../../utils/constant/notification/atom";
import { billingDetailState } from "../../../../utils/constant/processing/atom";
import BillingDetail from "../../../../pages/Private/Processing/components/billingDetail";
import SectionLoader from "../../loader/section";
function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function BillLater() {
  const [isBillingDetail, setBillingDetail] = useRecoilState(billingDetailState);
  const [isbillLater, setbillLater] = useRecoilState(billLaterState);
  const billLaterModal = () => {
    setbillLater(true);
  };

  const handleOk = async (e) => {
    setbillLater(false);
  };

  const handleCancel = async (e) => {
    setbillLater(false);
  };

  const columns = [
    {
      title: (
        <>
          <Tooltip title="billLater">D.</Tooltip> Date
        </>
      ),
      dataIndex: "dDate",
      key: "dDate",
      width: 90,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: (
        <>
          <Tooltip title="billLater">D.</Tooltip> Reason
        </>
      ),
      dataIndex: "dReason",
      key: "dReason",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (dReason) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={dReason}>
          {dReason}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          <Tooltip title="billLater">D.</Tooltip> Note
        </>
      ),
      dataIndex: "dNote",
      key: "dNote",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (messageType) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={messageType}>
          {messageType}
        </Tooltip>
      ),
    },
    {
      title: "Fill Date",
      dataIndex: "fillDate",
      key: "fillDates",
      width: 90,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: <Tooltip title="Store Number">S. #</Tooltip>,
      dataIndex: "storeNumber",
      key: "storeNumber",
      width: 40,
      align: "center",
    },
    {
      title: (
        <>
          <i className="icon rx-number"></i>
        </>
      ),
      dataIndex: "rxNumber",
      key: "rxNumber",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Refills" placement="top" mouseLeaveDelay="0">
          R.
        </Tooltip>
      ),
      dataIndex: "refillNumber",
      key: "refillNumber",
      width: 40,
      align: "center",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (patientName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
          {patientName}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date",
      key: "date",
      width: 90,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
      align: "center",
    },
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "Strength",
      dataIndex: "strength",
      key: "strength",
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      render: (strength) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
          {strength}
        </Tooltip>
      ),
    },
    {
      title: "Sig",
      dataIndex: "sig",
      key: "sig",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (sig) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={sig}>
          {sig}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip title="Order Quantity" placement="top" mouseLeaveDelay="0">
          OQ
        </Tooltip>
      ),
      dataIndex: "orderQty",
      key: "orderQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Dispense Quantity" placement="top" mouseLeaveDelay="0">
          DQ
        </Tooltip>
      ),
      dataIndex: "dispenseQty",
      key: "dispenseQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
          DS
        </Tooltip>
      ),
      dataIndex: "daySupply",
      key: "daySupply",
      width: 50,
      align: "center",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      width: 90,
      className: "col-tag-toggle",
      render: (row) => {
        if (row === true) {
          return <Tag icon={<i>Cash</i>} className="col-tag" onClick={() => setBillingDetail(true)}></Tag>;
        } else {
          return <Tag icon={<span>Insurance</span>} className="col-tag" onClick={() => setBillingDetail(true)}></Tag>;
        }
      },
    },
    {
      title: "DNS",
      dataIndex: "dns",
      key: "dns",
      width: 50,
      align: "center",
      render: (row) => {
        if (row === true) {
          return <Tag icon={<i className="mdi mdi-check"></i>} color="success"></Tag>;
        } else {
          return <Tag icon={<i className="mdi mdi-close"></i>} color="error"></Tag>;
        }
      },
    },
    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },
    {
      title: `CoPay`,
      dataIndex: "copay",
      key: "copay",
      width: 70,
      className: "col-amount",
      align: "center",
    },
    {
      title: `P. Paid`,
      dataIndex: "pPaid",
      key: "pPaid",
      width: 70,
      className: "col-amount",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      storeNumber: `123`,
      rxNumber: `1234567`,
      refillNumber: `0${i}`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90`,
      authRefill: `120`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      binNumber: `123 1234567 0${i}`,
      sig: `Take 2 tablets 3 times a day.`,
      note: `Testing note for long text`,
      copay: `157.${i}`,
    });
  }

  return (
    <Modal
      width={1800}
      style={{ top: 25 }}
      className="modal-billLater"
      title="Bill Later"
      visible={isbillLater}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      // keyboard={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <BillingDetail />
        </div>
      </Suspense>
      <Table
        rowSelection
        className="table-search-main mt-0"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 690 }}
      />
    </Modal>
  );
}
