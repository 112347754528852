const { atom } = require("recoil");

export const isLogedIn = atom({
  key: "isLogedIn",
  default: false,
});
export const isLogedType = atom({
  key: "isLogedType",
  default: "",
});
export const isUrl = atom({
  key: "isUrl",
  default: "",
});

export const siteTitle = atom({
  key: "siteTitle",
  default: "DashBoard",
});

// NOTES DRAWER
export const notesDrawerState = atom({
  key: "notesDrawerState",
  default: false,
});
export const notesDrawerTitle = atom({
  key: "notesDrawerTitle",
  default: "",
});

// REASONS DIALOG
export const reasonNoteState = atom({
  key: "setBlockedState",
  default: "false",
});
