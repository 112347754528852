import React from "react";
import { Button, Drawer } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRecoilState } from "recoil";
import { notesDrawerState, notesDrawerTitle } from "../../../utils/constant/atom";

export default function AddNotes() {
  const [isNotes, setNotes] = useRecoilState(notesDrawerState);
  const [title, setNoteTitle] = useRecoilState(notesDrawerTitle);

  const openNotes = () => {
    setNotes(true);
  };

  const closeNotes = () => {
    setNotes(false);
    setNoteTitle("");
  };

  return (
    <>
      <Drawer
        className="notes-drawer"
        width="600"
        title=""
        placement="right"
        closable={false}
        onClose={closeNotes}
        visible={isNotes}
        getContainer={true}>
        <div className="notes-title">
          <span className="title-text">{title}</span>
        </div>
        <div className="input-group-notes">
          <div className="row row-eq-height align-items-center mb-3">
            <div className="col-9">
              <TextArea className="input-notes" placeholder="Add Notes" rows={4} autoFocus />
            </div>
            <div className="col-2">
              <Button type="button" className="btn btn-add m-0" onClick={closeNotes}>
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="notes-history">
          <div className="card unseen-note p-2 w-100">
            <p className="notes-message mb-0">This is unseen note!</p>
            <div className="notes-info">
              <small className="float-left w-auto text-left">Username</small>
              <small className="float-right w-auto text-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
          <div className="card p-2 w-100">
            <p className="notes-message mb-0">This is seen note!</p>
            <div className="notes-info">
              <small className="float-left">Username</small>
              <small className="float-right">10/10/2020</small>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
