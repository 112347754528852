import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, InputNumber, Space, Table, Divider } from "antd";
import { useRecoilState } from "recoil";
import SectionLoader from "../../../../components/common/loader/section";
import ERxReceived from "../eRx/components/eRx-received";
import ERxMessage from "./components/eRx-message";
import { eRxWizardState } from "../../../../utils/constant/notification/atom";
import ERxViewDetail from "./components/eRx-view-detail";
import ERxLog from "./components/eRx-log";

export default function ERxWizard() {
  const [isERxWizard, setERxWizard] = useRecoilState(eRxWizardState);

  const handleOk = async (e) => {
    setERxWizard(false);
  };

  const handleCancel = async (e) => {
    setERxWizard(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setERxWizard(current);
  };

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <ERxViewDetail />
          <ERxLog />
        </div>
      </Suspense>
      <Modal
        width={1800}
        style={{ top: 25 }}
        className="modal-erx-wizard"
        title={
          <>
            <span>e</span>
            <i className="mdi mdi-prescription"></i>
            <span>Wizard</span>
          </>
        }
        visible={isERxWizard}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <div className="d-none">
            <Suspense fallback={<SectionLoader />}></Suspense>
          </div>

          <ERxReceived />
          <ERxMessage />
        </>
      </Modal>
    </>
  );
}
