import { Button, message, Popconfirm, Modal, DatePicker, InputNumber } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { amountFormatter, amountParser, percentFormatter, percentParser } from "../../../../utils/methods/validation";
import { saleBogoState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";

export default function SaleBogo() {
  const { RangePicker } = DatePicker;
  const [isSaleBogo, setSaleBogo] = useRecoilState(saleBogoState);

  const handleOk = async (e) => {
    setSaleBogo(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setSaleBogo(false);
    message.error("Data not saved!");
  };

  return (
    <>
      {/* <Button className="btn float-right" icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />} onClick={() => setSaleBogo(true)}>
        Eligibility Check
      </Button> */}
      <Modal
        width={500}
        title="Bogo Sale"
        visible={isSaleBogo}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section>
          <div className="row">
            <div className="col-12">
              <div className="input-group input-daterange float-right">
                <RangePicker />
              </div>
            </div>
            <div className="col-12 my-1">
              <label className="extend-label info float-left">Qty. Limit</label>
              <div className="input-group input-pkg float-right">
                <InputNumber precision={2} className="formControl" placeholder="0.00" />
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
