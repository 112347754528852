import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag, Button, Modal, DatePicker, Input, Checkbox, Switch } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
import { eRxLogState } from "../../../../../utils/constant/notification/atom";
import { SearchOutlined } from "@ant-design/icons";
import SearchPatient from "../../../searchPatient";
import SearchProduct from "../../../searchProduct";
import SearchPrescriber from "../../../searchPrescriber";
function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function ERxLog() {
  const [isERxLog, setERxLog] = useRecoilState(eRxLogState);
  const eRxLogModal = () => {
    setERxLog(true);
  };

  const handleOk = async (e) => {
    setERxLog(false);
  };

  const handleCancel = async (e) => {
    setERxLog(false);
  };

  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "Date/Time",
      dataIndex: "dateTime",
      key: "dateTime",
      width: 120,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Message Type",
      dataIndex: "messageType",
      key: "messageType",
      ellipsis: {
        showTitle: false,
      },
      render: (messageType) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={messageType}>
          {messageType}
        </Tooltip>
      ),
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      ellipsis: {
        showTitle: false,
      },
      render: (patientName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
          {patientName}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
      align: "center",
    },
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "Strength",
      dataIndex: "strength",
      key: "strength",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (strength) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
          {strength}
        </Tooltip>
      ),
    },
    {
      title: "Sig",
      dataIndex: "sig",
      key: "sig",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (sig) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={sig}>
          {sig}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip title="Order Quantity" placement="top" mouseLeaveDelay="0">
          OQ
        </Tooltip>
      ),
      dataIndex: "orderQty",
      key: "orderQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Dispense Quantity" placement="top" mouseLeaveDelay="0">
          DQ
        </Tooltip>
      ),
      dataIndex: "dispenseQty",
      key: "dispenseQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
          DS
        </Tooltip>
      ),
      dataIndex: "daySupply",
      key: "daySupply",
      width: 50,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Refill" placement="top" mouseLeaveDelay="0">
          R.
        </Tooltip>
      ),
      dataIndex: "refills",
      key: "refills",
      width: 70,
      align: "center",
    },
    {
      title: "Rx Status",
      dataIndex: "rxStatus",
      key: "rxStatus",
      width: 80,
      align: "center",
      render: (row) => {
        if (row === true) {
          return <Tag icon={<i className="mdi mdi-check"></i>} color="success"></Tag>;
        } else {
          return <Tag icon={<i className="mdi mdi-close"></i>} color="error"></Tag>;
        }
      },
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      width: 100,
      align: "center",
    },
    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },
    {
      title: "NPI",
      dataIndex: "npi",
      key: "npi",
      width: 100,
      align: "center",
    },
    {
      title: "DEA/NADEA",
      dataIndex: "dea",
      key: "npi",
      width: 100,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 60,
      render: () => (
        <Switch
          size="small"
          className="bg-green"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close" />}
          defaultChecked
        />
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      storeNumber: `123`,
      rxNumber: `1234567`,
      refillNumber: `0${i}`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90`,
      authRefill: `120`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      binNumber: `123 1234567 0${i}`,
      sig: `Take 2 tablets 3 times a day.`,
      note: `Testing note for long text`,
      copay: `157.${i}`,
    });
  }

  return (
    <Modal
      width={1800}
      style={{ top: 25 }}
      className="modal-erx-log"
      title="eRx Log"
      visible={isERxLog}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      // keyboard={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <section className="search-form">
        <form className="d-flex align-items-center justify-content-start">
          <div className="input-group" style={{ width: "550px" }}>
            <div className="input-group-prepend" style={{ width: "450px" }}>
              <Checkbox>Today</Checkbox>
              <Checkbox>Last 7 Days</Checkbox>
            </div>
            <RangePicker />
          </div>
          <hr axis="y" className="input-divider"></hr>
          <div className="d-flex">
            <div className="input-group input-search">
              <label className="formLabel">Patient</label>
              <SearchPatient />
            </div>
            <div className="input-group input-date with-addon">
              <Tooltip title="Date of Birth">
                <label className="formLabel">DOB</label>
              </Tooltip>
              <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" name="dateOfBirth" />
            </div>

            <hr axis="y" className="input-divider"></hr>
            <div className="input-group input-search">
              <label className="formLabel">Drug</label>
              <SearchProduct />
            </div>
            <div className="input-group input-search">
              <label className="formLabel">Prescriber</label>
              <SearchPrescriber />
            </div>
          </div>
          <Tooltip title="Search" mouseLeaveDelay={0}>
            <Button className="btn btn-lg btn-search" icon={<SearchOutlined />} />
          </Tooltip>
          <Button className="btn btn-clear-all">Clear All</Button>
        </form>
      </section>
      <Table className="table-search-main" size="small" columns={columns} dataSource={data} pagination={false} scroll={{ y: 650 }} />
    </Modal>
  );
}
