import { message, Popconfirm, Space, Switch, Table, Tooltip } from "antd";
import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import SectionLoader from "../../../../components/common/loader/section";
import { prescriberClinicDetailState } from "../../../../utils/constant/prescriber/atom";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function LinkClinicTable() {
  const [isPrescriberClinicDetail, setPrescriberClinicDetail] = useRecoilState(prescriberClinicDetailState);

  const linkClinicData = [];
  for (let i = 0; i < 100; i++) {
    linkClinicData.push({
      key: i,
      clinicName: `RysaTech ${i}`,
      ssId: `831204602${i}`,
      tel: `831 204 532`,
      fax: `831 204 532`,
      dea: `123456789${i}`,
      npi: `123456789${i}`,
      licenseNo: `23123234${i}`,
      address: `Mayo Clinic, Kingcity, CA 9393${i}`,
    });
  }
  const columnsLinkClinic = [
    {
      title: `SS ID`,
      dataIndex: "ssId",
      key: "ssId",
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (ssId) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={ssId}>
          {ssId}
        </Tooltip>
      ),
    },
    {
      title: "Clinic",
      dataIndex: "clinicName",
      key: "clinicName",
      width: 350,
    },
    {
      title: `Address`,
      dataIndex: "address",
      key: "address",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 120,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 80,
    },
    {
      title: "Tel",
      dataIndex: "tel",
      key: "tel",
      width: 120,
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: "col-status",
      width: 60,
      render: () => (
        <Switch
          size="small"
          className="bg-green"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close" />}
          defaultChecked
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: (row) => {
        return (
          <Space size="middle" className="actions">
            <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-edit" onClick={() => setPrescriberClinicDetail(true)}></Link>
            </Tooltip>
            <Tooltip title="Preferred" mouseLeaveDelay={0} placement="bottom">
              <Link className={row.key === 0 ? "action-default setDefault" : "action-default"}></Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <Suspense fallback={<SectionLoader />}>
      <Table
        className="table-length length-five"
        columns={columnsLinkClinic}
        dataSource={linkClinicData}
        pagination={false}
        scroll={{ y: 140 }}
        rowKey={(record) => record.uid}
      />
    </Suspense>
  );
}
