import { Button, Input, message, Popconfirm, Select, Space, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export default function PatientAllergiesExceptions() {
  const patientAllergiesExceptionsData = [];

  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }
  const [state, setState] = useState({
    name: "",
    dob: new Date(),
    tel: "",
    relation: "",
    insID: "",
    address: 1,
  });
  const columnsPatientAllergiesException = [
    {
      title: `Drug Name`,
      dataIndex: "drugName",
      key: "drugName",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (drugName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={drugName}>
          {drugName}
        </Tooltip>
      ),
    },

    {
      title: `NDC`,
      dataIndex: "ndc",
      key: "ndc",
      width: 120,
    },
    {
      title: `Drug Class`,
      dataIndex: "drugClassName",
      key: "drugClassName",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (drugName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={drugName}>
          {drugName}
        </Tooltip>
      ),
    },
    {
      title: "Severity Level",
      dataIndex: "severityLevel",
      width: 100,
      render: (row) => {
        if (row === true) {
          return <Tag color="info">Mild</Tag>;
        }
        if (row === true) {
          return <Tag color="warning">Medium</Tag>;
        }
        if (row === true) {
          return <Tag color="danger">Severe</Tag>;
        }
      },
    },
    {
      title: `Notes`,
      dataIndex: "arNote",
      key: "arNote",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (arNote) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={arNote}>
          {arNote}
        </Tooltip>
      ),
    },
    {
      title: `User`,
      dataIndex: "user",
      key: "user",
      width: 80,
    },
    // {
    //   title: `DOB`,
    //   dataIndex: "dob",
    //   key: "dob",
    //   width: 100,
    //   render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    // },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Popconfirm title="Are you sure delete this task?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
            <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-delete"></Link>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // const columnsPatientAllergiesException = [
  //   {
  //     title: "Severity Level",
  //     dataIndex: "severityLevel",
  //     width: 120,
  //     render: (row) => {
  //       if (row === true) {
  //         return <Tag color="info">Mild</Tag>;
  //       }
  //       if (row === true) {
  //         return <Tag color="warning">Medium</Tag>;
  //       }
  //       if (row === true) {
  //         return <Tag color="danger">Severe</Tag>;
  //       }
  //     },
  //   },
  //   {
  //     title: `Allergies Reaction Note`,
  //     dataIndex: "arNote",
  //     key: "arNote",
  //     width: 230,
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //     render: (arNote) => (
  //       <Tooltip mouseLeaveDelay={0} placement="topLeft" title={arNote}>
  //         {arNote}
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: `User`,
  //     dataIndex: "user",
  //     key: "user",
  //     width: 120,
  //   },
  //   // {
  //   //   title: `DOB`,
  //   //   dataIndex: "dob",
  //   //   key: "dob",
  //   //   width: 100,
  //   //   render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
  //   // },
  //   {
  //     title: "Action",
  //     key: "action",
  //     width: 80,
  //     render: () => (
  //       <Space size="middle" className="actions">
  //         <Popconfirm title="Are you sure delete this task?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
  //           <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
  //             <Link className="action-delete"></Link>
  //           </Tooltip>
  //         </Popconfirm>
  //       </Space>
  //     ),
  //   },
  // ];

  const { Option } = Select;
  return (
    <section className="bg-primary">
      <div className="section-title">
        <span className="title-text">Allergies Exception(s)</span>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="input-group input-xl">
            <Select showSearch placeholder="Name / NDC / UPC">
              <Option value="pharmacist">A001</Option>
              <Option value="mother">A002</Option>
            </Select>
            <label className="formLabel">Name / NDC / UPC</label>
          </div>
          {/* <div className="input-group input-xl">
            <Select placeholder="Drug Class" disabled>
              <Option value="inlusin">Class 1...</Option>
              <Option value="name">Class text 2...</Option>
            </Select>
            <label className="formLabel">Drug Class</label>
          </div> */}
          <Button className="btn btn-icon btn-sm btn-add float-right">
            <span></span>
          </Button>
        </div>
        {/* <div className="col-lg-12">
          <div className="input-group  input-md">
            <Select placeholder="Severity Level">
              <Option value="select">Select One</Option>
              <Option value="mild">Mild</Option>
              <Option value="medium">Medium</Option>
              <Option value="severe">Severe</Option>
            </Select>
            <label className="formLabel">Class</label>
          </div>
          <div className="input-group w-auto" style={{ minWidth: "1050px" }}>
            <Input className="formControl" placeholder="Allergies Reaction Note" disabled />
            <label className="formLabel">Allergies Reaction Note</label>
          </div>
         
        </div> */}
      </div>
      <Table
        className="table-length length-three"
        size="small"
        columns={columnsPatientAllergiesException}
        dataSource={patientAllergiesExceptionsData}
        pagination={false}
        scroll={{ y: 90 }}
        rowKey={(record) => record.uid}
      />
    </section>
  );
}
