import React from "react";

function SectionLoader() {
  return (
    <div className='loader loader-section'>
      <div className='loader-inner'>
        <div className='loader-text'>
          <h2>LOADING</h2>
        </div>
      </div>
    </div>
  );
}
export default SectionLoader;
