import React from "react";
import { Modal, Button, Select, InputNumber, Tooltip, message } from "antd";
import { sendRreState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";

export default function SendRre() {
  const [isSendRre, setSendRre] = useRecoilState(sendRreState);

  const handleOk = async (e) => {
    setSendRre(false);
    message.success("Electronic Refill Request sent successfully!");
  };

  const handleCancel = async (e) => {
    setSendRre(false);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        width={300}
        className="modal-send-rre"
        title="E-Refill(s) Request"
        visible={isSendRre}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="m-0">
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span>Refill(s)</span>
                  </div>
                  <InputNumber className="formControl" placeholder="0" autoFocus />
                  <Button className="btn btn-send input-group-append" onClick={handleOk}>
                    <span></span> Send RRE
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
