const { atom } = require("recoil");

export const dvLogState = atom({
  key: "dvLogState",
  default: false,
});
export const rvLogState = atom({
  key: "rvLogState",
  default: false,
});

export const drugVerifyState = atom({
  key: "drugVerifyState",
  default: false,
});

export const rphVerifyState = atom({
  key: "rphVerifyState",
  default: false,
});
