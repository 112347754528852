import React from "react";
import { BrowserRouter } from "react-router-dom";
import PharmacyRoutes from "../../PharmacyRoutes";
import PharmacyFooter from "../../components/Layout/footer";
import PharmacyHeader from "../../components/Layout/header";
import PharmacySidebar from "../../components/Layout/sidebarNav";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
// import LogoRysaRx from '../../assets/img/logo-rysa-rx.png';
import { siteTitle } from "../../utils/constant/atom";

export default function AppLayout() {
  const title = useRecoilValue(siteTitle);
  return (
    <BrowserRouter>
      <PharmacySidebar />
      <div className="pharmacy-app">
        <header className="ml-auto">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <PharmacyHeader />
        </header>
        <main>
          <div className="main-inner-wrapper">
            <PharmacyRoutes />
          </div>
        </main>
        <footer>
          <PharmacyFooter />
        </footer>
      </div>
    </BrowserRouter>
  );
}
