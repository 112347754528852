// Company Registration
let domain = "http://ec2-3-143-219-143.us-east-2.compute.amazonaws.com:8081";
let serviceDomain = "http://ec2-3-143-219-143.us-east-2.compute.amazonaws.com:8082";
let permissionDomain = "http://ec2-3-143-219-143.us-east-2.compute.amazonaws.com:8083";

export const getCompanyRegistration = `${domain}/api/permissions/load-permissions`;

export const storeCompanyRegistration = `${domain}/company`;

export const storeCompanySearch = `${domain}/api/company/search`;

export const countrySearch = `${domain}/location/country`;


// Get All State

export const allState = `${serviceDomain}/api/state/states_list`;
export const searchCity = `${serviceDomain}/api/city/search_city`;

// api/state/states_list

export function getStateByCountry(id) {
  return `${domain}/location/state/${id}`;
}
export function getCityByState(id) {
  return `${serviceDomain}/location/city/${id}`;
}
export function getCityByZip(id) {

  return `${serviceDomain}/api/zip/state_city_by_zip_code?zipCode=${id}`;
}

export function getStateByCity(id) {
  return `${domain}/zip/state_city_by_zip_code/?zipCode=${id}`;
}

export const getStore = `${domain}/store`;
export const storeSearch = `${domain}/store/search`;
export const saveStore = `${domain}/store`;

// Role
export const storeRole = `${permissionDomain}/api/roles/save`;
export const updateRole = `${permissionDomain}/api/roles/update`;

export function searchRole(id) {
  return `${permissionDomain}/api/roles/search/${id}`;
}
export function editRole(id) {
  return `${permissionDomain}/api/roles/get-role/${id}`;
}
export function deleteRole(roleID, loginID) {
  return `${permissionDomain}/api/roles/delete-role/${roleID}/${loginID}`;
}



// Permission

export const getAllPermission = `${permissionDomain}/api/permissions/load-permissions`;
