import React, { Suspense, useState } from "react";
import { AutoComplete, Select, Input, Divider } from "antd";
import { Link } from "react-router-dom";
import { prescriberDetailState } from "../../../utils/constant/prescriber/atom";
import { useRecoilState } from "recoil";
import PrescriberDetail from "../../../pages/Private/Prescriber/prescriberDetail";
import SectionLoader from "../loader/section";

export default function SearchPrescriber() {
  const [isPrescriberDetail, setPrescriberDetail] = useRecoilState(prescriberDetailState);

  const prescriberDetailModal = () => {
    setPrescriberDetail(true);
  };
  const [options, setOptions] = useState([]);
  const { Option } = Select;
  const handleSearch = (value) => {
    setOptions(value ? searchPrescriber(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };
  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const searchPrescriber = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const prescriberName = `${name}${idx}`;
        const prescriberNPI = `321 321 432${idx}`;
        return {
          value: prescriberName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-th search-name">
                <i>Name:</i> {prescriberName}
              </span>
              <span className="search-td search-generic">
                <i>DOB:</i>
                {prescriberNPI}
              </span>
            </div>
          ),
        };
      });
  };
  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <PrescriberDetail />
        </div>
      </Suspense>
      <div className="input-pres input-autocomplete">
        <AutoComplete
          className="formControl"
          id="prescriber"
          dropdownClassName="search-result presc"
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          notFoundContent="No Record Found"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: ".5rem 0" }} />
              <div className="btn-wrap">
                <Link className="btn" onClick={() => prescriberDetailModal(true)}>
                  <i className="mdi mdi-plus-circle" /> <span>Prescriber</span>
                </Link>
              </div>
            </>
          )}>
          <Input.Search placeholder="" />
        </AutoComplete>
        <label className="formLabel">Prescriber</label>
      </div>
    </>
  );
}
