import React, { useState } from "react";
import { Tabs, DatePicker, Input, Select, Tooltip, Divider, Button, Popconfirm, Space, Table, message, Tag, Switch, Menu, Dropdown } from "antd";
import moment from "moment";
import { BarsOutlined, CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { binState, pcnState, groupState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import InsBinDetail from "../../Insurance/InsuranceBin/insBinDetail";
import InsPcnDetail from "../../Insurance/InsurancePcn/insPcnDetail";
import InsGroupDetail from "../../Insurance/InsuranceGroup/insGroupDetail";
import FamilyMembers from "./familyMembers";
import EligibilityChecks from "./eligibilityCheck";

// const { TabPane } = Tabs;
const { Option } = Select;

let index = 0;

function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

function cancel(e) {
  message.error("Click on No");
}
function confirm(e) {
  message.success("Click on Yes");
}

const addInsData = [];
const addMemberData = [];
for (let i = 0; i < 100; i++) {
  addMemberData.push({
    key: i,
    bin: `RysaTech ${i}`,
    pcn: `RysaTech ${i}`,
    group: `23123234${i}`,
    pbm: `23123234${i}`,
    planDrug: `RysaTech ${i}`,
  });
}
for (let i = 0; i < 100; i++) {
  addInsData.push({
    key: i,
    bin: `RysaTech ${i}`,
    pcn: `RysaTech ${i}`,
    group: `23123234${i}`,
    pbm: `23123234${i}`,
    planDrug: `RysaTech ${i}`,
  });
}

const setInsurance = (
  <Menu>
    <Menu.Item key="0">Not Specified</Menu.Item>
    <Menu.Item key="1">Primary</Menu.Item>
    <Menu.Item key="2">Secondary</Menu.Item>
    <Menu.Item key="3">Tertiary</Menu.Item>
  </Menu>
);

const columnsAddIns = [
  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
    width: 120,
  },
  {
    title: `PBM`,
    dataIndex: "pbm",
    key: "pbm",
    ellipsis: {
      showTitle: false,
    },
    render: (pbm) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={pbm}>
        {pbm}
      </Tooltip>
    ),
  },
  {
    title: `PCN`,
    dataIndex: "pcn",
    key: "pcn",
    width: 120,
  },
  {
    title: `Group`,
    dataIndex: "group",
    key: "group",
    width: 120,
  },
  {
    title: `P / D`,
    dataIndex: "planDrug",
    key: "planDrug",
    ellipsis: {
      showTitle: false,
    },
    render: (planDrug) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={planDrug}>
        {planDrug}
      </Tooltip>
    ),
  },
  {
    title: "Action",
    key: "action",
    className: "col-action",
    width: 120,
    render: () => (
      <Space size="middle" className="actions">
        <Tooltip title="Select Insurance" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-tag-ins"></Link>
        </Tooltip>
      </Space>
    ),
  },
];
const columnsAddMember = [
  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
    width: 100,
  },
  {
    title: `PBM`,
    dataIndex: "pbm",
    key: "pbm",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (pbm) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={pbm}>
        {pbm}
      </Tooltip>
    ),
  },
  {
    title: `PCN`,
    dataIndex: "pcn",
    key: "pcn",
    width: 100,
  },
  {
    title: `Group`,
    dataIndex: "group",
    key: "group",
    width: 100,
  },
  {
    title: "Plan / Drug",
    dataIndex: "planDrug",
    key: "planDrug",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (planDrug) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={planDrug}>
        {planDrug}
      </Tooltip>
    ),
  },
  // {
  //   title: `Plan ID`,
  //   dataIndex: "planId",
  //   key: "planId",
  //   width: 100,
  // },
  {
    title: `Member ID`,
    dataIndex: "memberId",
    key: "memberId",
    width: 100,
    ellipsis: {
      showTitle: false,
    },
    render: (memberId) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={memberId}>
        {memberId}
      </Tooltip>
    ),
  },
  {
    title: `PC`,
    dataIndex: "pc",
    key: "pc",
    width: 100,
  },
  {
    title: `CH Name`,
    dataIndex: "chName",
    key: "chName",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (chName) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={chName}>
        {chName}
      </Tooltip>
    ),
  },
  // {
  //   title: "Online",
  //   dataIndex: "online",
  //   width: 70,
  //   render: (row) => {
  //     if (row === true) {
  //       return (
  //         <Tag icon={<CheckCircleOutlined />} color="success">
  //           Yes
  //         </Tag>
  //       );
  //     } else {
  //       return (
  //         <Tag icon={<ExclamationCircleOutlined />} color="warning">
  //           No
  //         </Tag>
  //       );
  //     }
  //   },
  // },
  {
    title: `Start Date`,
    dataIndex: "startDate",
    key: "startDate",
    width: 100,
    render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    className: "col-status",
    width: 60,
    render: () => (
      <Switch
        size="small"
        className="bg-green"
        checkedChildren={<i className="mdi mdi-check" />}
        unCheckedChildren={<i className="mdi mdi-close" />}
        defaultChecked
      />
    ),
  },
  {
    title: <Tooltip title="Prefference">Pref.</Tooltip>,
    dataIndex: "insPrefference",
    width: 60,
    align: "center",
    render: (insPrefference) => (
      <>
        <Dropdown overlay={setInsurance} trigger={["click"]}>
          <Tooltip title="Set Insurance" mouseLeaveDelay={0} placement="bottom">
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <BarsOutlined />
            </a>
          </Tooltip>
        </Dropdown>
        <Tag className="ins-status">
          <i className="mdi mdi-checkbox-multiple-blank-circle"></i>
        </Tag>
        {/* <Tag className="ins-status primary">
          <i className="mdi mdi-numeric-1-box"></i>
        </Tag>
        <Tag className="ins-status secondary">
          <i className="mdi mdi-numeric-2-box"></i>
        </Tag>
        <Tag className="ins-status tertiary">
          <i className="mdi mdi-numeric-3-box"></i>
        </Tag> */}
      </>
    ),
  },
  {
    title: `Notes`,
    dataIndex: "notes",
    key: "notes",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (notes) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={notes}>
        {notes}
      </Tooltip>
    ),
  },
  {
    title: "Action",
    key: "action",
    className: "col-action",
    width: 60,
    render: () => (
      <Space size="middle" className="actions">
        <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-edit"></Link>
        </Tooltip>
      </Space>
    ),
  },
];

export default function PatientBilling() {
  const { items, name } = useState(0);
  const [isBin, setBin] = useRecoilState(binState);
  const [isPcn, setPcn] = useRecoilState(pcnState);
  const [isGroup, setGroup] = useRecoilState(groupState);
  const state = {
    items: ["jack", "lucy"],
    name: "",
  };

  return (
    <>
      <div className="d-none">
        <InsBinDetail />
        <InsGroupDetail />
        <InsPcnDetail />
      </div>
      <div className="btn-bar">
        <div className="float-left">
          <FamilyMembers />
          <EligibilityChecks />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12">
          <section className="bg-primary pat-billing">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-md">
                      <Select
                        autoFocus
                        showSearch
                        placeholder="BIN"
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: ".5rem 0" }} />
                            <div className="btn-wrap">
                              <Button className="btn" onClick={() => setBin(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>BIN</span>
                              </Button>
                            </div>
                          </>
                        )}>
                        <Option value="1">A101284</Option>
                        <Option value="2">KMWX</Option>
                        <Option value="3">A1051465</Option>
                      </Select>
                      <label className="formLabel">BIN</label>
                      <Link className="input-group-append btn btn-edit" onClick={() => setBin(true)}>
                        <i className="mdi mdi-pencil m-0"></i>
                      </Link>
                    </div>
                    <div className="input-group input-xl">
                      <Input className="formControl" placeholder="PBM" />
                      <label className="formLabel">PBM</label>
                    </div>
                    <div className="input-group input-lg">
                      <Select
                        showSearch
                        placeholder="PCN"
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: ".5rem 0" }} />
                            <div className="btn-wrap">
                              <Button className="btn" onClick={() => setPcn(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>PCN</span>
                              </Button>
                            </div>
                          </>
                        )}>
                        <Option value="1">A101284</Option>
                        <Option value="2">KMWX</Option>
                        <Option value="3">A1051465</Option>
                      </Select>
                      <label className="formLabel">PCN</label>
                      <Link className="input-group-append btn btn-edit" onClick={() => setPcn(true)}>
                        <i className="mdi mdi-pencil m-0"></i>
                      </Link>
                    </div>
                    <div className="input-group input-lg">
                      <Select
                        showSearch
                        placeholder="Group"
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: ".5rem 0" }} />
                            <div className="btn-wrap">
                              <Link className="btn" onClick={() => setGroup(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>Group</span>
                              </Link>
                            </div>
                          </>
                        )}>
                        <Option value="1">A101284</Option>
                        <Option value="2">KMWX</Option>
                        <Option value="3">A1051465</Option>
                      </Select>
                      <label className="formLabel">Group</label>
                      <Link className="input-group-append btn btn-edit" onClick={() => setGroup(true)}>
                        <i className="mdi mdi-pencil m-0"></i>
                      </Link>
                    </div>
                    <div className="input-group" style={{ width: "300px" }}>
                      <Input className="formControl" placeholder="Plan / Drug" />
                      <Tooltip title="Plan / Drug" placement="topLeft">
                        <label className="formLabel">P / D</label>
                      </Tooltip>
                    </div>
                    <div className="float-right">
                      <Tooltip title="Link These" mouseLeaveDelay={0}>
                        <Button
                          className="btn btn-icon btn-sm btn-link"
                          icon={<i className="mdi mdi-link"></i>}
                          style={{ float: "right !important" }}
                        />
                      </Tooltip>
                      <Tooltip title="Search" mouseLeaveDelay={0}>
                        <Button className="btn btn-icon btn-sm btn-search ml-2" icon={<SearchOutlined />} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <Table
                  className="table-length length-five"
                  columns={columnsAddIns}
                  dataSource={addInsData}
                  pagination={false}
                  scroll={{ y: 150 }}
                  rowKey={(record) => record.uid}
                />
                {/* </TabPane> */}
                {/* <TabPane tab="Facility" key="2">
                      <PatientBillingFacility />
                    </TabPane>
                    <TabPane tab="House Charge" key="3">
                      <PatientBillingHouseCharge />
                    </TabPane> */}
                {/* </Tabs> */}
              </div>
            </div>
          </section>
          <section className="bg-primary">
            <div className="section-title mb-3">
              <span className="title-text">Members Info</span>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Member #" />
                      <label className="formLabel">Member #</label>
                    </div>
                    <div className="input-group input-xs  ">
                      <Input className="formControl" placeholder="PC" />
                      <Tooltip title="Person Code" placement="topLeft">
                        <label className="formLabel">PC</label>
                      </Tooltip>
                    </div>
                    <div className="input-group input-lg">
                      <Select placeholder="Realtionship">
                        <Option value="pharmacist">Father</Option>
                        <Option value="mother">Mother</Option>
                        <Option value="brother">Brother</Option>
                        <Option value="sister">Sister</Option>
                        <Option value="son">Son</Option>
                        <Option value="daughter">Daughter</Option>
                        <Option value="spouse">Spouse</Option>
                        <Option value="other">Other</Option>
                      </Select>
                      <label className="formLabel">Realtionship</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="CH Last Name" />
                      <Tooltip title="Card Holder Last Name" placement="topLeft">
                        <label className="formLabel">CH Last Name</label>
                      </Tooltip>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="CH First Name" />
                      <Tooltip title="Card Holder First Name" placement="topLeft">
                        <label className="formLabel">CH First Name</label>
                      </Tooltip>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="MI" />
                      <label className="formLabel">MI</label>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="input-group input-xl float-right ml-5">
                        <Input placeholder="Notes"></Input>
                      </div>
                      <div className="input-group input-date ml-5">
                        <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYY" />
                        <label className="formLabel">Start Date</label>
                      </div>
                      <Button className="btn btn-icon btn-add ml-4 float-right">
                        <span></span>
                      </Button>
                      {/* <div className="input-group input-date">
                          <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYY" />
                          <Tooltip title="Expiration Date" placement="topLeft">
                            <label className="formLabel">Exp. Date</label>
                          </Tooltip>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              className="table-length length-five"
              columns={columnsAddMember}
              dataSource={addMemberData}
              pagination={false}
              scroll={{ y: 138 }}
              rowKey={(record) => record.uid}
            />
          </section>
        </div>
      </div>
    </>
  );
}
