import { Button, message, Popconfirm, Modal, DatePicker, InputNumber, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { amountFormatter, amountParser, percentFormatter, percentParser } from "../../../../utils/methods/validation";
import { saleCompanionItemState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";

export default function SaleCompanionItem() {
  const { RangePicker } = DatePicker;
  const [isSaleCompanionItem, setSaleCompanionItem] = useRecoilState(saleCompanionItemState);

  const handleOk = async (e) => {
    setSaleCompanionItem(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setSaleCompanionItem(false);
    message.error("Data not saved!");
  };

  return (
    <>
      {/* <Button className="btn float-right" icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />} onClick={() => setSaleCompanionItem(true)}>
        Eligibility Check
      </Button> */}
      <Modal
        width={500}
        title="Companion Item Sale"
        visible={isSaleCompanionItem}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section>
          <div className="row">
            <div className="col-12">
              <div className="input-group input-daterange float-right">
                <RangePicker />
              </div>
            </div>
            <label className="extend-label success w-100 text-right mx-4 my-3">Current item at regular price.</label>
          </div>
          <section className="bg-secondary">
            <div className="row">
              <label className="extend-label success float-right my-3 mx-4">Get following item at discount.</label>
              <div className="col-12 my-1">
                <div className="input-group input-upc">
                  <Input className="formControl" placeholder="Item # / UPC" />
                  <label className="formLabel">Item # / UPC</label>
                </div>
                <div className="input-group input-xl">
                  <Input className="formControl" placeholder="Name" />
                  <label className="formLabel">Name</label>
                </div>
              </div>
              <div className="col-12 my-1">
                <label className="extend-label success float-left">Discount</label>
                <div className="d-flex justify-content-end">
                  <div className="input-group input-percent">
                    <InputNumber
                      className="formControl"
                      placeholder="Surcharge"
                      min={0}
                      max={100}
                      formatter={(value) => percentFormatter(value)}
                      parser={(value) => percentParser(value)}
                    />
                  </div>
                  <div className="input-group input-amount">
                    <InputNumber
                      className="formControl"
                      placeholder="0.00"
                      min={0}
                      formatter={(value) => amountFormatter(value)}
                      parser={(value) => amountParser(value)}
                      precision={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 my-1">
                <label className="extend-label warning float-left">Sale Price</label>
                <div className="input-group input-amount float-right">
                  <InputNumber
                    className="formControl"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                  />
                </div>
              </div>
              <div className="col-12 my-1">
                <label className="extend-label info float-left">Qty. Limit</label>
                <div className="input-group input-pkg float-right">
                  <InputNumber precision={2} className="formControl" placeholder="0.00" />
                </div>
              </div>
            </div>
          </section>
        </section>
      </Modal>
    </>
  );
}
