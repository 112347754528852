const { atom } = require("recoil");

export const groupNdcState = atom({
  key: "groupNdcState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const auxillaryLabelsState = atom({
  key: "auxillaryLabelsState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const salePercentState = atom({
  key: "salePercentState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const saleBogoState = atom({
  key: "saleBogoState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const saleSecondItemDiscountState = atom({
  key: "saleSecondItemDiscountState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const saleCompanionItemState = atom({
  key: "saleCompanionItemState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
