import React, { Component } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Modal, Button, Input, Tabs } from "antd";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

class UserSettings extends Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    return (
      <>
        <Link to="/" className="dropdown-item" onClick={this.showModal}>
          <i className="mdi mdi-account-settings"></i> User Setting
        </Link>
        <Modal
          visible={visible}
          title="User Setting"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={
            <div className="btn-bar">
              <div className="float-right">
                <Button className="btn btn-save" onClick={this.handleOk}>
                  <span></span>
                </Button>
                <Button className="btn btn-close" onClick={this.handleCancel}>
                  <span></span>
                </Button>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col-12 mx-auto">
              <section className="bg-primary">
                <Tabs tabPosition="left">
                  <TabPane tab="Chnage Password" key="1">
                    <form className="profileSttingForm ">
                      <div className="input-group">
                        <Input
                          className="formControl mb-2"
                          size="large"
                          placeholder="Username"
                          autoComplete="false"
                        />
                        <label className="formLabel">Username</label>
                      </div>
                      <div className="input-group m-0">
                        <Input
                          className="formControl mb-2"
                          size="large"
                          placeholder="Email"
                          autoComplete="false"
                        />
                        <label className="formLabel">Email</label>
                      </div>
                      <div className="input-group m-0">
                        <Input.Password
                          className="formControl mb-2"
                          size="large"
                          placeholder="Password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          autoComplete="false"
                        />
                        <label className="formLabel">Password</label>
                      </div>
                      <div className="input-group m-0">
                        <Input.Password
                          className="formControl mb-2"
                          size="large"
                          placeholder="Password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          autoComplete="false"
                        />
                        <label className="formLabel">Confirm Password</label>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tab="User Info." key="2">
                    Content of Tab 2
                  </TabPane>
                  {/* <TabPane tab="Tab 3" key="3">
                    Content of Tab 3
                  </TabPane> */}
                </Tabs>
              </section>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default UserSettings;
