import moment from "moment";
function findIndexWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeDeleteObject(arr, id) {
  return removeItemAtIndex(arr, findIndexWithAttr(arr, "id", id));
}

export function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
}

// FORMATTER
export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
// Days
export function daysFormatter(value) {
  return `${value}`.replace(` Day(s)`, "");
}
export function daysParser(value) {
  return value.replace(` Day(s)`, "");
}
// Amount
export function amountFormatter(value) {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function amountParser(value) {
  return value.replace(/\$\s?|(,*)/g, "");
}
// Percent
export function percentFormatter(value) {
  return `${value}%`;
}
export function percentParser(value) {
  return value.replace("%", "");
}
