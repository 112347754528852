const { atom } = require("recoil");

// e-Rx
export const eRxWizardState = atom({
  key: "eRxWizardState",
  default: false,
});
export const eRxViewDetailState = atom({
  key: "eRxViewDetailState",
  default: false,
});
export const eRxLogState = atom({
  key: "eRxLogState",
  default: false,
});

export const editRxState = atom({
  key: "editRxState",
  default: false,
});

export const defferedState = atom({
  key: "defferedState",
  default: false,
});

export const billLaterState = atom({
  key: "billLaterState",
  default: false,
});

export const rejectedClaimState = atom({
  key: "rejectedClaimState",
  default: false,
});

export const printLabelState = atom({
  key: "printLabelState",
  default: false,
});

export const refillRxState = atom({
  key: "refillRxState",
  default: false,
});

export const autoRefillRxState = atom({
  key: "autoRefillRxState",
  default: false,
});

export const dmsSearchState = atom({
  key: "dmsSearchState",
  default: false,
});
