import React from 'react';

function LoaderPill() {
  return (
    <div className="loader loader-sm">
      <div className="loaderPill ">
        <div className="loaderPill-anim">
          <div className="loaderPill-anim-bounce">
            <div className="loaderPill-anim-flop">
              <div className="loaderPill-pill"></div>
            </div>
          </div>
        </div>
        <div className="loaderPill-floor">
          <div className="loaderPill-floor-shadow"></div>
        </div>
        <div className="loaderPill-text">
          <h2>LOADING</h2>
        </div>
      </div>
    </div>
  );
}
export default LoaderPill;
