import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio, Tooltip } from "antd";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import React, { Suspense, useState } from "react";
import { useRecoilState } from "recoil";
import SectionLoader from "../../../../components/common/loader/section";
import { prescriberClinicDetailState, prescriberClinicSearchState } from "../../../../utils/constant/prescriber/atom";
import PrescriberClinicDetail from "../prescriberClinicDetail";
import LinkClinicTable from "./linkClinicTable";
import PrescriberClinicSearch from "./prescriberClinicSearchModal";

export default function LinkClinic() {
  const [isPrescriberClinicSearch, setPrescriberClinicSearch] = useRecoilState(prescriberClinicSearchState);
  const [isPrescriberClinicDetail, setPrescriberClinicDetail] = useRecoilState(prescriberClinicDetailState);

  // function prescriberClinicDetailModal(){
  //   prescriberClinicDetailState
  // }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setPrescriberClinicSearch(true);
    }
  };
  return (
    <>
      <div className="d-none">
        <PrescriberClinicSearch />
        <PrescriberClinicDetail />
      </div>
      <Suspense fallback={<SectionLoader />}>
        <section className="bg-secondary">
          <div className="section-title">
            <span className="title-text">Clinics</span>
            <Button className="btn float-right mt-2" icon={<i className="mdi mdi-plus-circle" />} onClick={() => setPrescriberClinicDetail(true)}>
              <span>Clinic</span>
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <div className="input-group input-xl">
              <Input className="formControl" placeholder="Name" onKeyPress={handleKeyPress}></Input>
              <label className="formLabel">Name</label>
            </div>
            <div className="input-group input-phone">
              <MaskedInput
                className="formControl"
                name="tel"
                placeholder="Tel"
                mask="111 111 1111"
                prefix={
                  <Tooltip title="Tel" position="top">
                    <i className="mdi mdi-phone"></i>
                  </Tooltip>
                }
              />
              {/* <label className="formLabel">Tel</label> */}
            </div>
            <div className="input-group input-city">
              <Input className="formControl" placeholder="City" name="state" onKeyPress={handleKeyPress} />
              <label className="formLabel">City</label>
            </div>
            <div className="input-group input-state">
              <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" onKeyPress={handleKeyPress} />
              <label className="formLabel">St.</label>
            </div>
            <div className="input-group input-zip">
              <MaskedInput className="formControl" placeholder="Zip" mask="11111" name="zip" onKeyPress={handleKeyPress} />
              <label className="formLabel">Zip</label>
            </div>
            <div className="input-divider">
              <hr axis="y"></hr>
            </div>
            <div className="input-group w-auto">
              <Radio.Group className="ml-3" defaultValue={1}>
                <Radio value={1}>Starts With</Radio>
                <Radio value={2}>Contains</Radio>
              </Radio.Group>
            </div>
            <Tooltip title="Search" mouseLeaveDelay={0}>
              <Button className="btn btn-sm btn-search" icon={<SearchOutlined />} onClick={handleKeyPress} />
            </Tooltip>
          </div>
          <LinkClinicTable />
        </section>
      </Suspense>
    </>
  );
}
