import React, { useState } from "react";
import { Select } from "antd";
import IcdCodes from "./icdCodes";

const { Option } = Select;

export default function PatientAdditionalInfo() {
  return (
    <>
      <form>
        <div className="row align-items-center">
          <div className="col-12">
            <section className="bg-primary">
              <div className="section-title">
                <span className="title-text">Disease State(s)</span>
                {/* <div className="input-group w-auto ml-5">
                    <Select placeholder="Select" className="formControl" defaultValue="notSelected" style={{ width: "150px" }}>
                      <Option value="notSelected">Not Selected</Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="formLabel">Hippa Acknowledged</label>
                  </div> */}
              </div>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="input-group input-md d-block">
                    <Select placeholder="Select" className="formControl" defaultValue="notSelected" autoFocus>
                      <Option value="notSelected">Not Selected</Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="formLabel">Diabetes</label>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="input-group input-md d-block">
                    <Select placeholder="Select" className="formControl" defaultValue="notSelected">
                      <Option value="notSelected">Not Selected</Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="formLabel">Insulin Dependent</label>
                  </div>
                </div>
              </div>
            </section>
            <IcdCodes />
            <section className="bg-primary pat-add-info d-none">
              <div className="input-group w-auto ml-4">
                <Select placeholder="Medicare Assignment" className="formControl" defaultValue="notSelected" style={{ width: "200px" }}>
                  <Option value="notSelected">Not Selected</Option>
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </div>
            </section>
          </div>
        </div>
      </form>
    </>
  );
}
