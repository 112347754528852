import { Button, DatePicker, Input, message, Popconfirm, Select, Space, Table, Tooltip, Modal, Tag, AutoComplete } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { eligibilityCheckState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import SearchPatient from "../../../../components/common/searchPatient";

export default function EligibilityCheck() {
  const [visible, setVisible] = React.useState(0);
  const [options, setOptions] = useState([]);
  const [isEligibilityCheck, setEligibilityCheck] = useRecoilState(eligibilityCheckState);
  const [state, setState] = useState({
    name: "",
    dob: new Date(),
    tel: "",
    relation: "",
    insID: "",
    address: 1,
  });

  const columnsEligibilityCheck = [
    {
      title: "Insurance",
      dataIndex: "insurance",
      key: "insurance",
      width: 100,
    },
    {
      title: "BIN",
      dataIndex: "bin",
      key: "bin",
      width: 100,
    },
    {
      title: `PBM`,
      dataIndex: "pbm",
      key: "pbm",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (pbm) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={pbm}>
          {pbm}
        </Tooltip>
      ),
    },
    {
      title: `PCN`,
      dataIndex: "pcn",
      key: "pcn",
      width: 100,
    },
    {
      title: `Group`,
      dataIndex: "group",
      key: "group",
      width: 100,
    },
    {
      title: `Member ID`,
      dataIndex: "memberId",
      key: "memberId",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (memberId) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={memberId}>
          {memberId}
        </Tooltip>
      ),
    },
    {
      title: `PC`,
      dataIndex: "personCode",
      key: "personCode",
      width: 50,
    },
    {
      title: `CH Name`,
      dataIndex: "chName",
      key: "chName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (chName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={chName}>
          {chName}
        </Tooltip>
      ),
    },
    {
      title: `Termination`,
      dataIndex: "termination",
      key: "termination",
      width: 100,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: `Effective`,
      dataIndex: "effective",
      key: "effective",
      width: 100,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
  ];

  const eligibilityCheckData = [];
  for (let i = 0; i < 100; i++) {
    eligibilityCheckData.push({
      key: i,
      pcn: `A098087`,
      group: `KWNX`,
      memberId: `1111111${i}`,
      pc: `0${i}`,
      chName: `LastName, FirstName`,
      termination: `10-20-2018`,
      effective: `10-20-2018`,
    });
  }

  const handleOk = async (e) => {
    setVisible(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setEligibilityCheck(false);
    message.error("Data not saved!");
  };

  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const { Option } = Select;

  const handleSearch = (value) => {
    setOptions(value ? searchPatient(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const searchPatient = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const patientName = `${name}${idx}`;
        const patientDOB = `10-10-198${idx}`;
        return {
          value: patientName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {patientName}
              </span>
              <span className="search-generic">
                <i>DOB:</i>
                {patientDOB}
              </span>
            </div>
          ),
        };
      });
  };

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />} onClick={() => setEligibilityCheck(true)}>
        Eligibility Check
      </Button>
      <Modal
        width={1280}
        title="Eligibility Check"
        visible={isEligibilityCheck}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-left">
              <Button className="btn btn-print">Print</Button>
            </div>
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>

              {/* <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard"> */}
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
              {/* </Popconfirm> */}
            </div>
          </div>
        }>
        <section className="bg-secondary">
          <div className="section-title">
            <span className="title-text">Patient Info</span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="input-group input-xl">
                <SearchPatient />
              </div>
              <div className="input-group input-date">
                <DatePicker className="formControl" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" name="dateOfBirth" />
                <Tooltip title="Date of Birth">
                  <label className="formLabel">DOB</label>
                </Tooltip>
              </div>
              <div className="input-group input-lg">
                <Select placeholder="Realtionship">
                  <Option value="pharmacist">Father</Option>
                  <Option value="mother">Mother</Option>
                  <Option value="brother">Brother</Option>
                  <Option value="sister">Sister</Option>
                  <Option value="son">Son</Option>
                  <Option value="daughter">Daughter</Option>
                  <Option value="spouse">Spouse</Option>
                  <Option value="other">Other</Option>
                </Select>
                <label className="formLabel">Realtionship</label>
              </div>
              <div className="input-group input-zip input-sm">
                <MaskedInput className="formControl" placeholder="Zip" mask="11111" name="zip" />
                <label className="formLabel">Zip</label>
              </div>
              <div className="input-group input-lg">
                <Input className="formControl" placeholder="Member #" autoFocus />
                <label className="formLabel">Member #</label>
              </div>
              <Tooltip title="Check">
                <Button className="btn btn-icon btn-sm ml-3">
                  <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
                </Button>
              </Tooltip>
            </div>
          </div>
          <section className="bg-primary">
            <Table
              rowSelection
              className="table-length length-five"
              columns={columnsEligibilityCheck}
              dataSource={eligibilityCheckData}
              pagination={false}
              scroll={{ y: 150 }}
              rowKey={(record) => record.uid}
            />
          </section>
        </section>
      </Modal>
    </>
  );
}
