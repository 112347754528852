import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag, Switch, Button } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
import { eRxLogState } from "../../../../../utils/constant/notification/atom";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function ReadyToFill() {
  const [isERxLog, setERxLog] = useRecoilState(eRxLogState);
  const eRxLogModal = () => {
    setERxLog(true);
  };
  const columns = [
    {
      title: <Tooltip title="Store Number">S. #</Tooltip>,
      dataIndex: "storeNumber",
      key: "storeNumber",
      width: 40,
      align: "center",
    },
    {
      title: (
        <>
          <i className="icon rx-number"></i>
        </>
      ),
      dataIndex: "rxNumber",
      key: "rxNumber",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Refills" placement="top" mouseLeaveDelay="0">
          R.
        </Tooltip>
      ),
      dataIndex: "refillNumber",
      key: "refillNumber",
      width: 40,
      align: "center",
    },

    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 120,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Last Fill Date",
      dataIndex: "lastFillDate",
      key: "lastFillDate",
      width: 120,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Last Pickup Date",
      dataIndex: "lastPickupDate",
      key: "lastPickupDate",
      width: 130,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      ellipsis: {
        showTitle: false,
      },
      render: (patientName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
          {patientName}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
      align: "center",
    },
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      width: 100,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Dispensing Quantity" placement="top" mouseLeaveDelay="0">
          DQ
        </Tooltip>
      ),
      dataIndex: "dispenseQty",
      key: "dispenseQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
          DS
        </Tooltip>
      ),
      dataIndex: "daySupply",
      key: "daySupply",
      width: 50,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Remaining Quantity" placement="top" mouseLeaveDelay="0">
          RQ
        </Tooltip>
      ),
      dataIndex: "remainingQty",
      key: "remainingQty",
      width: 70,
      align: "center",
    },

    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },

    {
      title: "Refill Remarks",
      dataIndex: "refillRemarks",
      key: "refillRemarks",
      ellipsis: {
        showTitle: false,
      },
      render: (refillRemarks) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={refillRemarks}>
          {refillRemarks}
        </Tooltip>
      ),
    },
    {
      title: "Rx Notes",
      dataIndex: "rxNotes",
      key: "rxNotes",
      ellipsis: {
        showTitle: false,
      },
      render: (rxNotes) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={rxNotes}>
          {rxNotes}
        </Tooltip>
      ),
    },

    {
      title: (
        <Tooltip title="Process" placement="topLeft" mouseLeaveDelay="0">
          P. Status
        </Tooltip>
      ),
      dataIndex: "processStatus",
      key: "processStatus",
      width: 80,
      align: "center",
      render: (row) => {
        if (row === true) {
          return <Tag icon={<i className="mdi mdi-check"></i>} color="success"></Tag>;
        } else {
          return <Tag icon={<i className="mdi mdi-close"></i>} color="error"></Tag>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Print" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-print"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 15; i++) {
    data.push({
      key: i,
      storeNumber: `123`,
      rxNumber: `1234567`,
      refillNumber: `0${i}`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90`,
      authRefill: `120`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      binNumber: `123 1234567 0${i}`,
      sig: `Take 2 tablets 3 times a day.`,
      note: `Testing note for long text`,
      copay: `157.${i}`,
    });
  }

  return (
    <section className="bg-primary">
      <div className="section-title">
        <span className="title-text">Ready to fill</span>
      </div>
      <Table
        rowSelection
        className="table-length length-five th-green m-0"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: "230px" }}
      />
    </section>
  );
}
