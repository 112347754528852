import React from "react";
import { Link } from "react-router-dom";
import { Image, Carousel } from "antd";
import { GlassMagnifier } from "react-image-magnifiers";
import PatientDummyDoc from "Assets/img/docs/patient-medical-record.png";
import FallBackImg from "Assets/img/product-thumbnail-images.jpg";

export default function DocumentPreview() {
  return (
    <div className="preview-doc-wrapper">
      <Carousel dotPosition="top">
        <div className="preview-doc">
          <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
        </div>
        <div className="preview-doc">
          <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
        </div>
        <div className="preview-doc">
          <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
        </div>
        {/* <GlassMagnifier imageSrc={docImg} largeImageSrc={docImg} imageAlt="Document Preview" magnifierSize="30%" square /> */}
      </Carousel>
    </div>
  );
}
