/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table, Tooltip, Select, Space, Modal, message } from "antd";
import AddRolesPermission from "./components/addRolesPermissions";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { rolesDialogState, rolesVisibleState } from "../../../utils/constant/roles/atom";
import { roleSearchs, destoryRole } from "../../../utils/methods/apiMethods";





export default function EmployeeRollSearch({ isRolesDialog, closeSidebarToggle }) {

  const [isRolesModal, setRolesDialog] = useState(rolesDialogState);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [name, setName] = useState("");
  const [editVisible, setEditVisible] = useRecoilState(rolesVisibleState);;
  const [id, setID] = useState("");







  const handleCancel = (e) => {

    closeSidebarToggle("");
  };

  const searchRole = async () => {

    if (name === "") {
      return message.error("Name Required!")
    }

    setLoading(true);
    let res = await roleSearchs(name);
    console.log(res);
    if (res.data.status === "SUCCESS") {
      setRoles(res.data.object);
      setLoading(false);
    } else {
      setLoading(false);
    }

  }
  const editRole = async (id) => {
    setID(id);
    setEditVisible(true);


  }
  const deleteRole = async (id) => {


    setDelLoading(true);
    let res = await destoryRole(id);
    console.log(res);
    if (res.data.status === "SUCCESS") {
      let index = roles.findIndex(x => parseInt(x.secRoleId) === parseInt(id));

      const rowArr = [...roles];
      rowArr.splice(index, 1);
      setRoles(rowArr);
      setDelLoading(false);
    } else {
      setDelLoading(false);
    }
  }

  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
    },

    {
      title: "Premissions",
      key: "action",
      width: "100px",
      render: (row) => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit" onClick={() => editRole(row.secRoleId)}></Link>
          </Tooltip>
          <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-delete" onClick={() => deleteRole(row.secRoleId)}></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <Modal
      width={700}
      title="Roles"
      visible={isRolesDialog}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <div className="col-12 mx-auto">
        <div className="float-right" style={{ marginTop: "-2.25rem", marginBottom: ".5rem" }}>
          <AddRolesPermission editVisible={editVisible} setEditVisible={setEditVisible} id={id} setID={setID} />
        </div>
        <section className="search-form">
          <form>
            <div className="input-group input-xl">
              <Input className="formControl" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Role Name" size="large" autoFocus />
              <label className="formLabel">Role Name</label>
            </div>
            <Tooltip title="Search" mouseLeaveDelay={0}>
              <Button className="btn btn-search" icon={<SearchOutlined />} loading={loading} onClick={() => searchRole()} />
            </Tooltip>
            <Button className="btn btn-clear-all" onClick={() => setName("")}>Clear All</Button>
          </form>
        </section>
        <Table size="small" columns={columns} dataSource={roles} pagination={false} scroll={{ y: "calc(100vh - 325px)" }} />
        <div className="btn-bar">
          <div className="float-right">{/* <AddRolesPermission /> */}</div>
        </div>
      </div>
    </Modal>
  );
}
