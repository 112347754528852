import React, { Suspense } from "react";
import { Button, Dropdown, Input, Menu, message, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import PageLoader from "../../../components/common/loader/page";
import SectionLoader from "../../../components/common/loader/section";
import { useRecoilState } from "recoil";
import { rvLogState, dvLogState } from "../../../utils/constant/order/atom";
import InOrderPrescription from "./components/inOrderPrescription";
import InOrderPendingPrescription from "./components/inOrderPendingPrescription";
import DvLog from "./components/dvLog";
import RvLog from "./components/rvLog";
import DrugVerify from "./components/drugVerify";
import RphVerify from "./components/rphVerify";
import { docGalleryState } from "../../../utils/constant/processing/atom";
import DocGallery from "../../../components/common/dms/components/docGallery";

export default function Order() {
  const [isDvLog, setDvLog] = useRecoilState(dvLogState);
  const [isRvLog, setRvLog] = useRecoilState(rvLogState);
  const [isDocGallery, setDocGallery] = useRecoilState(docGalleryState);
  const docGalleryModal = () => {
    setDocGallery(true);
  };
  const dvLogModal = () => {
    setDvLog(true);
  };
  const rvLogModal = () => {
    setRvLog(true);
  };

  function handleButtonClick(e) {
    message.info("Click on left button.");
    console.log("click left button", e);
  }
  function handleActionMenuClick(e) {
    message.info("Click on menu item.");
    console.log("click", e);
  }

  const attachDocMenu = (
    <Menu onClick={handleActionMenuClick} className="processing-menu btn-menu">
      <Menu.Item key="scan">
        <Button className="btn btn-scan">Scan</Button>
      </Menu.Item>
      <Menu.Item key="viewList">
        <Button className="btn btn-view-list" onClick={() => docGalleryModal(true)}>
          List
        </Button>
      </Menu.Item>
      <Menu.Item key="document">
        <Button className="btn btn-doc">DMS</Button>
      </Menu.Item>
      <Menu.Item key="import">
        <Button className="btn btn-upload float-right">
          <i className="mdi mdi-upload"></i>
          <span>Upload</span>
          <input type="file" />
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="order-page">
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <DvLog />
          <RvLog />
          <DocGallery />
        </div>
      </Suspense>
      <section className="p-1 m-0">
        <InOrderPendingPrescription />
        <InOrderPrescription />
      </section>
      <div className="btn-bar action-btns text-center">
        {/* <div className="d-flex  px-1"> */}
        {/* <div className="input-group input-xl mr-5"> */}
        <Input className="input-xl mr-5" placeholder="Scan Bar Code" autoFocus></Input>
        {/* </div> */}
        <Button className="btn btn-print">Print Label</Button>
        <Dropdown overlay={attachDocMenu}>
          <Button className="btn btn-attach-doc">
            Attach Docs <DownOutlined />
          </Button>
        </Dropdown>
        <hr axis="y" className="button-divider" />
        <Button className="btn btn-e-refill" onClick={() => dvLogModal(true)}>
          <Tooltip title="Electronic Refill" placement="bottom">
            DV Log
          </Tooltip>
        </Button>
        <Button className="btn btn-t-log" onClick={() => rvLogModal(true)}>
          <Tooltip title="Transmission Log" placement="bottom">
            RV Log
          </Tooltip>
        </Button>
        <hr axis="y" className="button-divider" />
        <DrugVerify />
        <RphVerify />
      </div>
      {/* </div> */}
    </div>
  );
}
