import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
// import { flowLogState } from "../../../../utils/constant/processing/atom";
import SectionLoader from "../../../../components/common/loader/section";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function InOrderPendingPrescription() {
  // const [isFlowLog, setFlowLog] = useRecoilState(flowLogState);

  // const flowLogModal = () => {
  //   setFlowLog(true);
  // };
  const columns = [
    {
      title: "Fill Date / Time",
      dataIndex: "fillDatetime",
      key: "fillDatetime",
      align: "center",
      width: 90,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Picked up Date / Time",
      dataIndex: "pickupDatetime",
      key: "pickupDatetime",
      align: "center",
      width: 90,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Order #",
      dataIndex: "orderNumber",
      key: "orderNumber",
      align: "center",
      width: 70,
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (patientName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
          {patientName}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 80,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
      align: "center",
    },
    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (user) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={user}>
          {user}
        </Tooltip>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 15; i++) {
    data.push({
      key: i,
      orderNumber: `1${i}2`,
      rxNumber: `1234567`,
      refillNumber: `0`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90.${i}`,
      authRefill: `120.${i}`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      patientName: `Abdul Rafay`,
      user: `magrafay`,
    });
  }

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">{/* <FlowLog /> */}</div>
      </Suspense>
      <Table
        className="table-length length-fifteen th-blue m-0 width-80 mx-auto d-flex float-none"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: "326px" }}
      />
    </>
  );
}
