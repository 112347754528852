import { adminMenus } from './route';

export default function menuRoute(name, rcvParams = null) {
  let menu = adminMenus.filter((menu) => menu.name === name);

  let path = menu[0].path;

  if (path.includes(':')) {
    if (rcvParams) {
      let params = getParams(path);

      if (params.length === rcvParams.length) {
        for (let index = 0; index < params.length; index++) {
          path = path.replace(params[index], rcvParams[index]);
        }
        return path;
      } else {
        throw new Error(`Too Few or More Parameters Given \n Required: ${params.length} | Given: ${rcvParams.length}`);
      }
    } else {
      throw new Error(`No parameters Present\nThis Route requieres Paramters \nRoute: ${path} \nParameters Received: ${rcvParams}`);
    }
  } else {
    if (rcvParams?.length > 0) {
      throw new Error(`No parameters Required\nThis Route requieres No Paramters but parameter given \nRoute: ${path} \nParameters Received: ${rcvParams}`);
    }
    return path;
  }
}

function getParams(path) {
  let pathWords = path.split('/');

  let params = pathWords.filter((singleWord) => singleWord.includes(':'));
  return params;
}
