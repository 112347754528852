/* eslint-disable no-unused-vars */
import React from "react";
import { Modal, Button, Switch, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";

function SetStatus() {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Content of the modal");

  const inActiveReason = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  return (
    <>
      <Tooltip title="Status" mouseLeaveDelay={0} placement="bottomRight">
        <Switch className="ml-3 mt-2 float-right bg-green" onChange={inActiveReason} checkedChildren="Active" unCheckedChildren="InActive" checked />
      </Tooltip>
      <Modal
        className="setStatus-modal"
        title="InActive Reason"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        visible={visible}
        footer="">
        <div className="row align-items-center">
          <div className="col-9">
            <TextArea className="input-notes" placeholder="Add Reason" rows={4} />
          </div>
          <div className="col-2">
            <Button type="button" className="btn btn-inactive" onClick={handleOk}>
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SetStatus;
