const { atom } = require("recoil");

export const patientDetailState = atom({
  key: "patientDetailState",
  default: false,
});

export const transmissionLogState = atom({
  key: "transmissionLogState",
  default: false,
});

export const cvsInfoState = atom({
  key: "cvsInfoState",
  default: false,
});

export const billingDetailState = atom({
  key: "billingDetailState",
  default: false,
});

export const binContactInfoState = atom({
  key: "binContactInfoState",
  default: false,
});

export const flowLogState = atom({
  key: "flowLogState",
  default: false,
});

export const sendRreState = atom({
  key: "sendRreState",
  default: false,
});

export const patientInsuranceState = atom({
  key: "patientInsuranceState",
  default: false,
});

export const claimSubmissionValueState = atom({
  key: "claimSubmissionValueState",
  default: false,
});
export const workmansCompState = atom({
  key: "workmansCompState",
  default: false,
});

export const discContRxState = atom({
  key: "discContRxState",
  default: false,
});

export const docGalleryState = atom({
  key: "docGalleryState",
  default: false,
});

export const patientBillingModalState = atom({
  key: "patientBillingModalState",
  default: false,
});

export const rxPickupState = atom({
  key: "rxPickupState",
  default: true,
});
