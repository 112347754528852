import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import rejectedClaims from "../../../assets/img/notification/Rejected-Claim-Default.png";
import editRx from "../../../assets/img/notification/edit-RX-Default.png";
import refillRx from "../../../assets/img/notification/Refill-Default.png";
import billLater from "../../../assets/img/notification/Bill-Later-Default.png";
import deffered from "../../../assets/img/notification/PA-Default.png";
import printLabel from "../../../assets/img/notification/Print-LBL-Default.png";
import erx from "../../../assets/img/notification/E-Rx-R-Default.png";
import faxes from "../../../assets/img/notification/Faxes-R-Default.png";
import autoFill from "../../../assets/img/notification/Auto-Fills-Default.png";
import dms from "../../../assets/img/notification/DMS-Default.png";
import { Badge } from "antd";
import { useRecoilState } from "recoil";
import {
  rejectedClaimState,
  billLaterState,
  defferedState,
  editRxState,
  eRxWizardState,
  printLabelState,
  refillRxState,
  autoRefillRxState,
  dmsSearchState,
} from "../../../utils/constant/notification/atom";
import SectionLoader from "../../common/loader/section";
import ERxWizard from "../../common/notification/eRx";
import EditRx from "../../common/notification/edit-rx";
import Deffered from "../../common/notification/deffered";
import BillLater from "../../common/notification/billLater";
import RejectedClaim from "../../common/notification/rejectedClaim";
import PrintLabel from "../../common/notification/printLabel";
import RefillRx from "../../common/notification/refill-rx";
import AutoRefillRx from "../../common/notification/autoRefillRx";
import DmsSearch from "../../common/dialogs/dmsSearch";

export default function HeaderNotification() {
  const [isERxWizard, setERxWizard] = useRecoilState(eRxWizardState);
  const [isEditRx, setEditRx] = useRecoilState(editRxState);
  const [isDeffered, setDeffered] = useRecoilState(defferedState);
  const [isBillLater, setBillLater] = useRecoilState(billLaterState);
  const [isRejectedClaim, setRejectedClaim] = useRecoilState(rejectedClaimState);
  const [isPrintLabel, setPrintLabel] = useRecoilState(printLabelState);
  const [isRefillRx, setRefillRx] = useRecoilState(refillRxState);
  const [isAutoRefillRx, setAutoRefillRx] = useRecoilState(autoRefillRxState);
  const [isDmsSearch, setDmsSearch] = useRecoilState(dmsSearchState);

  const dmsSearchModal = () => {
    setDmsSearch(true);
  };

  const autoRefillModal = () => {
    setAutoRefillRx(true);
  };

  const refillRxModal = () => {
    setRefillRx(true);
  };

  const printLabelModal = () => {
    setPrintLabel(true);
  };

  const rejectedClaimModal = () => {
    setRejectedClaim(true);
  };

  const billLaterModal = () => {
    setBillLater(true);
  };

  const defferedModal = () => {
    setDeffered(true);
  };

  const editRxModal = () => {
    setEditRx(true);
  };

  const eRxWizardModal = () => {
    setERxWizard(true);
  };

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <ERxWizard />
          <EditRx />
          <Deffered />
          <BillLater />
          <RejectedClaim />
          <PrintLabel />
          <RefillRx />
          <AutoRefillRx />
          <DmsSearch />
        </div>
      </Suspense>
      <div className="notification-wrapper">
        <Badge size="small" onClick={() => printLabelModal(true)}>
          <Link to="#">
            <img src={printLabel} width="30px" title="Print Label" alt="Print Label"></img>
          </Link>
        </Badge>
        <hr axis="y" className="mx-2" />
        <Badge size="small" onClick={() => editRxModal(true)}>
          <Link to="#">
            <img src={editRx} width="28px" title="Edit Rx" alt="Edit Rx"></img>
          </Link>
        </Badge>
        <hr axis="y" className="mx-2" />
        <Badge size="small" count={100} overflowCount={50} onClick={() => eRxWizardModal(true)}>
          <Link to="#">
            <img src={erx} width="30px" title="eRx" alt="eRx"></img>
          </Link>
        </Badge>
        <Badge size="small" count={5} onClick={() => refillRxModal(true)}>
          <Link to="#">
            <img src={refillRx} width="30px" title="Refill Rx" alt="Refill Rx"></img>
          </Link>
        </Badge>
        <hr axis="y" className="mx-2" />
        <Badge size="small" count={5} onClick={() => defferedModal(true)}>
          <Link to="#">
            <img src={deffered} width="30px" title="Deffered" alt="Deffered"></img>
          </Link>
        </Badge>
        <Badge size="small" count={5} onClick={() => billLaterModal(true)}>
          <Link to="#">
            <img src={billLater} width="30px" title="Bill Later" alt="Bill Later"></img>
          </Link>
        </Badge>
        <Badge size="small" count={3} onClick={() => autoRefillModal(true)}>
          <Link to="#">
            <img src={autoFill} width="30px" title="Autofill" alt="Autofill"></img>
          </Link>
        </Badge>
        <hr axis="y" className="mx-2" />
        <Badge size="small" count={5} onClick={() => rejectedClaimModal(true)}>
          <Link to="#">
            <img src={rejectedClaims} width="30px" title="Rejected Claims" alt="Rejected Claims"></img>
          </Link>
        </Badge>
        {/* <hr axis="y" className="mx-2" /> */}
        {/* <Badge size="small" count={5}>
          <Link to="#">
            <img src={faxes} width="30px" title="Faxes" alt="Faxes"></img>
          </Link>
        </Badge> */}
        {/* <hr axis="y" className="mx-2" /> */}
        <Badge size="small" onClick={() => dmsSearchModal(true)}>
          <Link to="#">
            <img src={dms} width="30px" title="DMS" alt="DMS"></img>
          </Link>
        </Badge>
        <hr axis="y" className="ml-2" />
      </div>
    </>
  );
}
