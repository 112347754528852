import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag, Button, Modal, DatePicker, Input, Checkbox } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
import { rejectedClaimState } from "../../../../utils/constant/notification/atom";
import { transmissionLogState } from "../../../../utils/constant/processing/atom";
import SectionLoader from "../../loader/section";
import TransmissionLog from "../../../../pages/Private/Processing/components/transmissionLog";
function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function RejectedClaim() {
  const [isTransmissionLog, setTransmissionLog] = useRecoilState(transmissionLogState);
  const [isRejectedClaim, setRejectedClaim] = useRecoilState(rejectedClaimState);

  const transmissionLogModal = () => {
    setTransmissionLog(true);
  };

  const handleOk = async (e) => {
    setRejectedClaim(false);
  };

  const handleCancel = async (e) => {
    setRejectedClaim(false);
  };

  const columns = [
    {
      title: "Fill Date",
      dataIndex: "fillDate",
      key: "fillDates",
      width: 80,
      align: "center",
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: <Tooltip title="Store Number">S. #</Tooltip>,
      dataIndex: "storeNumber",
      key: "storeNumber",
      width: 40,
      align: "center",
    },
    {
      title: (
        <>
          <i className="icon rx-number"></i>
        </>
      ),
      dataIndex: "rxNumber",
      key: "rxNumber",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Refills" placement="top" mouseLeaveDelay="0">
          R.
        </Tooltip>
      ),
      dataIndex: "refillNumber",
      key: "refillNumber",
      width: 40,
      align: "center",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (patientName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
          {patientName}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date",
      key: "date",
      width: 80,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
      align: "center",
    },
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "Strength",
      dataIndex: "strength",
      key: "strength",
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      render: (strength) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
          {strength}
        </Tooltip>
      ),
    },
    {
      title: "Sig",
      dataIndex: "sig",
      key: "sig",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (sig) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={sig}>
          {sig}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip title="Order Quantity" placement="top" mouseLeaveDelay="0">
          OQ
        </Tooltip>
      ),
      dataIndex: "orderQty",
      key: "orderQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Dispense Quantity" placement="top" mouseLeaveDelay="0">
          DQ
        </Tooltip>
      ),
      dataIndex: "dispenseQty",
      key: "dispenseQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
          DS
        </Tooltip>
      ),
      dataIndex: "daySupply",
      key: "daySupply",
      width: 50,
      align: "center",
    },
    {
      title: `P. Paid`,
      dataIndex: "pPaid",
      key: "pPaid",
      width: 70,
      className: "col-amount",
      align: "center",
    },
    {
      title: `S. Paid`,
      dataIndex: "sPaid",
      key: "sPaid",
      width: 70,
      className: "col-amount",
      align: "center",
    },
    {
      title: `Billed`,
      dataIndex: "billed",
      key: "billed",
      width: 70,
      className: "col-amount",
      align: "center",
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      width: 90,
      align: "center",
    },
    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },
    {
      title: `Bin #`,
      dataIndex: "binNo",
      key: "binNo",
      width: 70,
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 120,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
          <Tooltip title="Print" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-print"></Link>
          </Tooltip>
          <Tooltip title="Attach" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-attach"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      storeNumber: `123`,
      rxNumber: `1234567`,
      refillNumber: `0${i}`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90`,
      authRefill: `120`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      binNumber: `123 1234567 0${i}`,
      sig: `Take 2 tablets 3 times a day.`,
      note: `Testing note for long text`,
      copay: `157.${i}`,
    });
  }

  return (
    <Modal
      width={1800}
      style={{ top: 25 }}
      className="modal-rejected-claim"
      title="Rejected Claims"
      visible={isRejectedClaim}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      // keyboard={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <TransmissionLog />
        </div>
      </Suspense>
      <Table
        rowSelection
        className="table-search-main mt-0"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 690 }}
      />
      <div className="btn-bar action-btns">
        <div className="float-right">
          <Button className="btn btn-t-log" onClick={() => transmissionLogModal(true)}>
            <Tooltip title="Transmission Log">T. Log</Tooltip>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
