/* eslint-disable no-unused-vars */
import { CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Modal, Button, Input, Space, Table, Tooltip, Popconfirm, message, Radio, DatePicker, Tag, Switch } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import PageLoader from "../../../components/common/loader/page";
import { drugDetailState } from "../../../utils/constant/drug/atom";
import ProductDrugDetail from "./component/productDrugDetail";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

const infoProduct = () => {
  Modal.info({
    title: "Product Information",
    content: (
      <>
        <div className="info-modal">
          <div className="card flex-row">
            <div className="card-img">
              <i className="mdi mdi-store"></i>
            </div>
            <div className="card-detail">
              <span className="d-inline-block w-100">
                <h6 className="card-name">Doe, John</h6>
                <p className="card-id">
                  <strong>Prescriber #:</strong> 123456789
                </p>
              </span>
              <span className="d-inline-block w-100">
                <p className="card-address">
                  620 Broadway Street, King City. <small>CA, 93930</small>
                </p>
                <p className="card-phoneNo mb-0">
                  <i className="mdi mdi-phone"></i> 111 222 3333
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    ),
  });
};

export default function DrugSearch() {
  const [isDrugDetailModal, setDrugDetailModal] = useRecoilState(drugDetailState);

  const drugDetailModal = () => {
    setDrugDetailModal(true);
  };

  const onCancel = () => {
    setDrugDetailModal(false);
  };
  const [state, setState] = useState({
    dateOfBirth: "",
  });
  const columns = [
    {
      title: "Name/Brand",
      dataIndex: "productName",
      key: "productName",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (productName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={productName}>
          {productName}
        </Tooltip>
      ),
    },
    {
      title: "Generic",
      dataIndex: "generic",
      key: "generic",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "Strength",
      dataIndex: "strength",
      key: "strength",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (strength) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
          {strength}
        </Tooltip>
      ),
    },
    {
      title: "Pkg. Size",
      dataIndex: "pkgSize",
      key: "pkgSize",
      align: "center",
      width: 90,
    },
    {
      title: "Stock Qty.",
      dataIndex: "stockQty",
      key: "stockQty",
      align: "center",
      width: 90,
    },
    {
      title: (
        <Tooltip title="Brand/Generic" mouseEnterDelay="0">
          B/G
        </Tooltip>
      ),
      dataIndex: "brandGeneric",
      key: "brandGeneric",
      width: 100,
    },
    {
      title: "D. Type",
      dataIndex: "drugType",
      key: "drugType",
      align: "center",
      width: 70,
      className: "col-tag-toggle",
      render: (row) => {
        if (row === true) {
          return <Tag>OTC</Tag>;
        } else {
          return (
            <Tag>
              <i className="mdi mdi-prescription"></i>
            </Tag>
          );
        }
      },
    },
    {
      title: "D. Class",
      dataIndex: "drugClass",
      key: "drugClass",
      width: 100,
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      align: "center",
      width: 120,
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      align: "center",
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (manufacturer) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={manufacturer}>
          {manufacturer}
        </Tooltip>
      ),
    },
    // {
    //   title: "Vendor Name",
    //   dataIndex: "vendorName",
    //   key: "vendorName",
    //   width: 150,
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (vendor) => (
    //     <Tooltip mouseLeaveDelay={0} placement="topLeft" title={vendor}>
    //       {vendor}
    //     </Tooltip>
    //   ),
    // },

    {
      title: <Tooltip title="Autofill">AF</Tooltip>,
      dataIndex: "autofill",
      key: "autofill",
      align: "center",
      width: 50,
      render: () => (
        <Switch
          size="small"
          className="bg-green"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close" />}
          defaultChecked
        />
      ),
    },
    {
      title: `Pkg. Cost`,
      dataIndex: "pkgCost",
      key: "pkgCost",
      align: "center",
      width: 100,
      className: "col-amount",
    },
    {
      title: `Unit Cost`,
      dataIndex: "unitCost",
      key: "unitCost",
      align: "center",
      width: 100,
      className: "col-amount",
    },
    {
      title: <Tooltip title="Price Code">PC</Tooltip>,
      dataIndex: "priceCode",
      key: "priceCode",
      align: "center",
      width: 70,
    },
    {
      title: "Status",
      dataIndex: "activeInd",
      width: 60,
      align: "center",
      render: () => (
        <Switch
          size="small"
          className="bg-green"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close" />}
          defaultChecked
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 100,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
          <Tooltip title="Print" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-print"></Link>
          </Tooltip>
          <Tooltip title="Add" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-dbPlus"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      productName: `Amoxicillin/POT Calvulana`,
      generic: `Amoxicillin Oral Tablet 87`,
      strength: `250-62.5mG/5mL`,
      pkgSize: `100.${i}`,
      stockQty: `150.${i}`,
      ndc: `23123 2341 2${i}`,
      upc: `231232341${i}`,
      manufacturer: `Aurobindo`,
      vendorName: `Mayo Clinic`,
      pkgCost: `157.${i}`,
      unitCost: `257.${i}`,
    });
  }
  function handleChangeDate(date, dateString) {
    setState({
      ...state,
      dateOfBirth: date,
    });
  }
  return (
    <>
      <div className="d-none">
        <ProductDrugDetail />
      </div>
      <Suspense fallback={<PageLoader />}>
        <div className="main-header">
          <h4 className="page-title">Drug Search</h4>
          <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => drugDetailModal(true)}>
            Drug
          </Button>
        </div>
        <section className="search-form">
          <form>
            <div className="input-group input-xxl">
              <Input className="formControl" placeholder="Name / NDC / UPC" size="large" autoFocus />
              <label className="formLabel">Name / NDC / UPC</label>
            </div>
            <div className="form-actions">
              <div className="input-group w-auto d-flex align-items-center ml-3">
                <Radio.Group defaultValue="local">
                  <Radio value="local">Local</Radio>
                  <Radio value="master">Master</Radio>
                </Radio.Group>
                <div className="input-group w-auto mx-4">
                  <hr axis="y" className="input-divider"></hr>
                </div>
                <Radio.Group defaultValue="all">
                  <Radio value="all">All</Radio>
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">InActive</Radio>
                </Radio.Group>
                {/* <Radio.Group className="mt-2" defaultValue={1}>
                <Radio value={1}>All</Radio>
                <Radio value={2}>Rx</Radio>
                <Radio value={3}>OTC</Radio>
              </Radio.Group> */}
                {/* <div className="input-group w-auto mx-4">
                <hr axis="y" className="input-divider"></hr>
              </div> */}
                {/* <Radio.Group className="mt-2" defaultValue={1}>
                <Radio value={1}>Start</Radio>
                <Radio value={2}>Contain</Radio>
              </Radio.Group> 
              <div className="input-group w-auto mx-4">
              <hr axis="y" className="input-divider"></hr>
            </div>*/}
              </div>
              <Tooltip title="Search" mouseLeaveDelay={0}>
                <Button className="btn btn-search" icon={<SearchOutlined />} />
              </Tooltip>
              <Button className="btn btn-clear-all">Clear All</Button>
            </div>
          </form>
        </section>
        <Table
          className="table-search-main"
          size="small"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: "calc(100vh - 300px)" }}
        />
      </Suspense>
    </>
  );
}
