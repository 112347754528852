import React, { Suspense, useState } from "react";
import { Modal, Button, Select, InputNumber, Tooltip } from "antd";
import { binContactInfoState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";

export default function BinContactInfo() {
  const [isBinContactInfo, setBinContactInfo] = useRecoilState(binContactInfoState);
  const handleOk = async (e) => {
    setBinContactInfo(false);
  };

  const handleCancel = async (e) => {
    setBinContactInfo(false);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        width={800}
        className="modal-bin-contact-info"
        title="Insurance Contact Info"
        visible={isBinContactInfo}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="m-0 p-0">
            <div className="row">
              <div className="col-12 mx-auto">
                <section className="bg-white">
                  <div className="section-title">
                    <span className="title-text">Bin Contact Info</span>
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <i className="mdi mdi-numeric-1-box"></i>
                    </div>
                    <MaskedInput
                      id="tel1"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel1"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <i className="mdi mdi-numeric-2-box"></i>
                    </div>
                    <MaskedInput
                      id="tel2"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel2"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <span>PA</span>
                    </div>
                    <MaskedInput
                      id="telPa"
                      className="formControl"
                      placeholder="Tel (PA)"
                      mask="111 111 1111"
                      name="telPa"
                      prefix={
                        <Tooltip title="Tel (Prior Authorization) " position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <span>PA</span>
                    </div>
                    <MaskedInput
                      id="faxPa"
                      className="formControl"
                      placeholder="Fax (PA)"
                      mask="111 111 1111"
                      name="faxPa"
                      prefix={
                        <Tooltip title="Fax (Prior Authorization)" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                </section>
                <section className="bg-secondary">
                  <div className="section-title">
                    <span className="title-text">Group Contact Info</span>
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <i className="mdi mdi-numeric-1-box"></i>
                    </div>
                    <MaskedInput
                      id="tel1"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel1"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <i className="mdi mdi-numeric-2-box"></i>
                    </div>
                    <MaskedInput
                      id="tel2"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel2"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <span>PA</span>
                    </div>
                    <MaskedInput
                      id="telPa"
                      className="formControl"
                      placeholder="Tel (PA)"
                      mask="111 111 1111"
                      name="telPa"
                      prefix={
                        <Tooltip title="Tel (Prior Authorization) " position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend">
                      <span>PA</span>
                    </div>
                    <MaskedInput
                      id="faxPa"
                      className="formControl"
                      placeholder="Fax (PA)"
                      mask="111 111 1111"
                      name="faxPa"
                      prefix={
                        <Tooltip title="Fax (Prior Authorization)" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
