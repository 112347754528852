import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, InputNumber, Space, Table, Divider } from "antd";
import { useRecoilState } from "recoil";
import SectionLoader from "../../loader/section";
import { autoRefillRxState } from "../../../../utils/constant/notification/atom";
import NoRefill from "./components/no-refills";
import ReadyToFill from "./components/ready-to-fill";
import { sendRreState } from "../../../../utils/constant/processing/atom";
import SendRre from "../../../../pages/Private/Processing/components/refills";
import { SearchOutlined } from "@ant-design/icons";
import InputMask from "antd-mask-input/build/main/lib/inputmask-core";
import { MaskedInput } from "antd-mask-input";
import SearchPatient from "../../searchPatient";

export default function AutoRefillRx() {
  const [isSendRre, setSendRre] = useRecoilState(sendRreState);
  const [isAutoRefillRx, setAutoRefillRx] = useRecoilState(autoRefillRxState);

  const sendRreModal = () => {
    setSendRre(true);
  };

  const handleOk = async (e) => {
    setAutoRefillRx(false);
  };

  const handleCancel = async (e) => {
    setAutoRefillRx(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setAutoRefillRx(current);
  };

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <SendRre />
        </div>
      </Suspense>
      <Modal
        width={1800}
        style={{ top: 25 }}
        className="modal-notification"
        title={
          <>
            <span>Auto Refill</span>
            <i className="mdi mdi-prescription"></i>
          </>
        }
        visible={isAutoRefillRx}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <div className="d-none">
            <Suspense fallback={<SectionLoader />}></Suspense>
          </div>
          <NoRefill />
          <ReadyToFill />
          <div className="btn-bar action-btns">
            <div className="float-right">
              <Button className="btn btn-e-refill" onClick={() => sendRreModal(true)}>
                E-Refill
              </Button>
              <hr axis="y" className="button-divider"></hr>
              <Button className="btn btn-delete">Delete</Button>
              <hr axis="y" className="button-divider"></hr>
              <Button className="btn btn-fax btn-refill">Fax Refill</Button>
              <Button className="btn btn-process">Process</Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
