import React, { useState } from "react";
import { Modal, Button, Popconfirm, Input, Select, Checkbox, message, Switch } from "antd";
import SetStatus from "Components/common/setStatus";
import TextArea from "antd/lib/input/TextArea";
import { pcnState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import { blockedState } from "../../../../utils/constant/insurance/atom";
import { notesDrawerState, notesDrawerTitle } from "../../../../utils/constant/atom";
import SetStatusBlocked from "../../../../components/common/setStatus/blocked";

function InsPcnDetail() {
  const [checked, setChecked] = useState(false);
  const [isPcn, setPcn] = useRecoilState(pcnState);
  const [isBlocked, setBlocked] = useState(false);
  const [isBlockedState, setBlockedState] = useRecoilState(blockedState);
  const [isNotes, setNotes] = useRecoilState(notesDrawerState);
  const [title, setNoteTitle] = useRecoilState(notesDrawerTitle);
  const insPcnDetailModal = () => {
    setPcn(true);
  };
  const openNotes = (title) => {
    setNoteTitle(title);
    setNotes(true);
  };

  const blockedReasonModal = () => {
    setBlockedState(true);
  };
  const handleOk = async (e) => {
    setPcn(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setPcn(false);
    message.error("Data not saved!");
  };

  const { Option } = Select;

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function handleShowMsg(e) {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => insPcnDetailModal(true)}>
        Insurance PCN
      </Button>
      <Modal
        width={900}
        title="Add Insurance PCN"
        visible={isPcn}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="m-0 p-0">
          <div className="row flex-nowrap w-100 pr-4 position-relative">
            <div className="col-lg-8 pr-0">
              <section className="bg-primary">
                <div className="section-title">
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="PCN" autoFocus />
                    <label className="formLabel">Number</label>
                  </div>

                  <SetStatus />
                </div>
              </section>
              <section className="bg-primary">
                <div className="input-group">
                  <Select placeholder="Group(s)" mode="tags" onChange={handleChange} tokenSeparators={[","]}>
                    {children}
                  </Select>
                  <label className="formLabel">Group(s)</label>
                </div>
              </section>
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-4 pl-0">
              <section className="bg-primary">
                <div className="d-flex dir-col">
                  <div className="input-group input-switch input-sm">
                    <Switch
                      size="small"
                      className="formControl bg-green"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                      defaultChecked
                    />
                    <label className="formLabel">Online</label>
                  </div>
                  <SetStatusBlocked visible={isBlockedState} setBlockedState={setBlockedState} />

                  <div className="input-group input-switch input-sm">
                    <Switch
                      size="small"
                      className="formControl bg-red"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                    />
                    <label className="formLabel">Stars</label>
                  </div>
                </div>
                <div className="input-group ">
                  <Checkbox checked={checked} onChange={handleShowMsg}>
                    Show Msg.
                  </Checkbox>
                </div>
                {checked ? <TextArea rows="3" className="mt-3"></TextArea> : null}
              </section>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default InsPcnDetail;
