import React, { Suspense, useState } from "react";
import { Modal, Steps, Button, Popconfirm, message, Tooltip } from "antd";
import PatientInfo from "./components/patientInfo";
import PatientAdditionalInfo from "./components/patientAdditionalInfo";
import PatientBilling from "./components/patientBilling";
import PatientAllergies from "./components/patientAllergies";
import PatientWorksmanCom from "./components/patientWorksmanCom";
import PatientDocuments from "./components/patientDocuments";
import SectionLoader from "Components/common/loader/section";
import { patientDetailState } from "../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";

const { Step } = Steps;
const steps = [
  {
    title: "Patient Info",
    content: <PatientInfo />,
  },
  {
    title: "Disease State(s)",
    content: <PatientAdditionalInfo />,
  },
  {
    title: "Billing",
    content: <PatientBilling />,
  },
  {
    title: "Allergies",
    content: <PatientAllergies />,
  },
  {
    title: "General Docs",
    content: <PatientDocuments />,
  },
  {
    title: "Worksman Comp",
    content: <PatientWorksmanCom />,
  },
];

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

function PatientDetail() {
  const [isPatientDetail, setPatientDetail] = useRecoilState(patientDetailState);
  const patientDetailModal = () => {
    setPatientDetail(true);
  };

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOk = async (e) => {
    setPatientDetail(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setPatientDetail(false);
    message.error("Data not saved!");
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={patientDetailModal}>
        Patient
      </Button>
      <Modal
        width={1620}
        style={{ top: 30 }}
        title="Add Patient"
        visible={isPatientDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="row">
            <div className="col-2"></div>
            <div className="col-10">
              <div className="btn-bar btn-step">
                <div className="float-left">
                  {current > 0 && (
                    <>
                      <Popconfirm title="Going back without saving?" onConfirm={() => prev()} okText="Yes" cancelText="No">
                        <Button className="btn btn-prev" tabIndex="-1">
                          <i className="mdi mdi-arrow-left"></i> Back
                        </Button>
                      </Popconfirm>
                      <Button className="btn btn-prev" tabIndex="-1" onClick={() => prev()}>
                        <i className="mdi mdi-content-save-outline mr-2"></i>Save & Back<i className="mdi mdi-arrow-left float-right ml-2"></i>
                      </Button>
                    </>
                  )}
                </div>
                <div className="float-right">
                  {current < steps.length - 1 && (
                    <>
                      <Button className="btn btn-next" onClick={() => next()}>
                        <i className="mdi mdi-content-save-outline mr-2"> </i>Save & Next <i className="mdi mdi-arrow-right float-right ml-2"></i>
                      </Button>
                      <Button className="btn btn-save" onClick={handleOk}>
                        <span></span>
                      </Button>
                    </>
                  )}
                  {current === steps.length - 1 && (
                    <Button className="btn btn-save" onClick={handleOk}>
                      <span></span>
                    </Button>
                  )}
                  <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                    <Button className="btn btn-close">
                      <span></span>
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        }>
        <section className="info-bar">
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Patient Name" placement="bottomLeft">
                <i className="mdi mdi-account-card-details"></i>
              </Tooltip>
            </label>
            <span>Rafay, Malik Abdul Ghafoor</span>
          </div>
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Date Of Birth" placement="bottomLeft">
                <i className="mdi mdi-calendar"></i>
              </Tooltip>
            </label>
            <span>10-10-1989</span>
          </div>
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Cell" placement="bottomLeft">
                <i className="mdi mdi-cellphone-iphone"></i>
              </Tooltip>
            </label>
            <span>92 317 4090064 </span>
          </div>
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Tel" placement="bottomLeft">
                <i className="mdi mdi-phone"></i>
              </Tooltip>
            </label>
            <span>92 042 3090064</span>
          </div>
        </section>
        <section className="pat-detail px-0 overflow-visible">
          <div className="row">
            <div className="col-lg-2">
              <Steps className="mb-4" direction="vertical" current={current} onChange={onChange}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
            <div className="col-lg-10">
              <Suspense
                fallback={
                  <div className="loader">
                    <SectionLoader />
                  </div>
                }>
                <div className="steps-content">{steps[current].content}</div>
              </Suspense>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default PatientDetail;
