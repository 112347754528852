import { notification } from "antd";
import * as ApiRoutes from "ApiRoutes";
import Axios from "axios";


// Store Registration

export async function getCompanyRegistration() {
  try {
    const response = await Axios.get(ApiRoutes.getCompanyRegistration);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}

// Company Reg

export async function createCompany(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeCompanyRegistration, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Company Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

// Company Search

export async function companySearch(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeCompanySearch, {
      ...state,
    });

    return { status: true, data: response.data, message: "Company Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}


// Country Search


export async function countrySearch() {
  try {
    const response = await Axios.get(ApiRoutes.countrySearch);

    return { status: true, data: response.data, message: "Country Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

// cOUNTRY sTATE

export async function getCountryState(countryId) {
  try {
    const response = await Axios.get(ApiRoutes.getStateByCountry(countryId));
    return { status: true, data: response.data, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}

// sTATE cITY
export async function getStateCity(stateId) {
  try {
    const response = await Axios.get(ApiRoutes.getCityByState(stateId));
    return { status: true, data: response.data, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}

// CITY

export async function getCity(zipcode) {
  try {
    const response = await Axios.post(ApiRoutes.getCityByZip(zipcode));
    return { status: true, data: response.data, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}

// FETCH STORE
export async function fetchStores() {
  try {
    const response = await Axios.get(ApiRoutes.getStore);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}

// STORE SEARCH

export async function storeSearch(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeSearch, {
      ...state,
    });

    return { status: true, data: response.data, message: "Store Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

// STORE CREATE

export async function createStore(state) {
  try {
    const response = await Axios.post(ApiRoutes.saveStore, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Store Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

export async function fetchPermission() {
  try {
    const response = await Axios.post(ApiRoutes.getAllPermission);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}


export async function storeRole(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeRole, {
      ...state,
    });

    return { status: true, data: response.data, message: "Role Saved" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function updateRole(state) {
  try {
    const response = await Axios.post(ApiRoutes.updateRole, {
      ...state,
    });

    return { status: true, data: response.data, message: "Role Update" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function roleSearchs(role) {
  try {
    const response = await Axios.post(ApiRoutes.searchRole(role));

    return { status: true, data: response.data, message: "Role Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function editRole(role) {
  try {
    const response = await Axios.post(ApiRoutes.editRole(role));

    return { status: true, data: response.data, message: "Role Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function destoryRole(roleID) {
  try {
    const response = await Axios.post(ApiRoutes.deleteRole(roleID, 0));

    return { status: true, data: response.data, message: "Role Delete" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}


export async function fetchStates() {
  try {
    const response = await Axios.post(ApiRoutes.allState);

    return { status: true, data: response.data, message: "Country Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function fetchCity(object) {
  try {
    const response = await Axios.post(ApiRoutes.searchCity, {

      cityName: object.cityName,
      stateId: object.stateId
    });

    return { status: true, data: response.data, message: "Country Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}


function displayErrors(error) {
  notification.error({
    message: "Error",
    description: error,
  });
}
