import React, { useState } from "react";
import { Input, Select, Tooltip, InputNumber, Checkbox, Button, DatePicker, Modal, message, Popconfirm } from "antd";
import { MaskedInput } from "antd-mask-input";
import TextArea from "antd/lib/input/TextArea";
import LinkVendor from "./linkVendors";
import { amountFormatter, amountParser, percentFormatter, percentParser } from "../../../../utils/methods/validation";
import { salePercentState, saleBogoState, saleSecondItemDiscountState, saleCompanionItemState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";
import SalePercent from "./salePercent";
import SaleBogo from "./saleBogo";
import SaleSecondItemDiscount from "./saleSecondItemDiscount";
import SaleCompanionItem from "./saleSaleCompanionItem";
import { otcDetailState } from "../../../../utils/constant/drug/atom";

export default function ProductOtcDetail() {
  const [checked, setChecked] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [isSalePercent, setSalePercent] = useRecoilState(salePercentState);
  const [isSaleBogo, setSaleBogo] = useRecoilState(saleBogoState);
  const [isSaleSecondItemDiscount, setSaleSecondItemDiscount] = useRecoilState(saleSecondItemDiscountState);
  const [isSaleCompanionItem, setSaleCompanionItem] = useRecoilState(saleCompanionItemState);
  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  const [isOtcDetailModal, setOtcDetailModal] = useRecoilState(otcDetailState);

  const octDetailModal = () => {
    setOtcDetailModal(true);
  };

  const handleOk = async (e) => {
    setOtcDetailModal(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setOtcDetailModal(false);
    message.error("Data not saved.");
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
  };
  function onChange(value) {
    console.log("changed", value);
  }

  function handleShowAdditionalOrder(e) {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  return (
    <>
      <div className="d-none">
        <SalePercent />
        <SaleBogo />
        <SaleSecondItemDiscount />
        <SaleCompanionItem />
      </div>
      <Modal
        width={1680}
        style={{ top: "30px" }}
        title="Add OCT"
        visible={isOtcDetailModal}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="info-bar">
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Product Name" placement="bottomLeft">
                <i className="mdi mdi-pill"></i>
              </Tooltip>
            </label>
            <span>Amoxicillin Oral Tablet 87</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Strength" placement="bottomLeft">
                  <i className="mdi mdi-pharmacy"></i>
                </Tooltip>
              </label> */}
            <span>250-62.5mG/5mL</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Universal product Code" placement="bottomLeft">
                  <i>Form</i>
                </Tooltip>
              </label> */}
            <span>SUS</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Pkg. Size" placement="bottomLeft">
                  <i className="mdi mdi-package-variant"></i>
                </Tooltip>
              </label> */}
            <span>150.25</span>
          </div>
          <div className="info-block">
            <label>
              <Tooltip title="National product Code" placement="bottomLeft">
                <i>NDC</i>
              </Tooltip>
            </label>
            <span>54321 4321 21</span>
          </div>
          <div className="info-block">
            <label>
              <Tooltip title="National product Code" placement="bottomLeft">
                <i>UPC</i>
              </Tooltip>
            </label>
            <span>54321432121</span>
          </div>
        </section>
        <section className="product-detail my-0">
          <div className="row">
            <div className="col-lg-5 pr-0">
              <section className="bg-primary mb-0">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-upc">
                      <Input className="formControl" placeholder="Item # / UPC" autoFocus />
                      <label className="formLabel">Item # / UPC</label>
                    </div>
                    <div className="input-group input-ndc">
                      <MaskedInput className="formControl" placeholder="NDC" mask="1111 11111 11" name="npc" />
                      <label className="formLabel">NDC</label>
                    </div>
                    <div className="input-group input-md float-right">
                      <InputNumber precision={2} className="formControl" placeholder="On Hand Qty." />
                      <label className="formLabel">On Hand Qty.</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-xxl">
                      <Input className="formControl" placeholder="Name" />
                      <label className="formLabel">Name</label>
                    </div>
                    <div className="input-group input-stg">
                      <Input className="formControl" placeholder="Strength" />
                      <label className="formLabel"></label>
                    </div>
                    <div className="input-group input-form">
                      <Input className="formControl" placeholder="Form" />
                      <label className="formLabel">Form</label>
                    </div>
                    {/* <div className="input-group input-md float-right">
                    <Select className="formControl" placeholder="Select B/G">
                      <Option value="0">Select B/G</Option>
                      <Option value="1">Brand</Option>
                      <Option value="2">Generic</Option>
                    </Select>
                    <Tooltip title="Brand / Generic" placement="topRight">
                      <label className="formLabel">B/G</label>
                    </Tooltip>
                  </div> */}
                  </div>
                  <div className="col-12 my-2">
                    <div className="input-group input-pkg">
                      <InputNumber precision={2} className="formControl" placeholder="0.00" />
                      <label className="formLabel">Pkg. Size</label>
                    </div>

                    <div className="input-group input-sm float-right">
                      <Select showSearch className="formControl" placeholder="UOM">
                        <Option value="0">Select UOM</Option>
                      </Select>
                      <Tooltip title="Unit of Measure">
                        <label className="formLabel">UOM</label>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-group input-md">
                      <Select className="formControl" placeholder="Item Status">
                        <Option value="0">Select Item Status</Option>
                      </Select>
                      <label className="formLabel">Item Status</label>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="input-group input-lg">
                        <Select className="formControl" placeholder="Department">
                          <Option value="0">Select Department</Option>
                        </Select>
                        <label className="formLabel">Department</label>
                      </div>
                      <div className="input-group input-lg">
                        <Select className="formControl" placeholder="Sub Department">
                          <Option value="0">Select Sub Department</Option>
                        </Select>
                        <label className="formLabel">Sub Department</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-2">
                    <div className="input-group input-xxl">
                      <Input className="formControl" placeholder="Manufacturer" />
                      <label className="formLabel">Manufacturer</label>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Location" />
                      <label className="formLabel">Location</label>
                    </div>
                    <div className="input-group input-md float-right">
                      <Input className="formControl" placeholder="CLP" />
                      <label className="formLabel">CLP</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-md">
                      <InputNumber precision={2} className="formControl" placeholder="0.00" />
                      <label className="formLabel">Min. Stock Qty.</label>
                    </div>
                    <div className="input-group input-lg float-right">
                      <Input className="formControl" placeholder="Alternate Mono NDC" />
                      <label className="formLabel">Alternate Mono NDC</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group width-100">
                      <TextArea className="formControl input-notes" placeholder="Product Notes" id="productNote" name="productNote" rows={2} />
                      <label className="formLabel">Product Notes</label>
                    </div>
                  </div>
                </div>
              </section>
              <div className="more-option">
                <Button onClick={() => setSalePercent(true)}>Percent Sale</Button>
                <Button onClick={() => setSaleBogo(true)}>Bogo Sale</Button>
                <Button onClick={() => setSaleSecondItemDiscount(true)}>Second Item Discount</Button>
                <Button onClick={() => setSaleCompanionItem(true)}>Companion Item Sale</Button>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <section className="bg-pricing mb-0 px-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group input-group-check">
                          <Checkbox>Auto update</Checkbox>
                        </div>
                        <div className="input-group input-amount w-100">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                            onChange={onChange}
                          />
                          <Tooltip title="Package Price">
                            <label className="formLabel">Pkg. Price</label>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group input-group-check">
                          <Checkbox>Auto update</Checkbox>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <Tooltip title="Package Cost">
                            <label className="formLabel">Pkg. Cost</label>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="col-12 my-3">
                        <label className="extend-label float-left">Additional Margin</label>
                        <div className="d-flex justify-content-end">
                          <div className="input-group input-percent">
                            <div className="input-group-prepend">
                              <Checkbox></Checkbox>
                            </div>
                            <InputNumber
                              className="formControl"
                              placeholder="Surcharge"
                              min={0}
                              max={100}
                              formatter={(value) => percentFormatter(value)}
                              parser={(value) => percentParser(value)}
                              onChange={onChange}
                              disabled
                            />
                          </div>
                          <div className="input-group input-amount">
                            <div className="input-group-prepend">
                              <Checkbox></Checkbox>
                            </div>
                            <InputNumber
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="m-0 p-0 border-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="product-pricing-checks">
                          <Checkbox>Discountable</Checkbox>
                          <Checkbox>Monitored item</Checkbox>
                          <Checkbox>CRV</Checkbox>
                          <Checkbox>Taxable</Checkbox>
                        </div>
                        <div className="product-pricing-checks">
                          <Checkbox>EBT</Checkbox>
                          <Checkbox>Price below cost control</Checkbox>
                        </div>
                        <div className="product-pricing-checks">
                          <Checkbox>Rx use only</Checkbox>
                          <Checkbox>Exclude From Receipt</Checkbox>
                          <Checkbox>FSA item</Checkbox>
                        </div>
                        <hr></hr>
                        <div className="product-pricing-checks mb-3">
                          <Checkbox checked={checked} onChange={handleShowAdditionalOrder}>
                            Seasonal Additional Order
                          </Checkbox>
                        </div>
                        {checked ? (
                          <>
                            <div className="input-group input-pkg">
                              <InputNumber precision={2} className="formControl" placeholder="0.00" />
                              <label className="formLabel">Qty.</label>
                            </div>
                            <div className="input-group input-daterange">
                              <RangePicker />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-lg-5">
                  <div className="btn-bar d-flex justify-content-center">
                    <Button className="btn btn-lg btn-print">Print Shelf Tag</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <LinkVendor />
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
