import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag } from "antd";
import moment from "moment";
import SectionLoader from "../../../../components/common/loader/section";
import { useRecoilState } from "recoil";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function InOrderPrescription() {
  const columns = [
    {
      title: "Fill Date",
      dataIndex: "fillDate",
      key: "fillDate",
      align: "center",
      width: 90,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: <Tooltip title="Store Number">S. #</Tooltip>,
      dataIndex: "storeNumber",
      key: "storeNumber",
      align: "center",
      width: 50,
    },
    {
      title: (
        <>
          <i className="icon rx-number"></i>
        </>
      ),
      dataIndex: "rxNumber",
      key: "rxNumber",
      align: "center",
      width: 70,
    },
    // {
    //   title: "R. #",
    //   dataIndex: "refillNumber",
    //   key: "refillNumber",
    //   width: 40,
    //   align: "center",
    // },
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (generic) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
          {generic}
        </Tooltip>
      ),
    },
    {
      title: "Strength",
      dataIndex: "strength",
      key: "strength",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (strength) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
          {strength}
        </Tooltip>
      ),
    },
    {
      title: "Sig",
      dataIndex: "sig",
      key: "sig",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (sig) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={sig}>
          {sig}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip title="Order Quantity" placement="top" mouseLeaveDelay="0">
          OQ
        </Tooltip>
      ),
      dataIndex: "orderQty",
      key: "orderQty",
      align: "center",
      width: 70,
    },
    {
      title: (
        <Tooltip title="Dispense Quantity" placement="top" mouseLeaveDelay="0">
          DQ
        </Tooltip>
      ),
      dataIndex: "dispenseQty",
      key: "dispenseQty",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
          DS
        </Tooltip>
      ),
      dataIndex: "daySupply",
      key: "daySupply",
      width: 70,
      align: "center",
    },
    {
      title: (
        <Tooltip title="Authorize Refill" placement="top" mouseLeaveDelay="0">
          AR
        </Tooltip>
      ),
      dataIndex: "authRefill",
      key: "authRefill",
      width: 70,
      align: "center",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      align: "center",
      width: 90,
      className: "col-tag-toggle",
      render: (row) => {
        if (row === true) {
          return <Tag icon={<i>Cash</i>} className="col-tag"></Tag>;
        } else {
          return <Tag icon={<span>Insurance</span>} className="col-tag"></Tag>;
        }
      },
    },
    {
      title: `CoPay`,
      dataIndex: "copay",
      key: "copay",
      align: "center",
      width: 80,
      className: "col-amount",
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      align: "center",
      width: 120,
    },
    {
      title: "Prescriber",
      dataIndex: "prescriberName",
      key: "prescriberName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (prescriberName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
          {prescriberName}
        </Tooltip>
      ),
    },
    {
      title: "Rx Notes",
      dataIndex: "note",
      key: "note",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (manufacturer) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={manufacturer}>
          {manufacturer}
        </Tooltip>
      ),
    },
    {
      title: "Pkg. Notes",
      dataIndex: "note",
      key: "note",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (manufacturer) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={manufacturer}>
          {manufacturer}
        </Tooltip>
      ),
    },
    {
      title: <Tooltip title="Status">S.</Tooltip>,
      dataIndex: "rxStatus",
      width: 70,
      align: "center",
      render: (row) => {
        if (row === true) {
          return (
            <>
              <Tag className="rx-status data-entry">
                <Tooltip title="Data Entry">DE</Tooltip>
              </Tag>
              <Tag className="rx-status hold">
                <Tooltip title="Hold">H</Tooltip>
              </Tag>
              <Tag className="rx-status deferred">
                <Tooltip title="Deferred">DEF</Tooltip>
              </Tag>
              <Tag className="rx-status filed">
                <Tooltip title="Filed">F</Tooltip>
              </Tag>
              <Tag className="rx-status rejected">
                <Tooltip title="Rejected">R</Tooltip>
              </Tag>
              <Tag className="rx-status cashed">
                <Tooltip title="Cashed">C</Tooltip>
              </Tag>
              <Tag className="rx-status billed">
                <Tooltip title="Billed">B</Tooltip>
              </Tag>
              <Tag className="rx-status drug-verified">
                <Tooltip title="Drug Verified">DV</Tooltip>
              </Tag>
              <Tag className="rx-status rph-verified">
                <Tooltip title="Rph Verified">RV</Tooltip>
              </Tag>
              <Tag className="rx-status rph-verified-filed">
                <Tooltip title="Rph Verified Filed">RVF</Tooltip>
              </Tag>
              <Tag className="rx-status binned">
                <Tooltip title="Binned">BD</Tooltip>
              </Tag>
              <Tag className="rx-status picked-up">
                <Tooltip title="Picked Up">PU</Tooltip>
              </Tag>
              <Tag className="rx-status delivered">
                <Tooltip title="Delivered">D</Tooltip>
              </Tag>
              <Tag className="rx-status transferred">
                <Tooltip title="Transferred">XF</Tooltip>
              </Tag>
              <Tag className="rx-status payment-due">
                <Tooltip title="Payment Due">PD</Tooltip>
              </Tag>
              <Tag className="rx-status price-match">
                <Tooltip title="Price Match">PM</Tooltip>
              </Tag>
              <Tag className="rx-status disc-med">
                <Tooltip title="Discontinued Medicine">DM</Tooltip>
              </Tag>
              <Tag className="rx-status del">
                <Tooltip title="Deleted">DEL</Tooltip>
              </Tag>
            </>
          );
        }
      },
    },

    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      align: "center",
      width: 100,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
          <Tooltip title="Print" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-print"></Link>
          </Tooltip>
          <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-delete"></Link>
          </Tooltip>

          {/* <Tooltip title="Product Info" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-info" onClick={infoProduct}></Link>
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 15; i++) {
    data.push({
      key: i,
      storeNumber: `123`,
      rxNumber: `1234567`,
      refillNumber: `0${i}`,
      medication: `Amoxicillin/POT Calvulana`,
      strength: `250-62.5mG/5mL`,
      orderQty: `100.${i}`,
      dispenseQty: `150.${i}`,
      daySupply: `90.${i}`,
      authRefill: `120.${i}`,
      remainingQty: `100.${i}`,
      ndc: `23123 2341 2${i}`,
      prescriberName: `Mayo Clinic`,
      binNumber: `123 1234567 0${i}`,
      sig: `Take 2 tablets 3 times a day.`,
      note: `Testing note for long text`,
      copay: `157.${i}`,
    });
  }

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none"></div>
      </Suspense>
      <Table
        rowSelection
        className="table-length th-green length-fifteen table-completed-pres"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: "calc(100vh - 580px)" }}
      />
    </>
  );
}
