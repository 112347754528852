import { Button, DatePicker, Input, message, Popconfirm, Select, Space, Table, Tooltip, Modal, AutoComplete } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { familyMemberState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import SearchPatient from "Components/common/searchPatient";

export default function FamilyMembers() {
  const [visible, setVisible] = React.useState(0);
  const [options, setOptions] = useState([]);
  const [isFamilyMember, setFamilyMember] = useRecoilState(familyMemberState);
  const [state, setState] = useState({
    name: "",
    dob: new Date(),
    tel: "",
    relation: "",
    insID: "",
    address: 1,
    patientArr: [],
    patientData: {
      data: [
        { name: "Rafay, Abdul", dob: "06-30-1978" },
        { name: "Ahmad, Faraz", dob: "12-24-1982" },
        { name: "Mirza, Tabish", dob: "10-20-1989" },
      ],
    },
  });

  const columnsFamilyMembers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={name}>
          {<span>lastName, firstName</span>}
        </Tooltip>
      ),
    },
    {
      title: `DOB`,
      dataIndex: "dob",
      key: "dob",
      width: 120,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: `Cell`,
      dataIndex: "cell",
      key: "cell",
      width: 120,
    },
    {
      title: `Home Tel`,
      dataIndex: "homeTel",
      key: "homeTel",
      width: 120,
    },
    {
      title: `Address`,
      dataIndex: "address",
      key: "address",

      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Popconfirm title="Are you sure delete this data?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
            <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-delete"></Link>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const familyMemberData = [];
  for (let i = 0; i < 100; i++) {
    familyMemberData.push({
      key: i,
      name: `RysaTech ${i}`,
      dob: `RysaPharmacy ${i}`,
      cell: `111 111 111${i}`,
      tel: `12-10-197${i}`,
    });
  }

  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }
  const handleOk = async (e) => {
    setVisible(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setFamilyMember(false);
    message.error("Data not saved!");
  };

  function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }
  const { Option } = Select;

  const handleSearch = (value) => {
    setOptions(value ? searchPatient(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const searchPatient = (name, dob) => {
    return new Array(getRandomInt(100))
      .join(".")
      .split(".")
      .map((item, idx) => {
        const patientName = `${name}${idx}`;
        const patientDOB = `10-10-198${idx}`;
        return {
          value: patientName,
          label: (
            <div className="search-dropdown-item">
              <span className="search-name">
                <i>Name:</i> {patientName}
              </span>
              <span className="search-generic">
                <i>DOB:</i>
                {patientDOB}
              </span>
            </div>
          ),
        };
      });
  };

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => setFamilyMember(true)}>
        Family Member
      </Button>
      <Modal
        width={900}
        title="Add Family Member"
        visible={isFamilyMember}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="bg-primary">
          <div className="row">
            <div className="col-12">
              <div className="input-group input-xl">
                <SearchPatient />
              </div>
              <div className="input-group input-date">
                <DatePicker className="formControl" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" name="dateOfBirth" />
                <Tooltip title="Date of Birth">
                  <label className="formLabel">DOB</label>
                </Tooltip>
              </div>
              <div className="input-group input-lg">
                <Select placeholder="0">
                  <Option value="0">Not-Specified</Option>
                  <Option value="1">Father</Option>
                  <Option value="2">Mother</Option>
                  <Option value="3">Brother</Option>
                  <Option value="4">Sister</Option>
                  <Option value="5">Son</Option>
                  <Option value="6">Daughter</Option>
                  <Option value="7">Spouse</Option>
                  <Option value="8">Other</Option>
                </Select>
                <label className="formLabel">Realtionship</label>
              </div>
              <Button className="btn btn-icon btn-sm btn-add float-right ml-3">
                <span></span>
              </Button>
            </div>
          </div>
          <Table
            className="table-length length-five"
            columns={columnsFamilyMembers}
            dataSource={familyMemberData}
            pagination={false}
            scroll={{ y: 150 }}
            rowKey={(record) => record.uid}
          />
        </section>
      </Modal>
    </>
  );
}
