import React from "react";

function PageLoader() {
  return (
    <div className='loader loader-page'>
      <div className='loader-inner'>
        <div className='loader-anim'>
          <div className='loader-anim-bounce'>
            <div className='loader-anim-flop'>
              <div className='loader-pill'></div>
            </div>
          </div>
        </div>
        <div className='loader-floor'>
          <div className='loader-floor-shadow'></div>
        </div>
        <div className='loader-text'>
          <h2>LOADING</h2>
        </div>
      </div>
    </div>
  );
}
export default PageLoader;
