import React from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Tooltip, Table, Tag, Space } from "antd";
import { dvLogState } from "../../../../utils/constant/order/atom";
import { useRecoilState } from "recoil";
import moment from "moment";

const columnsDvLog = [
  {
    title: "DV Date",
    dataIndex: "dvDate",
    key: "dvDate",
    width: 90,
    align: "center",
    render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
  },
  {
    title: "DV By",
    dataIndex: "dvBy",
    ellipsis: {
      showTitle: false,
    },
    render: (username) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={username}>
        {username}
      </Tooltip>
    ),
  },
  {
    title: "S. #",
    dataIndex: "storeNumber",
    key: "storeNumber",
    width: 50,
    align: "center",
  },
  {
    title: (
      <>
        <i className="icon rx-number"></i>
      </>
    ),
    dataIndex: "rxNumber",
    key: "rxNumber",
    width: 70,
    align: "center",
  },
  {
    title: "R. #",
    dataIndex: "refillNumber",
    key: "refillNumber",
    width: 40,
    align: "center",
  },
  {
    title: "Patient Name",
    dataIndex: "patientName",
    key: "patientName",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (patientName) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={patientName}>
        {patientName}
      </Tooltip>
    ),
  },
  {
    title: "DOB",
    dataIndex: "date",
    key: "date",
    width: 90,
    render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    align: "center",
  },
  {
    title: "Medication",
    dataIndex: "medication",
    key: "medication",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (generic) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={generic}>
        {generic}
      </Tooltip>
    ),
  },
  {
    title: "Strength",
    dataIndex: "strength",
    key: "strength",
    width: 100,
    ellipsis: {
      showTitle: false,
    },
    render: (strength) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={strength}>
        {strength}
      </Tooltip>
    ),
  },
  {
    title: "Sig",
    dataIndex: "sig",
    key: "sig",
    width: 100,
    ellipsis: {
      showTitle: false,
    },
    render: (sig) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={sig}>
        {sig}
      </Tooltip>
    ),
  },
  {
    title: (
      <Tooltip title="Dispense Quantity" placement="top" mouseLeaveDelay="0">
        DQ
      </Tooltip>
    ),
    dataIndex: "dispenseQty",
    key: "dispenseQty",
    width: 70,
    align: "right",
  },
  {
    title: (
      <Tooltip title="Day Supply" placement="top" mouseLeaveDelay="0">
        DS
      </Tooltip>
    ),
    dataIndex: "daySupply",
    key: "daySupply",
    width: 50,
    align: "center",
  },
  {
    title: "P. Ins",
    dataIndex: "primaryIns",
    key: "primaryIns",
    width: 100,
  },
  {
    title: "S. Ins",
    dataIndex: "secondaryIns",
    key: "secondaryIns",
    width: 100,
  },
  {
    title: `Billed`,
    dataIndex: "billed",
    key: "billed",
    width: 80,
    className: "col-amount",
  },
  {
    title: `Paid`,
    dataIndex: "paid",
    key: "paid",
    width: 80,
    className: "col-amount",
  },
  {
    title: `CoPay`,
    dataIndex: "copay",
    key: "copay",
    width: 80,
    className: "col-amount",
  },
  {
    title: "NDC",
    dataIndex: "ndc",
    key: "ndc",
    width: 120,
    align: "center",
  },
  {
    title: "Prescriber",
    dataIndex: "prescriberName",
    key: "prescriberName",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (prescriberName) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={prescriberName}>
        {prescriberName}
      </Tooltip>
    ),
  },
  {
    title: "Rx Note",
    dataIndex: "rxNote",
    key: "rxNote",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (rxNote) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={rxNote}>
        {rxNote}
      </Tooltip>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    width: 120,
    render: () => (
      <Space size="middle" className="actions">
        <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-edit"></Link>
        </Tooltip>
        <Tooltip title="Print" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-print"></Link>
        </Tooltip>
        <Tooltip title="Flow Log" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-log"></Link>
        </Tooltip>
        <Tooltip title="Attach" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-attach"></Link>
        </Tooltip>
        {/* <Tooltip title="Product Info" mouseLeaveDelay={0} placement="bottom">
          <Link className="action-info" onClick={infoProduct}></Link>
        </Tooltip> */}
      </Space>
    ),
  },
];

const dataDvLog = [];
for (let i = 0; i < 5; i++) {
  dataDvLog.push({
    key: i,
    username: `Rafay. Abdul Ghafoor`,
    date: `10-24-197${i}`,
  });
}

export default function DvLog() {
  const [isDvLog, setDvLog] = useRecoilState(dvLogState);

  const handleOk = async (e) => {
    setDvLog(false);
  };

  const handleCancel = async (e) => {
    setDvLog(false);
  };

  return (
    <>
      <Modal
        width={1800}
        className="modal-log"
        title="DV Log"
        visible={isDvLog}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel} autoFocus>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <Table
            className="mt-0 pt-0 "
            size="small"
            columns={columnsDvLog}
            dataSource={dataDvLog}
            pagination={false}
            scroll={{ y: 500 }}
            rowKey={(record) => record.uid}
          />
        </>
      </Modal>
    </>
  );
}
