import React from "react";
import { Modal, Button, InputNumber } from "antd";
import { useHistory } from "react-router-dom";
import { printLabelState } from "../../../../utils/constant/notification/atom";
import { useRecoilState } from "recoil";
import { MaskedInput } from "antd-mask-input";

export default function PrintLabel() {
  const history = useHistory();
  const [isprintLabel, setprintLabel] = useRecoilState(printLabelState);

  const handleOk = async (e) => {
    setprintLabel(false);
    history.push("processing-detail");
    // message.success("Electronic Refill Request sent successfully!");
  };

  const handleCancel = async (e) => {
    setprintLabel(false);
  };

  return (
    <>
      <Modal
        width={450}
        className="modal-print-label"
        title="Print Label"
        visible={isprintLabel}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="m-0 p-0">
            <div className="row">
              <div className="col-12 d-flex mx-auto">
                <div className="input-group width-60">
                  <MaskedInput
                    className="formControl"
                    placeholder="--- ------- --"
                    mask="111 1111111 11"
                    size="large"
                    autoFocus
                    prefix={
                      <>
                        <i className="mdi mdi-pound"></i>
                        <i className="mdi mdi-prescription"></i>
                      </>
                    }
                  />
                </div>
                <div className="input-group width-40">
                  <InputNumber size="large" className="formControl" placeholder="0" />
                  <label className="formLabel">No. of Labels</label>
                </div>
                <Button className="btn btn-icon btn-print" onClick={handleOk}>
                  <span></span>
                </Button>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
