import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, InputNumber, Space, Table, Divider } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { amountFormatter, amountParser } from "../../../../utils/methods/universal";
import { binContactInfoState, cvsInfoState, transmissionLogState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import moment from "moment";
import SectionLoader from "../../../../components/common/loader/section";
import CvsInfo from "./cvsInfo";
import BinContactInfo from "./insuranceContactInfo";

const columnsTLogResponse = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 150,
    render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
  },
  {
    title: "Type",
    key: "type",
    key: "type",
    width: 150,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (status) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={status}>
        {status}
      </Tooltip>
    ),
  },
  {
    title: "Auth #",
    key: "authNo",
    key: "authNo",
    width: 150,
  },
  {
    title: "Bin",
    key: "bin",
    key: "bin",
    width: 100,
  },
  {
    title: "PCN",
    key: "pcn",
    key: "pcn",
    width: 100,
  },
  {
    title: "Group",
    key: "group",
    key: "group",
    width: 100,
  },
];
const columnsTLogRejectionCode = [
  {
    title: "Rejection Code",
    key: "rejCode",
    key: "rejCode",
    width: 150,
  },
  {
    title: "Description",
    dataIndex: "desc",
    key: "desc",
    ellipsis: {
      showTitle: false,
    },
    render: (status) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={status}>
        {status}
      </Tooltip>
    ),
  },
];
const columnsTLogRejectionReason = [
  {
    title: "DUR Reason",
    dataIndex: "durReason",
    key: "durReason",
    width: 150,
    ellipsis: {
      showTitle: false,
    },
    render: (durReason) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={durReason}>
        {durReason}
      </Tooltip>
    ),
  },
  {
    title: "Level",
    key: "level",
    key: "level",
    width: 80,
  },
  {
    title: "O. Pharmacy",
    dataIndex: "oPharmacy",
    key: "oPharmacy",
    width: 150,
  },
  {
    title: "Prev. Fill",
    dataIndex: "prevFill",
    key: "prevFill",
    width: 70,
  },
  {
    title: "Qty.",
    dataIndex: "qty",
    key: "qty",
    width: 60,
  },
  {
    title: "DB",
    dataIndex: "DB",
    key: "db",
    width: 60,
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
    width: 150,
    ellipsis: {
      showTitle: false,
    },
    render: (provider) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={provider}>
        {provider}
      </Tooltip>
    ),
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    width: 200,
    ellipsis: {
      showTitle: false,
    },
    render: (comments) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];

const dataTLogResponse = [];
const dataTLogRejectionCode = [];
const dataTLogRejectionReason = [];
for (let i = 0; i < 6; i++) {
  dataTLogResponse.push({
    key: i,
    pcn: `AA017110${i}`,
  });
}
for (let i = 0; i < 5; i++) {
  dataTLogRejectionCode.push({
    key: i,
    pcn: `AA017110${i}`,
  });
}
for (let i = 0; i < 5; i++) {
  dataTLogRejectionReason.push({
    key: i,
    pcn: `AA017110${i}`,
  });
}

export default function TransmissionLog() {
  const [current, setCurrent] = React.useState(0);
  const [isTransmissionLog, setTransmissionLog] = useRecoilState(transmissionLogState);
  const [isCvsInfo, setCvsInfo] = useRecoilState(cvsInfoState);
  const [isBinContactInfo, setBinContactInfo] = useRecoilState(binContactInfoState);

  const cvsInfoModal = () => {
    setCvsInfo(true);
  };
  const binContactInfoModal = () => {
    setBinContactInfo(true);
  };
  const handleOk = async (e) => {
    setTransmissionLog(false);
  };

  const handleCancel = async (e) => {
    setTransmissionLog(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        width={1500}
        style={{ top: 25 }}
        className="modal-t-log"
        title="Transmission Log"
        visible={isTransmissionLog}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <div className="d-none">
            <Suspense fallback={<SectionLoader />}>
              <CvsInfo />
              <BinContactInfo />
            </Suspense>
          </div>
          <section className="m-0 p-0">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <Table
                  className="table-length length-five mt-0"
                  columns={columnsTLogResponse}
                  dataSource={dataTLogResponse}
                  pagination={false}
                  scroll={{ y: 115 }}
                  rowKey={(record) => record.uid}
                />
                <section className="bg-secondary custom-table mx-0 border-0">
                  <div className="section-title">
                    <section className="info-bar info-bar-sm info-bar-top mt-0 mb-2">
                      <div className="info-block">
                        <label>Bin</label>
                        <span>614520</span>
                      </div>
                      <div className="info-block">
                        <label>PBM</label>
                        <span>614520</span>
                      </div>
                      <div className="info-block">
                        <label>PCN</label>
                        <span>614520</span>
                      </div>
                      <div className="info-block">
                        <label>Group</label>
                        <span>614520</span>
                      </div>
                      <div className="info-block">
                        <label>Member ID</label>
                        <span>15649812315679841</span>
                      </div>
                      <div className="info-block">
                        <label>PC</label>
                        <span>1</span>
                      </div>
                    </section>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th width="100">Ing.</th>
                        <th width="100">
                          <span>Dispensing</span>Fee
                        </th>
                        <th width="100">
                          <span>Other</span> Fee
                        </th>
                        <th width="100">
                          <span>Sale</span> Tax
                        </th>
                        <th width="100">
                          <span>Other</span> Amount
                        </th>
                        <th width="100">Total</th>
                        <th width="100">
                          <span>Insurance</span> Approved
                        </th>
                        <th width="100">
                          <span>Insurance</span> Paid
                        </th>
                        <th width="100">Copay</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white"> Billed</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="arrow-label addon right float-left text-white"> Paid</span>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-group input-amount">
                            <InputNumber
                              disabled
                              className="formControl w-100"
                              placeholder="0.00"
                              min={0}
                              formatter={(value) => amountFormatter(value)}
                              parser={(value) => amountParser(value)}
                              precision={2}
                              onChange={onChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <Table
                  className="table-length length-three mt-0 pt-0 "
                  columns={columnsTLogRejectionCode}
                  dataSource={dataTLogRejectionCode}
                  pagination={false}
                  scroll={{ y: 70 }}
                  rowKey={(record) => record.uid}
                />
                <div className="input-group">
                  <label className="formLabel">Message</label>
                  <TextArea className="formControl ss-message" rows={4} placeholder="Message" disabled></TextArea>
                </div>
              </div>
              <div className="col-lg-3 pl-0">
                <section className="info ss-info">
                  <div className="info-block">
                    <label>Pharmacy NPI</label>
                    <span>13366458614520</span>
                  </div>
                  <div className="info-block">
                    <label>NCPDP</label>
                    <span>232162</span>
                  </div>
                </section>
                <section className="info drug-info">
                  <div className="info-block">
                    <label>
                      <i className="mdi mdi-prescription"></i>
                      <i className="mdi mdi-pound"></i>
                    </label>
                    <span>13366458614520</span>
                  </div>
                  <div className="info-block">
                    <label>Fill Date</label>
                    <span>10-10-1989</span>
                  </div>
                  <div className="info-block width-65">
                    <label>Drug</label>
                    <Tooltip title="Amoxicillin/POT Calvulana" placement="top">
                      <span>Amoxicillin/POT Calvulana</span>
                    </Tooltip>
                  </div>
                  <div className="info-block width-25 float-right">
                    <label>Strength</label>
                    <Tooltip title="250-62.5mG/5mL" placement="top">
                      <span>250-62.5mG/5mL</span>
                    </Tooltip>
                  </div>
                  <div className="info-block w-100">
                    <label>Sig</label>
                    <span></span>
                  </div>
                  <div className="info-block">
                    <label>Qty.</label>
                    <span>3000.00</span>
                  </div>
                  <div className="info-block">
                    <label>Day(s) Supply</label>
                    <span>30</span>
                  </div>
                  <div className="info-block">
                    <label>Refill</label>
                    <span>0</span>
                  </div>
                  <div className="info-block">
                    <label>NDC</label>
                    <span>00045-0212-10</span>
                  </div>
                </section>
                <section className="info patient-info">
                  <div className="info-block width-60">
                    <label>Patient</label>
                    <Tooltip title="Rafay, Abdul Ghafoor" placement="top">
                      <span>Rafay, Abdul Ghafoor</span>
                    </Tooltip>
                  </div>
                  <div className="info-block width-30 float-right">
                    <Tooltip title="Date of birth" placement="bottom" mouseLeaveDelay="0">
                      <label>DOB</label>
                    </Tooltip>
                    <span>10-10-1989</span>
                  </div>
                  <div className="info-block w-100">
                    <label>Address</label>
                    <span>620 Broadway street</span>
                  </div>
                  <div className="info-block">
                    <label>City</label>
                    <span>King City</span>
                  </div>
                  <div className="info-block">
                    <label>State</label>
                    <span>CA</span>
                  </div>
                  <div className="info-block">
                    <label>Zip</label>
                    <span>93930</span>
                  </div>
                  <div className="info-block">
                    <label>Cell</label>
                    <span>123 123 1234</span>
                  </div>
                  <div className="info-block">
                    <label>Home Tel</label>
                    <span>123 123 1234</span>
                  </div>
                </section>
                <section className="info provider-info">
                  <div className="info-block width-65">
                    <label>Provider</label>
                    <Tooltip title="Test Prescriber" placement="top">
                      <span>Test Prescriber</span>
                    </Tooltip>
                  </div>
                  <div className="info-block width-25 float-right">
                    <label>Degree</label>
                    <span></span>
                  </div>
                  <div className="info-block">
                    <label>NPI</label>
                    <span>1234567891</span>
                  </div>
                  <div className="info-block">
                    <label>DEA</label>
                    <span></span>
                  </div>
                  <div className="info-block w-100">
                    <label>Address</label>
                    <span>620 Broadway street</span>
                  </div>
                  <div className="info-block">
                    <label>City</label>
                    <span>King City</span>
                  </div>
                  <div className="info-block">
                    <label>State</label>
                    <span>CA</span>
                  </div>
                  <div className="info-block">
                    <label>Zip</label>
                    <span>93930</span>
                  </div>
                  <div className="info-block">
                    <label>Cell</label>
                    <span>123 123 1234</span>
                  </div>
                  <div className="info-block">
                    <label>Home Tel</label>
                    <span>123 123 1234</span>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <Table
            className="table-length length-three mt-0 pt-0"
            columns={columnsTLogRejectionReason}
            dataSource={dataTLogRejectionReason}
            pagination={false}
            scroll={{ y: 70 }}
            rowKey={(record) => record.uid}
          />
          <div className="btn-bar action-btns">
            <div className="float-left ">
              <Button className="btn" onClick={() => binContactInfoModal(true)}>
                Contact Processor
              </Button>
              <hr axis="y" className="button-divider" />
              <Button className="btn">Print Rejection</Button>
              <Button className="btn">P/A Fax</Button>
              <Button className="btn">P/A CMM</Button>
              <hr axis="y" className="button-divider" />
              <Button className="btn btn-edit">Edit Rx</Button>
              <Button className="btn">Defer</Button>
              <hr axis="y" className="button-divider" />
              <Button className="btn" onClick={() => cvsInfoModal(true)}>
                Reimbursement Detail
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
