const { atom } = require("recoil");

export const companyAddress = atom({
  key: "companyAddress",
  default: {
    addressLine1: "",
    addressLine2: "",
    cityName: "",
    state: "",
    zipCode: "",
    isWorkPhone: false,
    isAddress: false,
    cityId: 0,
    stateID: 0,

  },
});
export const mailingContact = atom({
  key: "mailingContact",
  default: {
    addressLine1: "",
    addressLine2: "",
    cityName: "",
    state: "",
    zipCode: "",
    isWorkPhone: false,
    isAddress: false,
    cityId: 0,
    stateID: 0,
  },
});
export const isAdminState = atom({
  key: "isAdminState",
  default: {
    addresses: [
      {
        addressLine1: "",
        addressLine2: "",
        addressTypeId: 1,
        cityId: 1,
      },
    ],
    contactNo: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    empNo: "",
    firstName: "",
    lastName: "",
    middleName: "",
    note: "",
    password: "",
    ssnNo: "",
    title: "",
    username: "",
    position: "",
    department: "",
    workContact: "",
    homeContact: "",
  },
});

export const address = atom({
  key: "address",
  default: [],
});
export const companyContacts = atom({
  key: "companyContacts",
  default: [],
});
export const countryState = atom({
  key: "countryState",
  default: [],
});
export const stateArray = atom({
  key: "stateArray",
  default: [],
});
export const cityArray = atom({
  key: "cityArray",
  default: [],
});

export const companyRequiredState = atom({
  key: "storeRequiredState",
  default: ["zip", "name"],
});

export const storeListState = atom({
  key: "storeListState",
  default: "",
});
