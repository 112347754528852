import { atom } from "recoil";

export const rolesDialogState = atom({
  key: "rolesDialogState",
  default: false,
});
export const rolesVisibleState = atom({
  key: "rolesVisibleState",
  default: false,
});
export const rolesStates = atom({
  key: "rolesStates",
  default: [],
});
export const selectedRoles = atom({
  key: "selectedRoles",
  default: [],
});
export const permissionState = atom({
  key: "permissionState",
  default: [],
});
