import React from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Tooltip, DatePicker, Input, Switch } from "antd";
import { useRecoilState } from "recoil";
import { drugVerifyState } from "../../../../utils/constant/order/atom";
import TextArea from "antd/lib/input/TextArea";
import DocumentPreview from "../../../../components/common/dms/components/docView";
import ThumbnailImg from "../../../../assets/img/thumbnail-images.jpg";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function DrugVerify() {
  const [isdrugVerify, setdrugVerify] = useRecoilState(drugVerifyState);

  const drugVerifyModal = () => {
    setdrugVerify(true);
  };

  const handleOk = () => {
    setdrugVerify(false);
  };

  const handleCancel = () => {
    setdrugVerify(false);
  };

  return (
    <>
      <Button type="primary" onClick={drugVerifyModal}>
        Drug Verification
      </Button>
      <Modal
        width={1100}
        style={{ top: 20 }}
        className="modal-drug-verify"
        title="Drug Verification"
        visible={isdrugVerify}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          {/* <section className="info-bar">
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Patient Name" placement="bottomLeft">
                <i className="mdi mdi-account-card-details"></i>
              </Tooltip>
            </label>
            <span>Rafay, Malik Abdul Ghafoor</span>
          </div>
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Date Of Birth" placement="bottomLeft">
                <i className="mdi mdi-calendar"></i>
              </Tooltip>
            </label>
            <span>10-10-1989</span>
          </div>
        </section> */}
          <section>
            <div className="row">
              <div className="col-lg-5">
                <section className="bg-primary border-0 mb-0">
                  <div className="detail-block d-flex mt-3">
                    <div className="detail-info w-100">
                      <label className="label-info patient">Patient</label>
                      <span style={{ fontSize: "1.15rem" }}>Patient, test</span>
                    </div>
                    <div className="detail-info">
                      <label className="label-info">DOB</label>
                      <span className="text-center" style={{ fontSize: "1.15rem" }}>
                        10-25-1974
                      </span>
                    </div>
                  </div>
                </section>

                <section className="bg-secondary border-0 my-1">
                  <div className="detail-block d-inline-block w-100 my-3">
                    <div className="detail-info">
                      <label className="label-info">Fill date</label>
                      <span style={{ fontSize: "1.15rem" }}>12/15/2015</span>
                    </div>
                    <div className="detail-info float-right">
                      <label className="label-info">Rx #</label>
                      <span style={{ fontSize: "1.15rem" }}>123456897-00</span>
                    </div>
                  </div>
                  <div className="detail-block d-inline-block w-100 my-1">
                    <div className="detail-info w-100">
                      <label className="label-info">Drug</label>
                      <span>Amoxicillin</span>
                    </div>
                  </div>
                  <div className="detail-block w-100 my-1">
                    <div className="d-flex w-100">
                      <div className="detail-info w-100">
                        <label className="formLabel">Stg.</label>
                        <span>875 M</span>
                      </div>
                      <div className="detail-info">
                        <label className="formLabel">Form</label>
                        <span>Tab</span>
                      </div>
                    </div>
                  </div>
                  <div className="detail-block d-inline-block w-100 my-1">
                    <div className="detail-info w-100">
                      <label className="formLabel">SIG</label>
                      <span style={{ height: 50 }}>3 Times Daily</span>
                    </div>
                  </div>
                  <div className="detail-block d-flex align-items-end justify-content-between w-100 my-1 text-center">
                    <div className="d-flex align-items-end">
                      <div className="detail-info">
                        <label className="formLabel">OQ</label>
                        <span>20</span>
                      </div>
                      <div className="detail-info">
                        <label className="formLabel">AR</label>
                        <span>20</span>
                      </div>
                      <div className="detail-info">
                        <label style={{ fontSize: ".9rem", lineHeight: "1", top: "-.45rem" }} className="formLabel">
                          DQ
                        </label>
                        <span style={{ fontSize: "1.75rem", lineHeight: "1.4", background: "#a5c9e2" }}>20</span>
                      </div>
                      <div className="detail-info">
                        <label style={{ fontSize: ".9rem", lineHeight: "1", top: "-.45rem" }} className="formLabel">
                          DS
                        </label>
                        <span style={{ fontSize: "1.75rem", lineHeight: "1.4", background: "#a5c9e2" }}>6</span>
                      </div>
                    </div>
                    <div className="detail-info">
                      <label className="formLabel">RQ</label>
                      <span>6</span>
                    </div>
                  </div>
                </section>
                <section className="bg-primary border-0 my-0">
                  <div className="detail-block d-flex">
                    <div className="detail-info width-100 mt-3">
                      <label className="label-info">Prescriber</label>
                      <span style={{ fontSize: "1.15rem" }}>Prescriber, test</span>
                    </div>
                  </div>
                </section>
                <section className="product-thumbnail d-flex border-0 my-0">
                  <div className="input-group w-50">
                    <TextArea
                      className="formControl input-notes p-1"
                      id="productDesc"
                      name="productDesc"
                      rows={3}
                      placeholder="No Description Found"
                      disabled
                    />
                    <label className="formLabel">Description</label>
                  </div>
                  <div className="img-wrapper w-50">
                    <img src={ThumbnailImg} />
                  </div>
                  <div className="detail-block d-inline-block pl-3 text-center">
                    <div className="detail-info mb-2 w-100">
                      <label className="formLabel">Pkg. Size</label>
                      <span className="w-100">100</span>
                    </div>
                    <div className="detail-info w-100">
                      <label className="formLabel">Stock</label>
                      <span className="w-100">86</span>
                    </div>
                  </div>
                </section>

                <div className="input-group align-items-center">
                  <h6 className="text-danger width-27 m-0 mr-2" style={{ fontSize: "1.3rem" }}>
                    EZ Cap
                  </h6>
                  <Button className="input-group-prepend btn btn-note">PKGN</Button>
                  <TextArea rows="1" className="w-100"></TextArea>
                </div>
                <section className="pb-0 mb-0" style={{ background: "#7ED6F2" }}>
                  <div className="section-title">
                    <Checkbox className="title-text">Split Fill</Checkbox>
                    <div className="detail-block float-right">
                      <div className="detail-info" style={{ width: 80, height: 25 }}>
                        <label>DNS</label>
                        <span className="d-flex justify-content-end w-100">
                          <Switch
                            size="small"
                            className="bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="detail-block d-inline-block w-100">
                    <div className="detail-info input-group w-auto">
                      <div className="input-group-prepend">
                        <i className="mdi mdi-numeric-1-box"></i>
                      </div>
                      <label className="formLabel">NDC</label>
                      <span className="text-center">65824-0015-01</span>
                    </div>
                    <div className="detail-info input-group w-auto">
                      <label className="formLabel">Qty.</label>
                      <span className="text-center">15</span>
                    </div>
                    <Link className="btn btn-icon btn-print float-right m-0">
                      <span></span>
                    </Link>
                  </div>
                  <div className="detail-block d-inline-block w-100">
                    <div className="detail-info input-group w-auto">
                      <div className="input-group-prepend">
                        <i className="mdi mdi-numeric-2-box"></i>
                      </div>
                      <label className="formLabel">NDC</label>
                      <span className="text-center">65824-0015-01</span>
                    </div>
                    <div className="detail-info input-group w-auto">
                      <label className="formLabel">Qty.</label>
                      <span className="text-center">15</span>
                    </div>
                    <Link className="btn btn-icon btn-print float-right m-0">
                      <span></span>
                    </Link>
                  </div>
                </section>
                <div className="d-flex justify-content-between mb-2">
                  <Input size="small" className="input-xl m-0" placeholder="Scan / Enter NDC" autoFocus></Input>
                  <Button className="btn btn-reprocess float-right m-0">Reprocess</Button>
                </div>
              </div>
              <div className="col-lg-7 pl-0">
                <DocumentPreview />
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
