const { atom } = require("recoil");

export const patientDetailState = atom({
  key: "patientDetailMainState",
  default: false,
});

export const binState = atom({
  key: "binState",
  default: false,
});

export const pcnState = atom({
  key: "pcnState",
  default: false,
});

export const groupState = atom({
  key: "groupState",
  default: false,
});

export const familyMemberState = atom({
  key: "familyMemberState",
  default: false,
});

export const eligibilityCheckState = atom({
  key: "eligibilityCheckState",
  default: false,
});
export const deceasedCheckState = atom({
  key: "deceasedCheckState",
  default: false,
});
