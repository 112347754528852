import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { adminMenus, privateMenus } from "./utils/menu/route";
import PageLoader from "Components/common/loader/page";
import PageNotFound from "Pages/NotFound/page-not-found";
import Login from "./pages/Auth/Login";
// import ProcessingSearch from "Pages/Private/Processing";
// import ProcessingDetail from "Pages/Private/Processing/detail";
import Order from "Pages/Private/Order";
// import PatientSearch from "Pages/Private/Patient/patientSearch";
// import PrescriberSearch from "Pages/Private/Prescriber/prescriberSearch";
// import DrugSearch from "Pages/Private/Product/drugSearch";
import OctSearch from "Pages/Private/Product/octSearch";
// import Binman from "Pages/Private/Binman";
// import Wimo from "Pages/Private/Wimo";

const type = localStorage.getItem("type");
let menuRoute = [];
let menu = [];

if (type === "ADMIN") {
  menu = adminMenus;
} else if (type === "PRIVATE") {
  menu = privateMenus;
}
menu.forEach((item) => {
  if (item.child.length === 0) {
    menuRoute.push(item);
  } else {
    item.child.forEach((record) => {
      menuRoute.push(record);
    });
  }
});

const PharmacyRoutes = () => {
  return (
    <Switch>
      {menuRoute.map((menu, index) => {
        return (
          <Route
            exact
            path={menu.path}
            render={(props) => (
              <Suspense fallback={<PageLoader />}>
                <menu.component {...props} />
              </Suspense>
            )}
            key={menu.path}
          />
        );
      })}


      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/404" component={PageNotFound} />
      {/* <Redirect to="/404" /> */}
    </Switch>
  );
};

export default PharmacyRoutes;
